module.exports =
    [
      {
        "id": 132410,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار الأهلية للنشر والتوزيع _Z1",
        "created_at": "2022-12-05T21:18:20.195+01:00",
        "updated_at": "2022-12-06T17:57:43.779+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 227.13962947115186,
          "y": 169.16271801786348,
          "lat": 21.749161054846105,
          "lng": 39.151125092907,
          "radius": 5,
          "georeferences": {
            "lat": 21.749161054846105,
            "lng": 39.151125092907
          },
          "cartesians": {
            "x": 227.13962947115186,
            "y": 169.16271801786348
          }
        }
      },
      {
        "id": 132577,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات نابو _F15",
        "created_at": "2022-12-05T21:18:20.947+01:00",
        "updated_at": "2022-12-06T17:58:55.842+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 192.77824581027699,
          "y": 88.44386207452402,
          "lat": 21.749890378507068,
          "lng": 39.15145643432926,
          "radius": 5,
          "georeferences": {
            "lat": 21.749890378507068,
            "lng": 39.15145643432926
          },
          "cartesians": {
            "x": 192.77824581027699,
            "y": 88.44386207452402
          }
        }
      },
      {
        "id": 132467,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات تكوين _J1",
        "created_at": "2022-12-05T21:18:20.496+01:00",
        "updated_at": "2022-12-06T18:22:53.307+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 145.3341286088406,
          "y": 122.04521632743558,
          "lat": 21.749587377679713,
          "lng": 39.15191543410353,
          "radius": 5,
          "georeferences": {
            "lat": 21.749587377679713,
            "lng": 39.15191543410353
          },
          "cartesians": {
            "x": 145.3341286088406,
            "y": 122.04521632743558
          }
        }
      },
      {
        "id": 132544,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سطور للنشر _I12",
        "created_at": "2022-12-05T21:18:20.823+01:00",
        "updated_at": "2022-12-06T18:14:49.988+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 147.51832560210082,
          "y": 89.00974501370735,
          "lat": 21.749885707235798,
          "lng": 39.15189397703577,
          "radius": 5,
          "georeferences": {
            "lat": 21.749885707235798,
            "lng": 39.15189397703577
          },
          "cartesians": {
            "x": 147.51832560210082,
            "y": 89.00974501370735
          }
        }
      },
      {
        "id": 132351,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نور الحياة للنشر والتوزيع _K11",
        "created_at": "2022-12-05T21:18:19.973+01:00",
        "updated_at": "2022-12-06T18:26:10.288+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 120.42053629674669,
          "y": 111.1072779094981,
          "lat": 21.74968640249703,
          "lng": 39.15215616550375,
          "radius": 5,
          "georeferences": {
            "lat": 21.74968640249703,
            "lng": 39.15215616550375
          },
          "cartesians": {
            "x": 120.42053629674669,
            "y": 111.1072779094981
          }
        }
      },
      {
        "id": 132571,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار رشم للنشر والتوزيع _S1",
        "created_at": "2022-12-05T21:18:20.927+01:00",
        "updated_at": "2022-12-06T15:30:58.869+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 165.60732201839866,
          "y": 227.60544496917265,
          "lat": 21.74863384276088,
          "lng": 39.151720543390844,
          "radius": 5,
          "georeferences": {
            "lat": 21.74863384276088,
            "lng": 39.151720543390844
          },
          "cartesians": {
            "x": 165.60732201839866,
            "y": 227.60544496917265
          }
        }
      },
      {
        "id": 132615,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار وقت القراءة للنشر والتوزيع (بوك تشينو) _F1",
        "created_at": "2022-12-05T21:18:21.066+01:00",
        "updated_at": "2022-12-06T16:44:38.239+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 189.09848233565168,
          "y": 117.72238497131033,
          "lat": 21.74962599331346,
          "lng": 39.15149231068503,
          "radius": 5,
          "georeferences": {
            "lat": 21.74962599331346,
            "lng": 39.15149231068503
          },
          "cartesians": {
            "x": 189.09848233565168,
            "y": 117.72238497131033
          }
        }
      },
      {
        "id": 132411,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مجموعة زاد للنشر _Y18",
        "created_at": "2022-12-05T21:18:20.198+01:00",
        "updated_at": "2022-12-06T15:14:44.802+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 244.57432278352502,
          "y": 192.14346200665122,
          "lat": 21.74895334134675,
          "lng": 39.15095678690187,
          "radius": 5,
          "georeferences": {
            "lat": 21.74895334134675,
            "lng": 39.15095678690187
          },
          "cartesians": {
            "x": 244.57432278352502,
            "y": 192.14346200665122
          }
        }
      },
      {
        "id": 132413,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة دار الشمال العالميه ش.م.م _V15",
        "created_at": "2022-12-05T21:18:20.204+01:00",
        "updated_at": "2022-12-06T15:27:42.773+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 214.45282458402622,
          "y": 240.65627101715162,
          "lat": 21.748515503670518,
          "lng": 39.15124848045562,
          "radius": 5,
          "georeferences": {
            "lat": 21.748515503670518,
            "lng": 39.15124848045562
          },
          "cartesians": {
            "x": 214.45282458402622,
            "y": 240.65627101715162
          }
        }
      },
      {
        "id": 132671,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار وحي القلم للطباعة والنشر والتوزيع _X12",
        "created_at": "2022-12-05T21:18:21.251+01:00",
        "updated_at": "2022-12-06T15:24:21.149+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 247.6422488487299,
          "y": 207.2086911861084,
          "lat": 21.748817254098864,
          "lng": 39.150927285052745,
          "radius": 5,
          "georeferences": {
            "lat": 21.748817254098864,
            "lng": 39.150927285052745
          },
          "cartesians": {
            "x": 247.6422488487299,
            "y": 207.2086911861084
          }
        }
      },
      {
        "id": 132669,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز الأمير عبدالمحسن بن جلوي للبحوث والدراسات الإسلامية _X7",
        "created_at": "2022-12-05T21:18:21.245+01:00",
        "updated_at": "2022-12-06T15:24:31.078+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 234.31563974891338,
          "y": 198.87815029046453,
          "lat": 21.748892618447222,
          "lng": 39.15105602938966,
          "radius": 5,
          "georeferences": {
            "lat": 21.748892618447222,
            "lng": 39.15105602938966
          },
          "cartesians": {
            "x": 234.31563974891338,
            "y": 198.87815029046453
          }
        }
      },
      {
        "id": 132634,
        "project_id": 12170,
        "building_id": 12170,
        "name": "القهوة السعودية _X17",
        "created_at": "2022-12-05T21:18:21.129+01:00",
        "updated_at": "2022-12-06T15:26:44.013+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 233.4879662381539,
          "y": 203.29264958704076,
          "lat": 21.748852758147315,
          "lng": 39.15106407647482,
          "radius": 5,
          "georeferences": {
            "lat": 21.748852758147315,
            "lng": 39.15106407647482
          },
          "cartesians": {
            "x": 233.4879662381539,
            "y": 203.29264958704076
          }
        }
      },
      {
        "id": 132635,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ديوان الأمير خالد الفيصل _X19",
        "created_at": "2022-12-05T21:18:21.132+01:00",
        "updated_at": "2022-12-06T15:26:45.709+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 227.6927533553627,
          "y": 200.12647108976338,
          "lat": 21.748881408871156,
          "lng": 39.151120067014624,
          "radius": 5,
          "georeferences": {
            "lat": 21.748881408871156,
            "lng": 39.151120067014624
          },
          "cartesians": {
            "x": 227.6927533553627,
            "y": 200.12647108976338
          }
        }
      },
      {
        "id": 132636,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ترجم _W12",
        "created_at": "2022-12-05T21:18:21.135+01:00",
        "updated_at": "2022-12-06T15:25:48.366+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 230.04070782380603,
          "y": 222.98523875259295,
          "lat": 21.74867494341644,
          "lng": 39.15109760613571,
          "radius": 5,
          "georeferences": {
            "lat": 21.74867494341644,
            "lng": 39.15109760613571
          },
          "cartesians": {
            "x": 230.04070782380603,
            "y": 222.98523875259295
          }
        }
      },
      {
        "id": 132450,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Al Rewaya for Book Publishing and Distribution FZE _Y5",
        "created_at": "2022-12-05T21:18:20.440+01:00",
        "updated_at": "2022-12-06T15:21:53.062+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 232.35577937980287,
          "y": 182.22564686752315,
          "lat": 21.749043029969005,
          "lng": 39.15107480292842,
          "radius": 5,
          "georeferences": {
            "lat": 21.749043029969005,
            "lng": 39.15107480292842
          },
          "cartesians": {
            "x": 232.35577937980287,
            "y": 182.22564686752315
          }
        }
      },
      {
        "id": 132625,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز ابوظبي  للغة العربية التابع لدائرة الثقافة والسياحة-ابوظبي _V1",
        "created_at": "2022-12-05T21:18:21.099+01:00",
        "updated_at": "2022-12-06T15:28:01.563+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 200.1275351974577,
          "y": 206.46578812538604,
          "lat": 21.748824424655247,
          "lng": 39.15138661085318,
          "radius": 5,
          "georeferences": {
            "lat": 21.748824424655247,
            "lng": 39.15138661085318
          },
          "cartesians": {
            "x": 200.1275351974577,
            "y": 206.46578812538604
          }
        }
      },
      {
        "id": 132428,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز توثيق سيرة الملك عبدالله بن عبدالعزيز آل سعود _W4",
        "created_at": "2022-12-05T21:18:20.369+01:00",
        "updated_at": "2022-12-06T15:25:54.688+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 213.6515527054009,
          "y": 204.7273475583122,
          "lat": 21.74883999362285,
          "lng": 39.15125585364073,
          "radius": 5,
          "georeferences": {
            "lat": 21.74883999362285,
            "lng": 39.15125585364073
          },
          "cartesians": {
            "x": 213.6515527054009,
            "y": 204.7273475583122
          }
        }
      },
      {
        "id": 132455,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الروافد الثقافية ناشرون _U11",
        "created_at": "2022-12-05T21:18:20.458+01:00",
        "updated_at": "2022-12-06T15:28:48.913+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 197.5922772321474,
          "y": 235.63986106896323,
          "lat": 21.7485609716627,
          "lng": 39.151411422364866,
          "radius": 5,
          "georeferences": {
            "lat": 21.7485609716627,
            "lng": 39.151411422364866
          },
          "cartesians": {
            "x": 197.5922772321474,
            "y": 235.63986106896323
          }
        }
      },
      {
        "id": 132622,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة دار دكة للنشر والتوزيع _N34",
        "created_at": "2022-12-05T21:18:21.090+01:00",
        "updated_at": "2022-12-06T16:00:28.620+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 114.1732297595083,
          "y": 171.04273462681007,
          "lat": 21.749145172574885,
          "lng": 39.152217179960076,
          "radius": 5,
          "georeferences": {
            "lat": 21.749145172574885,
            "lng": 39.152217179960076
          },
          "cartesians": {
            "x": 114.1732297595083,
            "y": 171.04273462681007
          }
        }
      },
      {
        "id": 132517,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة رياض الريس للكتب والنشر _Y10",
        "created_at": "2022-12-05T21:18:20.667+01:00",
        "updated_at": "2022-12-06T15:21:49.956+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 245.3315604103376,
          "y": 186.72909656131367,
          "lat": 21.74900223234595,
          "lng": 39.15094941035418,
          "radius": 5,
          "georeferences": {
            "lat": 21.74900223234595,
            "lng": 39.15094941035418
          },
          "cartesians": {
            "x": 245.3315604103376,
            "y": 186.72909656131367
          }
        }
      },
      {
        "id": 132550,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Universal Publisher& Distributor _W19",
        "created_at": "2022-12-05T21:18:20.845+01:00",
        "updated_at": "2022-12-06T15:25:40.012+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 217.9647791713646,
          "y": 216.65339126208522,
          "lat": 21.74873224497545,
          "lng": 39.15121428063716,
          "radius": 5,
          "georeferences": {
            "lat": 21.74873224497545,
            "lng": 39.15121428063716
          },
          "cartesians": {
            "x": 217.9647791713646,
            "y": 216.65339126208522
          }
        }
      },
      {
        "id": 132597,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار الذهبية للنشر و التوزيع - مصر _V14",
        "created_at": "2022-12-05T21:18:21.011+01:00",
        "updated_at": "2022-12-06T15:28:12.313+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 218.54325857785645,
          "y": 238.78992014682086,
          "lat": 21.74853231939783,
          "lng": 39.1512089181161,
          "radius": 5,
          "georeferences": {
            "lat": 21.74853231939783,
            "lng": 39.1512089181161
          },
          "cartesians": {
            "x": 218.54325857785645,
            "y": 238.78992014682086
          }
        }
      },
      {
        "id": 132563,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة وجوه للنشر والتوزيع _V12",
        "created_at": "2022-12-05T21:18:20.900+01:00",
        "updated_at": "2022-12-06T15:28:09.440+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 215.13793603679972,
          "y": 232.65580980633788,
          "lat": 21.748587750981482,
          "lng": 39.15124177432062,
          "radius": 5,
          "georeferences": {
            "lat": 21.748587750981482,
            "lng": 39.15124177432062
          },
          "cartesians": {
            "x": 215.13793603679972,
            "y": 232.65580980633788
          }
        }
      },
      {
        "id": 132470,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ببلومانيا للنشر والتوزيع _J11",
        "created_at": "2022-12-05T21:18:20.507+01:00",
        "updated_at": "2022-12-06T18:26:47.644+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 129.48988949029206,
          "y": 94.9601190999735,
          "lat": 21.749832143075935,
          "lng": 39.152068323158744,
          "radius": 5,
          "georeferences": {
            "lat": 21.749832143075935,
            "lng": 39.152068323158744
          },
          "cartesians": {
            "x": 129.48988949029206,
            "y": 94.9601190999735
          }
        }
      },
      {
        "id": 132647,
        "project_id": 12170,
        "building_id": 12170,
        "name": "عالم الرواسي للنشر والتوزيع _B30",
        "created_at": "2022-12-05T21:18:21.174+01:00",
        "updated_at": "2022-12-08T05:41:56.605+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 203.2498343656963,
          "y": 146.90599765206477,
          "lat": 21.749362292186113,
          "lng": 39.151355809295076,
          "radius": 5,
          "georeferences": {
            "lat": 21.749362292186113,
            "lng": 39.151355809295076
          },
          "cartesians": {
            "x": 203.2498343656963,
            "y": 146.90599765206477
          }
        }
      },
      {
        "id": 132431,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جدل للنشر والتوزيع _G6",
        "created_at": "2022-12-05T21:18:20.378+01:00",
        "updated_at": "2022-12-06T18:20:55.865+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 182.33359746626152,
          "y": 83.55868097092792,
          "lat": 21.749934599062055,
          "lng": 39.1515573542731,
          "radius": 5,
          "georeferences": {
            "lat": 21.749934599062055,
            "lng": 39.1515573542731
          },
          "cartesians": {
            "x": 182.33359746626152,
            "y": 83.55868097092792
          }
        }
      },
      {
        "id": 132618,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة خالد بن الوليد _J12",
        "created_at": "2022-12-05T21:18:21.075+01:00",
        "updated_at": "2022-12-06T18:23:41.960+01:00",
        "info": "<p> اليمن - Yemen </p>",
        "info_unsafe": "<p> اليمن - Yemen </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 128.1681722471608,
          "y": 91.44447533872741,
          "lat": 21.749863906474182,
          "lng": 39.15208106409046,
          "radius": 5,
          "georeferences": {
            "lat": 21.749863906474182,
            "lng": 39.15208106409046
          },
          "cartesians": {
            "x": 128.1681722471608,
            "y": 91.44447533872741
          }
        }
      },
      {
        "id": 132461,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة دار المسيلة للنشر والتوزيع _Q13",
        "created_at": "2022-12-05T21:18:20.477+01:00",
        "updated_at": "2022-12-05T21:18:20.477+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 129.272312230237,
          "y": 237.98933325578082,
          "lat": 21.748540416243582,
          "lng": 39.15207190825374,
          "radius": 5,
          "georeferences": {
            "lat": 21.748540416243582,
            "lng": 39.15207190825374
          },
          "cartesians": {
            "x": 129.272312230237,
            "y": 237.98933325578082
          }
        }
      },
      {
        "id": 132442,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة حزاية _O13",
        "created_at": "2022-12-05T21:18:20.416+01:00",
        "updated_at": "2022-12-06T15:59:30.404+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 104.5831133749951,
          "y": 209.74155185306194,
          "lat": 21.74879576796082,
          "lng": 39.15231029037525,
          "radius": 5,
          "georeferences": {
            "lat": 21.74879576796082,
            "lng": 39.15231029037525
          },
          "cartesians": {
            "x": 104.5831133749951,
            "y": 209.74155185306194
          }
        }
      },
      {
        "id": 132444,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كيان لكتب الاطفال _O11",
        "created_at": "2022-12-05T21:18:20.422+01:00",
        "updated_at": "2022-12-06T15:59:32.354+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 102.22074929161965,
          "y": 206.05465309845508,
          "lat": 21.74882908810307,
          "lng": 39.1523330895741,
          "radius": 5,
          "georeferences": {
            "lat": 21.74882908810307,
            "lng": 39.1523330895741
          },
          "cartesians": {
            "x": 102.22074929161965,
            "y": 206.05465309845508
          }
        }
      },
      {
        "id": 132447,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار العربية للموسوعات والكتب _Z16",
        "created_at": "2022-12-05T21:18:20.431+01:00",
        "updated_at": "2022-12-06T15:14:01.942+01:00",
        "info": "<p> المملكة المتحدة - United Kingdom </p>",
        "info_unsafe": "<p> المملكة المتحدة - United Kingdom </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 241.36515563780102,
          "y": 174.76818315110413,
          "lat": 21.749110292542802,
          "lng": 39.15098763021469,
          "radius": 5,
          "georeferences": {
            "lat": 21.749110292542802,
            "lng": 39.15098763021469
          },
          "cartesians": {
            "x": 241.36515563780102,
            "y": 174.76818315110413
          }
        }
      },
      {
        "id": 132458,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سكوير ميديا _P7",
        "created_at": "2022-12-05T21:18:20.467+01:00",
        "updated_at": "2022-12-06T15:40:44.093+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 129.93485024664568,
          "y": 209.2310044512659,
          "lat": 21.748800132736108,
          "lng": 39.15206520516876,
          "radius": 5,
          "georeferences": {
            "lat": 21.748800132736108,
            "lng": 39.15206520516876
          },
          "cartesians": {
            "x": 129.93485024664568,
            "y": 209.2310044512659
          }
        }
      },
      {
        "id": 132472,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كيان للنشر _L3",
        "created_at": "2022-12-05T21:18:20.514+01:00",
        "updated_at": "2022-12-06T15:53:38.515+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 120.68936617826549,
          "y": 135.2094189981258,
          "lat": 21.749468728040192,
          "lng": 39.152153816145756,
          "radius": 5,
          "georeferences": {
            "lat": 21.749468728040192,
            "lng": 39.152153816145756
          },
          "cartesians": {
            "x": 120.68936617826549,
            "y": 135.2094189981258
          }
        }
      },
      {
        "id": 132448,
        "project_id": 12170,
        "building_id": 12170,
        "name": "اتحاد الناشرين الموريتانيين _Z11",
        "created_at": "2022-12-05T21:18:20.434+01:00",
        "updated_at": "2022-12-06T17:57:19.483+01:00",
        "info": "<p> موريتانيا - Mauritania </p>",
        "info_unsafe": "<p> موريتانيا - Mauritania </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 256.3455685694972,
          "y": 172.71810589133636,
          "lat": 21.74912866183372,
          "lng": 39.15084279046158,
          "radius": 5,
          "georeferences": {
            "lat": 21.74912866183372,
            "lng": 39.15084279046158
          },
          "cartesians": {
            "x": 256.3455685694972,
            "y": 172.71810589133636
          }
        }
      },
      {
        "id": 132443,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مسرح 2",
        "created_at": "2022-12-05T21:18:20.419+01:00",
        "updated_at": "2022-12-10T21:31:51.592+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Theaters",
        "category_id": 2229,
        "position": {
          "floor_id": 37595,
          "x": 153.28372151520307,
          "y": 68.8729825153427,
          "lat": 21.750067510893153,
          "lng": 39.151838033372066,
          "radius": 5,
          "georeferences": {
            "lat": 21.750067510893153,
            "lng": 39.151838033372066
          },
          "cartesians": {
            "x": 153.28372151520307,
            "y": 68.8729825153427
          }
        }
      },
      {
        "id": 132339,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:19.933+01:00",
        "updated_at": "2022-12-06T15:30:38.335+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 174.19624556628298,
          "y": 218.2203714931226,
          "lat": 21.74871851809263,
          "lng": 39.151637415341156,
          "radius": 5,
          "georeferences": {
            "lat": 21.74871851809263,
            "lng": 39.151637415341156
          },
          "cartesians": {
            "x": 174.19624556628298,
            "y": 218.2203714931226
          }
        }
      },
      {
        "id": 132334,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:19.915+01:00",
        "updated_at": "2022-12-06T16:21:24.462+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 227.16524392385637,
          "y": 90.99641706904555,
          "lat": 21.749866992010375,
          "lng": 39.15112403468929,
          "radius": 5,
          "georeferences": {
            "lat": 21.749866992010375,
            "lng": 39.15112403468929
          },
          "cartesians": {
            "x": 227.16524392385637,
            "y": 90.99641706904555
          }
        }
      },
      {
        "id": 132336,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:19.922+01:00",
        "updated_at": "2022-12-06T15:32:00.235+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 138.72251590810407,
          "y": 245.79406925338907,
          "lat": 21.74846983818609,
          "lng": 39.151980632380265,
          "radius": 5,
          "georeferences": {
            "lat": 21.74846983818609,
            "lng": 39.151980632380265
          },
          "cartesians": {
            "x": 138.72251590810407,
            "y": 245.79406925338907
          }
        }
      },
      {
        "id": 132335,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:19.919+01:00",
        "updated_at": "2022-12-05T21:18:19.919+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 226.87051111055553,
          "y": 233.97579264099073,
          "lat": 21.748575716030484,
          "lng": 39.151128367083025,
          "radius": 5,
          "georeferences": {
            "lat": 21.748575716030484,
            "lng": 39.151128367083025
          },
          "cartesians": {
            "x": 226.87051111055553,
            "y": 233.97579264099073
          }
        }
      },
      {
        "id": 132340,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة الأطفال",
        "created_at": "2022-12-05T21:18:19.936+01:00",
        "updated_at": "2022-12-06T16:00:50.252+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Kids Area",
        "category_id": 2214,
        "position": {
          "floor_id": 37595,
          "x": 100.82165624499598,
          "y": 165.0604355793112,
          "lat": 21.749199329666283,
          "lng": 39.152346190194365,
          "radius": 5,
          "georeferences": {
            "lat": 21.749199329666283,
            "lng": 39.152346190194365
          },
          "cartesians": {
            "x": 100.82165624499598,
            "y": 165.0604355793112
          }
        }
      },
      {
        "id": 132330,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطاد للنشر والتوزيع _G1",
        "created_at": "2022-12-05T21:18:19.902+01:00",
        "updated_at": "2022-12-06T18:20:31.642+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 180.6167435258962,
          "y": 99.6977492215457,
          "lat": 21.749788860179194,
          "lng": 39.15157411860603,
          "radius": 5,
          "georeferences": {
            "lat": 21.749788860179194,
            "lng": 39.15157411860603
          },
          "cartesians": {
            "x": 180.6167435258962,
            "y": 99.6977492215457
          }
        }
      },
      {
        "id": 132329,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة دار المعارف _F9",
        "created_at": "2022-12-05T21:18:19.899+01:00",
        "updated_at": "2022-12-06T18:21:05.125+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 195.2495312282334,
          "y": 96.75119475161998,
          "lat": 21.749815329131447,
          "lng": 39.15143262997661,
          "radius": 5,
          "georeferences": {
            "lat": 21.749815329131447,
            "lng": 39.15143262997661
          },
          "cartesians": {
            "x": 195.2495312282334,
            "y": 96.75119475161998
          }
        }
      },
      {
        "id": 132322,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة نهير الرشيدي (دار حلول للإعلام والتجارة) _F14",
        "created_at": "2022-12-05T21:18:19.873+01:00",
        "updated_at": "2022-12-06T18:10:01.471+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 193.4692841528308,
          "y": 85.99495636034288,
          "lat": 21.749912488416374,
          "lng": 39.15144972855233,
          "radius": 5,
          "georeferences": {
            "lat": 21.749912488416374,
            "lng": 39.15144972855233
          },
          "cartesians": {
            "x": 193.4692841528308,
            "y": 85.99495636034288
          }
        }
      },
      {
        "id": 132328,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الحياة للنشر والتوزيع _H9",
        "created_at": "2022-12-05T21:18:19.896+01:00",
        "updated_at": "2022-12-06T18:21:57.621+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 165.12756777405247,
          "y": 80.23248313407845,
          "lat": 21.749964805722836,
          "lng": 39.15172365404242,
          "radius": 5,
          "georeferences": {
            "lat": 21.749964805722836,
            "lng": 39.15172365404242
          },
          "cartesians": {
            "x": 165.12756777405247,
            "y": 80.23248313407845
          }
        }
      },
      {
        "id": 132337,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار دريم بوك للنشر و التوزيع _H6",
        "created_at": "2022-12-05T21:18:19.926+01:00",
        "updated_at": "2022-12-06T18:22:17.547+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 162.18319058751817,
          "y": 83.61481690900285,
          "lat": 21.74993428768864,
          "lng": 39.15175215295429,
          "radius": 5,
          "georeferences": {
            "lat": 21.74993428768864,
            "lng": 39.15175215295429
          },
          "cartesians": {
            "x": 162.18319058751817,
            "y": 83.61481690900285
          }
        }
      },
      {
        "id": 132332,
        "project_id": 12170,
        "building_id": 12170,
        "name": "VIP Gates",
        "created_at": "2022-12-05T21:18:19.908+01:00",
        "updated_at": "2022-12-08T05:41:18.598+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Gates",
        "category_id": 2211,
        "position": {
          "floor_id": 37595,
          "x": 294.1707601347345,
          "y": 160.98536349383033,
          "lat": 21.749234255689952,
          "lng": 39.15047700536821,
          "radius": 5,
          "georeferences": {
            "lat": 21.749234255689952,
            "lng": 39.15047700536821
          },
          "cartesians": {
            "x": 294.1707601347345,
            "y": 160.98536349383033
          }
        }
      },
      {
        "id": 132323,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار  الحضارة للنشر و التوزيع _J26",
        "created_at": "2022-12-05T21:18:19.878+01:00",
        "updated_at": "2022-12-08T05:42:07.527+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 155.89097278361825,
          "y": 134.51600585294076,
          "lat": 21.749474648702947,
          "lng": 39.15181350818107,
          "radius": 5,
          "georeferences": {
            "lat": 21.749474648702947,
            "lng": 39.15181350818107
          },
          "cartesians": {
            "x": 155.89097278361825,
            "y": 134.51600585294076
          }
        }
      },
      {
        "id": 132333,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مصلى",
        "created_at": "2022-12-05T21:18:19.911+01:00",
        "updated_at": "2022-12-09T14:04:47.276+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Prayer Rooms",
        "category_id": 2221,
        "position": {
          "floor_id": 37595,
          "x": 88.13865690602256,
          "y": 110.73184488699076,
          "lat": 21.749690106481758,
          "lng": 39.152468236969575,
          "radius": 5,
          "georeferences": {
            "lat": 21.749690106481758,
            "lng": 39.152468236969575
          },
          "cartesians": {
            "x": 88.13865690602256,
            "y": 110.73184488699076
          }
        }
      },
      {
        "id": 132331,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دورات مياه",
        "created_at": "2022-12-05T21:18:19.905+01:00",
        "updated_at": "2022-12-09T14:05:19.719+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Restrooms",
        "category_id": 2225,
        "position": {
          "floor_id": 37595,
          "x": 290.0979937260962,
          "y": 190.7098962346909,
          "lat": 21.7489658462962,
          "lng": 39.150516686206785,
          "radius": 5,
          "georeferences": {
            "lat": 21.7489658462962,
            "lng": 39.150516686206785
          },
          "cartesians": {
            "x": 290.0979937260962,
            "y": 190.7098962346909
          }
        }
      },
      {
        "id": 132341,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة الخيال العلمي",
        "created_at": "2022-12-05T21:18:19.939+01:00",
        "updated_at": "2022-12-09T14:05:49.947+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Sci-Fi Area",
        "category_id": 2226,
        "position": {
          "floor_id": 37595,
          "x": 102.55257119339606,
          "y": 97.88900807229194,
          "lat": 21.749805953106556,
          "lng": 39.15232876194772,
          "radius": 5,
          "georeferences": {
            "lat": 21.749805953106556,
            "lng": 39.15232876194772
          },
          "cartesians": {
            "x": 102.55257119339606,
            "y": 97.88900807229194
          }
        }
      },
      {
        "id": 132363,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار العالمية الحديثة للنشر والتَوزيع _T9",
        "created_at": "2022-12-05T21:18:20.027+01:00",
        "updated_at": "2022-12-05T21:18:20.027+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 185.05223857974798,
          "y": 249.30795246740257,
          "lat": 21.74843765378868,
          "lng": 39.15153279103599,
          "radius": 5,
          "georeferences": {
            "lat": 21.74843765378868,
            "lng": 39.15153279103599
          },
          "cartesians": {
            "x": 185.05223857974798,
            "y": 249.30795246740257
          }
        }
      },
      {
        "id": 132375,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة عالم الطفل _M13",
        "created_at": "2022-12-05T21:18:20.071+01:00",
        "updated_at": "2022-12-06T15:55:40.541+01:00",
        "info": "<p> عمان - Oman </p>",
        "info_unsafe": "<p> عمان - Oman </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 93.64202578492177,
          "y": 139.54932236685895,
          "lat": 21.74942979595082,
          "lng": 39.152415332962,
          "radius": 5,
          "georeferences": {
            "lat": 21.74942979595082,
            "lng": 39.152415332962
          },
          "cartesians": {
            "x": 93.64202578492177,
            "y": 139.54932236685895
          }
        }
      },
      {
        "id": 132380,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الهدهد للنشر والتوزيع _O15",
        "created_at": "2022-12-05T21:18:20.093+01:00",
        "updated_at": "2022-12-06T15:59:24.145+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 109.95494840321726,
          "y": 206.2185207589089,
          "lat": 21.748827533100492,
          "lng": 39.15225832334073,
          "radius": 5,
          "georeferences": {
            "lat": 21.748827533100492,
            "lng": 39.15225832334073
          },
          "cartesians": {
            "x": 109.95494840321726,
            "y": 206.2185207589089
          }
        }
      },
      {
        "id": 132386,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كشمش _L9",
        "created_at": "2022-12-05T21:18:20.112+01:00",
        "updated_at": "2022-12-06T15:53:46.042+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 104.48181647745162,
          "y": 125.05520802503077,
          "lat": 21.749560590328148,
          "lng": 39.1523103925954,
          "radius": 5,
          "georeferences": {
            "lat": 21.749560590328148,
            "lng": 39.1523103925954
          },
          "cartesians": {
            "x": 104.48181647745162,
            "y": 125.05520802503077
          }
        }
      },
      {
        "id": 132362,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار صادر _F21",
        "created_at": "2022-12-05T21:18:20.023+01:00",
        "updated_at": "2022-12-06T16:45:27.178+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 187.1453154627484,
          "y": 107.44905419155901,
          "lat": 21.749718793029825,
          "lng": 39.15151108589206,
          "radius": 5,
          "georeferences": {
            "lat": 21.749718793029825,
            "lng": 39.15151108589206
          },
          "cartesians": {
            "x": 187.1453154627484,
            "y": 107.44905419155901
          }
        }
      },
      {
        "id": 132365,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة كنوز المعرفة _F19",
        "created_at": "2022-12-05T21:18:20.033+01:00",
        "updated_at": "2022-12-06T16:45:35.938+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 189.15003339226027,
          "y": 101.0333942862184,
          "lat": 21.74977671483486,
          "lng": 39.15149163941455,
          "radius": 5,
          "georeferences": {
            "lat": 21.74977671483486,
            "lng": 39.15149163941455
          },
          "cartesians": {
            "x": 189.15003339226027,
            "y": 101.0333942862184
          }
        }
      },
      {
        "id": 132372,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مصلى",
        "created_at": "2022-12-05T21:18:20.059+01:00",
        "updated_at": "2022-12-06T15:25:15.662+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Prayer Rooms",
        "category_id": 2221,
        "position": {
          "floor_id": 37595,
          "x": 261.8754274529957,
          "y": 211.87067127207018,
          "lat": 21.748775012546563,
          "lng": 39.15078973868609,
          "radius": 5,
          "georeferences": {
            "lat": 21.748775012546563,
            "lng": 39.15078973868609
          },
          "cartesians": {
            "x": 261.8754274529957,
            "y": 211.87067127207018
          }
        }
      },
      {
        "id": 132371,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز الادب العربى للنشر والتوزيع _Q26",
        "created_at": "2022-12-05T21:18:20.055+01:00",
        "updated_at": "2022-12-08T05:42:13.141+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 156.34614375535287,
          "y": 194.2373374426435,
          "lat": 21.748935287530916,
          "lng": 39.15180972685813,
          "radius": 5,
          "georeferences": {
            "lat": 21.748935287530916,
            "lng": 39.15180972685813
          },
          "cartesians": {
            "x": 156.34614375535287,
            "y": 194.2373374426435
          }
        }
      },
      {
        "id": 132370,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دارشغف _T18",
        "created_at": "2022-12-05T21:18:20.052+01:00",
        "updated_at": "2022-12-08T05:42:22.320+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 172.37284697735473,
          "y": 197.73716387080995,
          "lat": 21.74890352423926,
          "lng": 39.15165483002817,
          "radius": 5,
          "georeferences": {
            "lat": 21.74890352423926,
            "lng": 39.15165483002817
          },
          "cartesians": {
            "x": 172.37284697735473,
            "y": 197.73716387080995
          }
        }
      },
      {
        "id": 132391,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:20.129+01:00",
        "updated_at": "2022-12-08T05:42:55.881+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 173.81469977545814,
          "y": 68.02332596963475,
          "lat": 21.75007498503506,
          "lng": 39.15163954735017,
          "radius": 5,
          "georeferences": {
            "lat": 21.75007498503506,
            "lng": 39.15163954735017
          },
          "cartesians": {
            "x": 173.81469977545814,
            "y": 68.02332596963475
          }
        }
      },
      {
        "id": 132373,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دورات مياه",
        "created_at": "2022-12-05T21:18:20.065+01:00",
        "updated_at": "2022-12-09T14:05:10.458+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Restrooms",
        "category_id": 2225,
        "position": {
          "floor_id": 37595,
          "x": 287.2754604690367,
          "y": 134.30402694917052,
          "lat": 21.749475287764138,
          "lng": 39.15054338655715,
          "radius": 5,
          "georeferences": {
            "lat": 21.749475287764138,
            "lng": 39.15054338655715
          },
          "cartesians": {
            "x": 287.2754604690367,
            "y": 134.30402694917052
          }
        }
      },
      {
        "id": 132414,
        "project_id": 12170,
        "building_id": 12170,
        "name": "هنداوى للطباعة والتوزيع _V8",
        "created_at": "2022-12-05T21:18:20.213+01:00",
        "updated_at": "2022-12-06T15:28:03.802+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 209.16205610103376,
          "y": 222.66249399510517,
          "lat": 21.748678060868894,
          "lng": 39.15129944053246,
          "radius": 5,
          "georeferences": {
            "lat": 21.748678060868894,
            "lng": 39.15129944053246
          },
          "cartesians": {
            "x": 209.16205610103376,
            "y": 222.66249399510517
          }
        }
      },
      {
        "id": 132403,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات المتوسط _K22",
        "created_at": "2022-12-05T21:18:20.172+01:00",
        "updated_at": "2022-12-06T18:26:17.325+01:00",
        "info": "<p> إيطاليا - Italy </p>",
        "info_unsafe": "<p> إيطاليا - Italy </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 134.9276928921875,
          "y": 120.40144712030221,
          "lat": 21.749602323945233,
          "lng": 39.152016018148785,
          "radius": 5,
          "georeferences": {
            "lat": 21.749602323945233,
            "lng": 39.152016018148785
          },
          "cartesians": {
            "x": 134.9276928921875,
            "y": 120.40144712030221
          }
        }
      },
      {
        "id": 132510,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة مون - MOON _M15",
        "created_at": "2022-12-05T21:18:20.645+01:00",
        "updated_at": "2022-12-06T15:55:44.258+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 87.70910388716237,
          "y": 137.45252348148625,
          "lat": 21.74944879020414,
          "lng": 39.15247266597086,
          "radius": 5,
          "georeferences": {
            "lat": 21.74944879020414,
            "lng": 39.15247266597086
          },
          "cartesians": {
            "x": 87.70910388716237,
            "y": 137.45252348148625
          }
        }
      },
      {
        "id": 132502,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار نهضة مصر للنشر _W17",
        "created_at": "2022-12-05T21:18:20.618+01:00",
        "updated_at": "2022-12-06T15:25:36.871+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 223.24209982194702,
          "y": 222.02692929654592,
          "lat": 21.74868366411934,
          "lng": 39.15116331957727,
          "radius": 5,
          "georeferences": {
            "lat": 21.74868366411934,
            "lng": 39.15116331957727
          },
          "cartesians": {
            "x": 223.24209982194702,
            "y": 222.02692929654592
          }
        }
      },
      {
        "id": 132493,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الإسراء - موريتانيا _R13",
        "created_at": "2022-12-05T21:18:20.587+01:00",
        "updated_at": "2022-12-05T21:18:20.587+01:00",
        "info": "<p> موريتانيا - Mauritania </p>",
        "info_unsafe": "<p> موريتانيا - Mauritania </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 149.04317936778676,
          "y": 240.24360884875298,
          "lat": 21.748519865455943,
          "lng": 39.151880803076594,
          "radius": 5,
          "georeferences": {
            "lat": 21.748519865455943,
            "lng": 39.151880803076594
          },
          "cartesians": {
            "x": 149.04317936778676,
            "y": 240.24360884875298
          }
        }
      },
      {
        "id": 132504,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار حكاية _F23",
        "created_at": "2022-12-05T21:18:20.625+01:00",
        "updated_at": "2022-12-06T16:45:28.772+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 184.58818956696925,
          "y": 116.2100386628367,
          "lat": 21.749639695434585,
          "lng": 39.15153589690694,
          "radius": 5,
          "georeferences": {
            "lat": 21.749639695434585,
            "lng": 39.15153589690694
          },
          "cartesians": {
            "x": 184.58818956696925,
            "y": 116.2100386628367
          }
        }
      },
      {
        "id": 132552,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار فانتازيا _J7",
        "created_at": "2022-12-05T21:18:20.863+01:00",
        "updated_at": "2022-12-06T18:23:30.901+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 135.3272892224265,
          "y": 104.95332975901943,
          "lat": 21.749741835482418,
          "lng": 39.15201199526036,
          "radius": 5,
          "georeferences": {
            "lat": 21.749741835482418,
            "lng": 39.15201199526036
          },
          "cartesians": {
            "x": 135.3272892224265,
            "y": 104.95332975901943
          }
        }
      },
      {
        "id": 132584,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المبتكر الصغير _P21",
        "created_at": "2022-12-05T21:18:20.970+01:00",
        "updated_at": "2022-12-06T15:35:34.722+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 131.2226576026651,
          "y": 213.47082641904797,
          "lat": 21.748761829451126,
          "lng": 39.15205279965402,
          "radius": 5,
          "georeferences": {
            "lat": 21.748761829451126,
            "lng": 39.15205279965402
          },
          "cartesians": {
            "x": 131.2226576026651,
            "y": 213.47082641904797
          }
        }
      },
      {
        "id": 132581,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة يتخيلون المحدودة _L5",
        "created_at": "2022-12-05T21:18:20.960+01:00",
        "updated_at": "2022-12-06T15:53:40.519+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 115.5181248154351,
          "y": 131.87038404284792,
          "lat": 21.749498933812415,
          "lng": 39.15220377297783,
          "radius": 5,
          "georeferences": {
            "lat": 21.749498933812415,
            "lng": 39.15220377297783
          },
          "cartesians": {
            "x": 115.5181248154351,
            "y": 131.87038404284792
          }
        }
      },
      {
        "id": 132578,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة دار محاور للنشر _I5",
        "created_at": "2022-12-05T21:18:20.950+01:00",
        "updated_at": "2022-12-06T18:14:42.617+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 152.39284349947613,
          "y": 106.65887038932217,
          "lat": 21.749726266704325,
          "lng": 39.151847036792034,
          "radius": 5,
          "georeferences": {
            "lat": 21.749726266704325,
            "lng": 39.151847036792034
          },
          "cartesians": {
            "x": 152.39284349947613,
            "y": 106.65887038932217
          }
        }
      },
      {
        "id": 132579,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ديوان للدعاية والإعلان والنشر والتوزيع _J15",
        "created_at": "2022-12-05T21:18:20.953+01:00",
        "updated_at": "2022-12-06T18:26:52.528+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 131.4953688447905,
          "y": 89.30301687433177,
          "lat": 21.749883214168747,
          "lng": 39.152048877248745,
          "radius": 5,
          "georeferences": {
            "lat": 21.749883214168747,
            "lng": 39.152048877248745
          },
          "cartesians": {
            "x": 131.4953688447905,
            "y": 89.30301687433177
          }
        }
      },
      {
        "id": 132633,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الادب في كل مكان _X15",
        "created_at": "2022-12-05T21:18:21.125+01:00",
        "updated_at": "2022-12-06T15:26:42.405+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 238.4857514330405,
          "y": 206.70106682408857,
          "lat": 21.748821927451807,
          "lng": 39.15101579733501,
          "radius": 5,
          "georeferences": {
            "lat": 21.748821927451807,
            "lng": 39.15101579733501
          },
          "cartesians": {
            "x": 238.4857514330405,
            "y": 206.70106682408857
          }
        }
      },
      {
        "id": 132632,
        "project_id": 12170,
        "building_id": 12170,
        "name": "النشر الرقمي _X13",
        "created_at": "2022-12-05T21:18:21.122+01:00",
        "updated_at": "2022-12-06T15:26:40.827+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 244.31581093843988,
          "y": 210.03965906628574,
          "lat": 21.748791719282593,
          "lng": 39.15095947172603,
          "radius": 5,
          "georeferences": {
            "lat": 21.748791719282593,
            "lng": 39.15095947172603
          },
          "cartesians": {
            "x": 244.31581093843988,
            "y": 210.03965906628574
          }
        }
      },
      {
        "id": 132631,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتب خوارزميات للإستشارات التعليمية _Y22",
        "created_at": "2022-12-05T21:18:21.119+01:00",
        "updated_at": "2022-12-06T15:14:47.995+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 230.69589802116622,
          "y": 186.7789352904679,
          "lat": 21.74900192431162,
          "lng": 39.15109089655661,
          "radius": 5,
          "georeferences": {
            "lat": 21.74900192431162,
            "lng": 39.15109089655661
          },
          "cartesians": {
            "x": 230.69589802116622,
            "y": 186.7789352904679
          }
        }
      },
      {
        "id": 132624,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جودة الحياة _Q25",
        "created_at": "2022-12-05T21:18:21.096+01:00",
        "updated_at": "2022-12-06T15:34:54.610+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 150.11984684897763,
          "y": 209.48490037317953,
          "lat": 21.748797643806466,
          "lng": 39.151870075717405,
          "radius": 5,
          "georeferences": {
            "lat": 21.748797643806466,
            "lng": 39.151870075717405
          },
          "cartesians": {
            "x": 150.11984684897763,
            "y": 209.48490037317953
          }
        }
      },
      {
        "id": 132626,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نقطة معلومات",
        "created_at": "2022-12-05T21:18:21.103+01:00",
        "updated_at": "2022-12-06T16:26:01.745+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Information Point",
        "category_id": 2213,
        "position": {
          "floor_id": 37595,
          "x": 241.01053008323737,
          "y": 108.38623688555114,
          "lat": 21.749709806245235,
          "lng": 39.150990369833465,
          "radius": 5,
          "georeferences": {
            "lat": 21.749709806245235,
            "lng": 39.150990369833465
          },
          "cartesians": {
            "x": 241.01053008323737,
            "y": 108.38623688555114
          }
        }
      },
      {
        "id": 132630,
        "project_id": 12170,
        "building_id": 12170,
        "name": "البريد السعودي | سبل _Z6",
        "created_at": "2022-12-05T21:18:21.115+01:00",
        "updated_at": "2022-12-06T17:57:39.394+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 242.22810677851925,
          "y": 170.90538282401062,
          "lat": 21.749145169982928,
          "lng": 39.150979247821795,
          "radius": 5,
          "georeferences": {
            "lat": 21.749145169982928,
            "lng": 39.150979247821795
          },
          "cartesians": {
            "x": 242.22810677851925,
            "y": 170.90538282401062
          }
        }
      },
      {
        "id": 132656,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مصلى",
        "created_at": "2022-12-05T21:18:21.201+01:00",
        "updated_at": "2022-12-05T21:18:21.201+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Prayer Rooms",
        "category_id": 2221,
        "position": {
          "floor_id": 37595,
          "x": 85.61466247632472,
          "y": 214.0408362086489,
          "lat": 21.74875712428806,
          "lng": 39.15249370643919,
          "radius": 5,
          "georeferences": {
            "lat": 21.74875712428806,
            "lng": 39.15249370643919
          },
          "cartesians": {
            "x": 85.61466247632472,
            "y": 214.0408362086489
          }
        }
      },
      {
        "id": 132648,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مصلى",
        "created_at": "2022-12-05T21:18:21.177+01:00",
        "updated_at": "2022-12-08T05:40:46.660+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Prayer Rooms",
        "category_id": 2221,
        "position": {
          "floor_id": 37595,
          "x": 260.19992461938455,
          "y": 111.98727366515226,
          "lat": 21.749677098199527,
          "lng": 39.150804899490105,
          "radius": 5,
          "georeferences": {
            "lat": 21.749677098199527,
            "lng": 39.150804899490105
          },
          "cartesians": {
            "x": 260.19992461938455,
            "y": 111.98727366515226
          }
        }
      },
      {
        "id": 132318,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مسرح 1",
        "created_at": "2022-12-05T21:18:19.860+01:00",
        "updated_at": "2022-12-10T21:31:33.072+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Theaters",
        "category_id": 2229,
        "position": {
          "floor_id": 37595,
          "x": 174.70229824301285,
          "y": 164.63552546055854,
          "lat": 21.749202449955053,
          "lng": 39.15163196758839,
          "radius": 5,
          "georeferences": {
            "lat": 21.749202449955053,
            "lng": 39.15163196758839
          },
          "cartesians": {
            "x": 174.70229824301285,
            "y": 164.63552546055854
          }
        }
      },
      {
        "id": 132789,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة المانجا",
        "created_at": "2022-12-09T00:22:25.497+01:00",
        "updated_at": "2022-12-10T21:25:39.104+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 232.47404177280885,
          "y": 141.54109181025666,
          "lat": 21.749410460171713,
          "lng": 39.15107323760411,
          "radius": 5,
          "georeferences": {
            "lat": 21.749410460171713,
            "lng": 39.15107323760411
          },
          "cartesians": {
            "x": 232.47404177280885,
            "y": 141.54109181025666
          }
        }
      },
      {
        "id": 132320,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مجموعة تكوين المتحدة للنشر والتوزيع _Y1",
        "created_at": "2022-12-05T21:18:19.867+01:00",
        "updated_at": "2022-12-06T15:21:54.663+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 222.98810466450132,
          "y": 178.8563292827412,
          "lat": 21.74907354996581,
          "lng": 39.15116532704263,
          "radius": 5,
          "georeferences": {
            "lat": 21.74907354996581,
            "lng": 39.15116532704263
          },
          "cartesians": {
            "x": 222.98810466450132,
            "y": 178.8563292827412
          }
        }
      },
      {
        "id": 132511,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Austin Macauley Publishers FZE _N25",
        "created_at": "2022-12-05T21:18:20.649+01:00",
        "updated_at": "2022-12-06T15:57:38.838+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 119.80728354090076,
          "y": 185.449863383699,
          "lat": 21.74901500386998,
          "lng": 39.152162863760175,
          "radius": 5,
          "georeferences": {
            "lat": 21.74901500386998,
            "lng": 39.152162863760175
          },
          "cartesians": {
            "x": 119.80728354090076,
            "y": 185.449863383699
          }
        }
      },
      {
        "id": 132325,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار العين للنشر والتوزيع _D5",
        "created_at": "2022-12-05T21:18:19.884+01:00",
        "updated_at": "2022-12-06T16:28:06.441+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 214.9753616501388,
          "y": 121.4188470318469,
          "lat": 21.749592358544156,
          "lng": 39.15124219209959,
          "radius": 5,
          "georeferences": {
            "lat": 21.749592358544156,
            "lng": 39.15124219209959
          },
          "cartesians": {
            "x": 214.9753616501388,
            "y": 121.4188470318469
          }
        }
      },
      {
        "id": 132369,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الرواق للنشر والتوزيع _D8",
        "created_at": "2022-12-05T21:18:20.049+01:00",
        "updated_at": "2022-12-06T16:28:10.029+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 221.34955927525874,
          "y": 114.5158496392126,
          "lat": 21.749654639188137,
          "lng": 39.15118049990547,
          "radius": 5,
          "georeferences": {
            "lat": 21.749654639188137,
            "lng": 39.15118049990547
          },
          "cartesians": {
            "x": 221.34955927525874,
            "y": 114.5158496392126
          }
        }
      },
      {
        "id": 132374,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دورات مياه",
        "created_at": "2022-12-05T21:18:20.068+01:00",
        "updated_at": "2022-12-08T03:15:42.793+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Restrooms",
        "category_id": 2225,
        "position": {
          "floor_id": 37595,
          "x": 241.0495999427056,
          "y": 44.548362215013356,
          "lat": 21.750286340044394,
          "lng": 39.1509893302606,
          "radius": 5,
          "georeferences": {
            "lat": 21.750286340044394,
            "lng": 39.1509893302606
          },
          "cartesians": {
            "x": 241.0495999427056,
            "y": 44.548362215013356
          }
        }
      },
      {
        "id": 132398,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مداد للنشر والتوزيع _G10",
        "created_at": "2022-12-05T21:18:20.156+01:00",
        "updated_at": "2022-12-06T18:22:02.166+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 177.75521143675874,
          "y": 83.25326033233728,
          "lat": 21.749937401827527,
          "lng": 39.15160161130358,
          "radius": 5,
          "georeferences": {
            "lat": 21.749937401827527,
            "lng": 39.15160161130358
          },
          "cartesians": {
            "x": 177.75521143675874,
            "y": 83.25326033233728
          }
        }
      },
      {
        "id": 132356,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الشروق للنشر _E1",
        "created_at": "2022-12-05T21:18:19.989+01:00",
        "updated_at": "2022-12-06T16:32:05.464+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 202.34654976300743,
          "y": 117.22551344892702,
          "lat": 21.74963035207085,
          "lng": 39.15136423384897,
          "radius": 5,
          "georeferences": {
            "lat": 21.74963035207085,
            "lng": 39.15136423384897
          },
          "cartesians": {
            "x": 202.34654976300743,
            "y": 117.22551344892702
          }
        }
      },
      {
        "id": 132324,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار التنوير _E19",
        "created_at": "2022-12-05T21:18:19.881+01:00",
        "updated_at": "2022-12-06T16:44:01.877+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 205.93758783244144,
          "y": 102.256840100745,
          "lat": 21.749765502661866,
          "lng": 39.151329363434456,
          "radius": 5,
          "georeferences": {
            "lat": 21.749765502661866,
            "lng": 39.151329363434456
          },
          "cartesians": {
            "x": 205.93758783244144,
            "y": 102.256840100745
          }
        }
      },
      {
        "id": 132342,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار شروق _D14",
        "created_at": "2022-12-05T21:18:19.942+01:00",
        "updated_at": "2022-12-06T16:39:53.258+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 230.9797370495068,
          "y": 103.67857792705064,
          "lat": 21.749752419540513,
          "lng": 39.15108729069744,
          "radius": 5,
          "georeferences": {
            "lat": 21.749752419540513,
            "lng": 39.15108729069744
          },
          "cartesians": {
            "x": 230.9797370495068,
            "y": 103.67857792705064
          }
        }
      },
      {
        "id": 132395,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الفارابي  لبنان _E7",
        "created_at": "2022-12-05T21:18:20.142+01:00",
        "updated_at": "2022-12-06T16:44:00.292+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 209.54665852947696,
          "y": 104.25290780115111,
          "lat": 21.749747440692452,
          "lng": 39.151294494511944,
          "radius": 5,
          "georeferences": {
            "lat": 21.749747440692452,
            "lng": 39.151294494511944
          },
          "cartesians": {
            "x": 209.54665852947696,
            "y": 104.25290780115111
          }
        }
      },
      {
        "id": 132629,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الوطن للنشر والتوزيع _D16",
        "created_at": "2022-12-05T21:18:21.112+01:00",
        "updated_at": "2022-12-06T16:39:59.693+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 226.15812068718674,
          "y": 102.95954993934356,
          "lat": 21.749758960047945,
          "lng": 39.1511338947738,
          "radius": 5,
          "georeferences": {
            "lat": 21.749758960047945,
            "lng": 39.1511338947738
          },
          "cartesians": {
            "x": 226.15812068718674,
            "y": 102.95954993934356
          }
        }
      },
      {
        "id": 132326,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الحكمة - لندن _E21",
        "created_at": "2022-12-05T21:18:19.887+01:00",
        "updated_at": "2022-12-06T16:45:13.372+01:00",
        "info": "<p> المملكة المتحدة - United Kingdom </p>",
        "info_unsafe": "<p> المملكة المتحدة - United Kingdom </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 202.89097463843785,
          "y": 107.36329945832094,
          "lat": 21.749719414654887,
          "lng": 39.15135886857411,
          "radius": 5,
          "georeferences": {
            "lat": 21.749719414654887,
            "lng": 39.15135886857411
          },
          "cartesians": {
            "x": 202.89097463843785,
            "y": 107.36329945832094
          }
        }
      },
      {
        "id": 132359,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جروس برس _E5",
        "created_at": "2022-12-05T21:18:20.012+01:00",
        "updated_at": "2022-12-06T16:42:15.069+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 206.50057852737672,
          "y": 109.84211686998282,
          "lat": 21.749696992859256,
          "lng": 39.151323999504115,
          "radius": 5,
          "georeferences": {
            "lat": 21.749696992859256,
            "lng": 39.151323999504115
          },
          "cartesians": {
            "x": 206.50057852737672,
            "y": 109.84211686998282
          }
        }
      },
      {
        "id": 132366,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سما للنشر والتوزيع _E11",
        "created_at": "2022-12-05T21:18:20.038+01:00",
        "updated_at": "2022-12-06T16:40:50.441+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 214.9473416510419,
          "y": 95.07516931056264,
          "lat": 21.749830274484065,
          "lng": 39.15124218990083,
          "radius": 5,
          "georeferences": {
            "lat": 21.749830274484065,
            "lng": 39.15124218990083
          },
          "cartesians": {
            "x": 214.9473416510419,
            "y": 95.07516931056264
          }
        }
      },
      {
        "id": 132429,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ابن حزم للطباعة والنشر والتوزيع _D15",
        "created_at": "2022-12-05T21:18:20.372+01:00",
        "updated_at": "2022-12-06T16:39:51.537+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 227.95945233687377,
          "y": 100.92326144239172,
          "lat": 21.749777332740766,
          "lng": 39.15111645982811,
          "radius": 5,
          "georeferences": {
            "lat": 21.749777332740766,
            "lng": 39.15111645982811
          },
          "cartesians": {
            "x": 227.95945233687377,
            "y": 100.92326144239172
          }
        }
      },
      {
        "id": 132319,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ابن النفيس للنشر  والتوزيع _D13",
        "created_at": "2022-12-05T21:18:19.864+01:00",
        "updated_at": "2022-12-06T16:40:01.199+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 229.0397159420802,
          "y": 105.74948615667827,
          "lat": 21.749733735535415,
          "lng": 39.15110606674148,
          "radius": 5,
          "georeferences": {
            "lat": 21.749733735535415,
            "lng": 39.15110606674148
          },
          "cartesians": {
            "x": 229.0397159420802,
            "y": 105.74948615667827
          }
        }
      },
      {
        "id": 132321,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة أفاق المعرفة _D17",
        "created_at": "2022-12-05T21:18:19.870+01:00",
        "updated_at": "2022-12-06T16:38:28.843+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 222.9364343125032,
          "y": 106.51451387953722,
          "lat": 21.749726885643728,
          "lng": 39.15116507631587,
          "radius": 5,
          "georeferences": {
            "lat": 21.749726885643728,
            "lng": 39.15116507631587
          },
          "cartesians": {
            "x": 222.9364343125032,
            "y": 106.51451387953722
          }
        }
      },
      {
        "id": 132327,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز مكتبة أمجاد _E14",
        "created_at": "2022-12-05T21:18:19.893+01:00",
        "updated_at": "2022-12-06T16:43:40.187+01:00",
        "info": "<p> عمان - Oman </p>",
        "info_unsafe": "<p> عمان - Oman </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 213.9680299257994,
          "y": 87.35241916643935,
          "lat": 21.74990002988075,
          "lng": 39.15125157706767,
          "radius": 5,
          "georeferences": {
            "lat": 21.74990002988075,
            "lng": 39.15125157706767
          },
          "cartesians": {
            "x": 213.9680299257994,
            "y": 87.35241916643935
          }
        }
      },
      {
        "id": 132338,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار عناوين بوكس _E13",
        "created_at": "2022-12-05T21:18:19.930+01:00",
        "updated_at": "2022-12-06T16:43:42.050+01:00",
        "info": "<p> اليمن - Yemen </p>",
        "info_unsafe": "<p> اليمن - Yemen </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 217.7856412512474,
          "y": 89.76204912106743,
          "lat": 21.749878230913637,
          "lng": 39.151214696416375,
          "radius": 5,
          "georeferences": {
            "lat": 21.749878230913637,
            "lng": 39.151214696416375
          },
          "cartesians": {
            "x": 217.7856412512474,
            "y": 89.76204912106743
          }
        }
      },
      {
        "id": 132361,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة تقاسيم للنشر والتوزيع _E17",
        "created_at": "2022-12-05T21:18:20.019+01:00",
        "updated_at": "2022-12-06T16:43:58.432+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 208.91423283146656,
          "y": 96.59874690840186,
          "lat": 21.749816573277062,
          "lng": 39.1513005289714,
          "radius": 5,
          "georeferences": {
            "lat": 21.749816573277062,
            "lng": 39.1513005289714
          },
          "cartesians": {
            "x": 208.91423283146656,
            "y": 96.59874690840186
          }
        }
      },
      {
        "id": 132586,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة دار الهلال _D19",
        "created_at": "2022-12-05T21:18:20.976+01:00",
        "updated_at": "2022-12-06T16:38:31.377+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 218.36378516561237,
          "y": 111.62256274998501,
          "lat": 21.74968079809491,
          "lng": 39.151209333981036,
          "radius": 5,
          "georeferences": {
            "lat": 21.74968079809491,
            "lng": 39.151209333981036
          },
          "cartesians": {
            "x": 218.36378516561237,
            "y": 111.62256274998501
          }
        }
      },
      {
        "id": 132367,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار سائر المشرق _E16",
        "created_at": "2022-12-05T21:18:20.042+01:00",
        "updated_at": "2022-12-06T16:43:47.328+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 211.1991604184252,
          "y": 92.73442617538879,
          "lat": 21.749851450644922,
          "lng": 39.15127840006725,
          "radius": 5,
          "georeferences": {
            "lat": 21.749851450644922,
            "lng": 39.15127840006725
          },
          "cartesians": {
            "x": 211.1991604184252,
            "y": 92.73442617538879
          }
        }
      },
      {
        "id": 132368,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الكرمة للنشر _D11",
        "created_at": "2022-12-05T21:18:20.045+01:00",
        "updated_at": "2022-12-06T16:36:54.533+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 225.85291524567987,
          "y": 109.47683879766632,
          "lat": 21.749700103911625,
          "lng": 39.15113691282509,
          "radius": 5,
          "georeferences": {
            "lat": 21.749700103911625,
            "lng": 39.15113691282509
          },
          "cartesians": {
            "x": 225.85291524567987,
            "y": 109.47683879766632
          }
        }
      },
      {
        "id": 132481,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار مدارك للنشر والتوزيع _E23",
        "created_at": "2022-12-05T21:18:20.548+01:00",
        "updated_at": "2022-12-06T16:44:44.913+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 198.80649333279482,
          "y": 114.88457075089244,
          "lat": 21.749651528013796,
          "lng": 39.15139843201379,
          "radius": 5,
          "georeferences": {
            "lat": 21.749651528013796,
            "lng": 39.15139843201379
          },
          "cartesians": {
            "x": 198.80649333279482,
            "y": 114.88457075089244
          }
        }
      },
      {
        "id": 132376,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات الفنار _F13",
        "created_at": "2022-12-05T21:18:20.074+01:00",
        "updated_at": "2022-12-06T18:21:01.017+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 197.8059907636413,
          "y": 87.33508983401248,
          "lat": 21.74990034327306,
          "lng": 39.151407818609655,
          "radius": 5,
          "georeferences": {
            "lat": 21.74990034327306,
            "lng": 39.151407818609655
          },
          "cartesians": {
            "x": 197.8059907636413,
            "y": 87.33508983401248
          }
        }
      },
      {
        "id": 132432,
        "project_id": 12170,
        "building_id": 12170,
        "name": "تنمية للنشر والتوزيع _F12",
        "created_at": "2022-12-05T21:18:20.381+01:00",
        "updated_at": "2022-12-06T18:21:02.349+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 197.2191144558962,
          "y": 89.88732869901554,
          "lat": 21.74987729912779,
          "lng": 39.15141351850381,
          "radius": 5,
          "georeferences": {
            "lat": 21.74987729912779,
            "lng": 39.15141351850381
          },
          "cartesians": {
            "x": 197.2191144558962,
            "y": 89.88732869901554
          }
        }
      },
      {
        "id": 132611,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ملهمون للنشر والتوزيع _D1",
        "created_at": "2022-12-05T21:18:21.054+01:00",
        "updated_at": "2022-12-06T16:44:41.591+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 207.0763720937873,
          "y": 129.42690444831885,
          "lat": 21.74952011265632,
          "lng": 39.151318636200834,
          "radius": 5,
          "georeferences": {
            "lat": 21.74952011265632,
            "lng": 39.151318636200834
          },
          "cartesians": {
            "x": 207.0763720937873,
            "y": 129.42690444831885
          }
        }
      },
      {
        "id": 132627,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار صفحة سبعة للنشر والتوزيع _D22",
        "created_at": "2022-12-05T21:18:21.106+01:00",
        "updated_at": "2022-12-06T16:38:39.090+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 212.1284114221727,
          "y": 118.59438983633618,
          "lat": 21.749617894486956,
          "lng": 39.151269684848344,
          "radius": 5,
          "georeferences": {
            "lat": 21.749617894486956,
            "lng": 39.151269684848344
          },
          "cartesians": {
            "x": 212.1284114221727,
            "y": 118.59438983633618
          }
        }
      },
      {
        "id": 132576,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة ديوان الشرق لإدارة المكتبات _E15",
        "created_at": "2022-12-05T21:18:20.944+01:00",
        "updated_at": "2022-12-06T16:43:44.029+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 212.51430974163733,
          "y": 90.11246441191548,
          "lat": 21.749875117404066,
          "lng": 39.15126565907963,
          "radius": 5,
          "georeferences": {
            "lat": 21.749875117404066,
            "lng": 39.15126565907963
          },
          "cartesians": {
            "x": 212.51430974163733,
            "y": 90.11246441191548
          }
        }
      },
      {
        "id": 132343,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ميلاد _E12",
        "created_at": "2022-12-05T21:18:19.946+01:00",
        "updated_at": "2022-12-06T16:42:28.913+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 216.2626346223891,
          "y": 92.5911282691666,
          "lat": 21.749852695648638,
          "lng": 39.151229448951966,
          "radius": 5,
          "georeferences": {
            "lat": 21.749852695648638,
            "lng": 39.151229448951966
          },
          "cartesians": {
            "x": 216.2626346223891,
            "y": 92.5911282691666
          }
        }
      },
      {
        "id": 132344,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ومكتبة الأسرة العربية _N38",
        "created_at": "2022-12-05T21:18:19.949+01:00",
        "updated_at": "2022-12-06T15:58:25.765+01:00",
        "info": "<p> تركيا - Turkey </p>",
        "info_unsafe": "<p> تركيا - Turkey </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 84.16306540665295,
          "y": 193.41988486965434,
          "lat": 21.748943370820008,
          "lng": 39.15250752564293,
          "radius": 5,
          "georeferences": {
            "lat": 21.748943370820008,
            "lng": 39.15250752564293
          },
          "cartesians": {
            "x": 84.16306540665295,
            "y": 193.41988486965434
          }
        }
      },
      {
        "id": 132354,
        "project_id": 12170,
        "building_id": 12170,
        "name": "اروى العربية للنشر _N1",
        "created_at": "2022-12-05T21:18:19.983+01:00",
        "updated_at": "2022-12-06T15:58:00.416+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 128.4004995509418,
          "y": 178.16487270512394,
          "lat": 21.749080712840744,
          "lng": 39.15207971604402,
          "radius": 5,
          "georeferences": {
            "lat": 21.749080712840744,
            "lng": 39.15207971604402
          },
          "cartesians": {
            "x": 128.4004995509418,
            "y": 178.16487270512394
          }
        }
      },
      {
        "id": 132355,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بيج بوكس Big Books _N27",
        "created_at": "2022-12-05T21:18:19.986+01:00",
        "updated_at": "2022-12-06T15:57:35.062+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 128.43976656972902,
          "y": 182.40605972127386,
          "lat": 21.749042409347435,
          "lng": 39.15207938038912,
          "radius": 5,
          "georeferences": {
            "lat": 21.749042409347435,
            "lng": 39.15207938038912
          },
          "cartesians": {
            "x": 128.43976656972902,
            "y": 182.40605972127386
          }
        }
      },
      {
        "id": 132347,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة ماس للنشر والتوزيع _M8",
        "created_at": "2022-12-05T21:18:19.959+01:00",
        "updated_at": "2022-12-06T15:55:25.943+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 107.90187040812347,
          "y": 144.6368620593991,
          "lat": 21.74938371081531,
          "lng": 39.15227753294512,
          "radius": 5,
          "georeferences": {
            "lat": 21.74938371081531,
            "lng": 39.15227753294512
          },
          "cartesians": {
            "x": 107.90187040812347,
            "y": 144.6368620593991
          }
        }
      },
      {
        "id": 132346,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الصقر للنشر والتوزيع _M11",
        "created_at": "2022-12-05T21:18:19.956+01:00",
        "updated_at": "2022-12-06T15:55:31.224+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 99.574965685091,
          "y": 141.68063746040704,
          "lat": 21.749410489973805,
          "lng": 39.152358000141426,
          "radius": 5,
          "georeferences": {
            "lat": 21.749410489973805,
            "lng": 39.152358000141426
          },
          "cartesians": {
            "x": 99.574965685091,
            "y": 141.68063746040704
          }
        }
      },
      {
        "id": 132575,
        "project_id": 12170,
        "building_id": 12170,
        "name": "النادي الأدبي الثقافي بجدة _F16",
        "created_at": "2022-12-05T21:18:20.940+01:00",
        "updated_at": "2022-12-06T17:59:06.310+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 191.84454112070372,
          "y": 90.9964691190944,
          "lat": 21.74986733440332,
          "lng": 39.15146548708525,
          "radius": 5,
          "georeferences": {
            "lat": 21.74986733440332,
            "lng": 39.15146548708525
          },
          "cartesians": {
            "x": 191.84454112070372,
            "y": 90.9964691190944
          }
        }
      },
      {
        "id": 132350,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المعاني للنشر والتوزيع _G9",
        "created_at": "2022-12-05T21:18:19.970+01:00",
        "updated_at": "2022-12-06T18:20:47.865+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 178.2723494917242,
          "y": 80.35628199843734,
          "lat": 21.749963560069272,
          "lng": 39.15159658202487,
          "radius": 5,
          "georeferences": {
            "lat": 21.749963560069272,
            "lng": 39.15159658202487
          },
          "cartesians": {
            "x": 178.2723494917242,
            "y": 80.35628199843734
          }
        }
      },
      {
        "id": 132503,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جامعة حمد بن خليفة للنشر _F7",
        "created_at": "2022-12-05T21:18:20.622+01:00",
        "updated_at": "2022-12-06T18:21:06.360+01:00",
        "info": "<p> قطر - Qatar </p>",
        "info_unsafe": "<p> قطر - Qatar </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 193.3134378734116,
          "y": 102.47715405855786,
          "lat": 21.749763635501402,
          "lng": 39.15145140590622,
          "radius": 5,
          "georeferences": {
            "lat": 21.749763635501402,
            "lng": 39.15145140590622
          },
          "cartesians": {
            "x": 193.3134378734116,
            "y": 102.47715405855786
          }
        }
      },
      {
        "id": 132349,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار مجرة _G14",
        "created_at": "2022-12-05T21:18:19.965+01:00",
        "updated_at": "2022-12-06T18:22:05.856+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 176.10736168624584,
          "y": 99.04744363073158,
          "lat": 21.74979477700812,
          "lng": 39.1516177049713,
          "radius": 5,
          "georeferences": {
            "lat": 21.74979477700812,
            "lng": 39.1516177049713
          },
          "cartesians": {
            "x": 176.10736168624584,
            "y": 99.04744363073158
          }
        }
      },
      {
        "id": 132345,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار رقش للنشر والتوزيع _J19",
        "created_at": "2022-12-05T21:18:19.953+01:00",
        "updated_at": "2022-12-06T18:26:42.108+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 139.2786789605791,
          "y": 102.74224440950363,
          "lat": 21.749761765933993,
          "lng": 39.15197377349877,
          "radius": 5,
          "georeferences": {
            "lat": 21.749761765933993,
            "lng": 39.15197377349877
          },
          "cartesians": {
            "x": 139.2786789605791,
            "y": 102.74224440950363
          }
        }
      },
      {
        "id": 132353,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار تأثير للنشر والتوزيع _J14",
        "created_at": "2022-12-05T21:18:19.979+01:00",
        "updated_at": "2022-12-06T18:26:51.024+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 129.89641633310242,
          "y": 85.99456123692687,
          "lat": 21.749913109095644,
          "lng": 39.152064300417486,
          "radius": 5,
          "georeferences": {
            "lat": 21.749913109095644,
            "lng": 39.152064300417486
          },
          "cartesians": {
            "x": 129.89641633310242,
            "y": 85.99456123692687
          }
        }
      },
      {
        "id": 132357,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بوابة D",
        "created_at": "2022-12-05T21:18:20.001+01:00",
        "updated_at": "2022-12-09T14:05:01.304+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Gates",
        "category_id": 2211,
        "position": {
          "floor_id": 37595,
          "x": 72.52963361495216,
          "y": 83.78168320733812,
          "lat": 21.749933650961555,
          "lng": 39.15261885393096,
          "radius": 5,
          "georeferences": {
            "lat": 21.749933650961555,
            "lng": 39.15261885393096
          },
          "cartesians": {
            "x": 72.52963361495216,
            "y": 83.78168320733812
          }
        }
      },
      {
        "id": 132394,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ART BOOK  SHOP _Z12",
        "created_at": "2022-12-05T21:18:20.138+01:00",
        "updated_at": "2022-12-06T17:57:17.693+01:00",
        "info": "<p> المملكة المتحدة - United Kingdom </p>",
        "info_unsafe": "<p> المملكة المتحدة - United Kingdom </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 255.93360921042319,
          "y": 176.71837461570638,
          "lat": 21.74909253850776,
          "lng": 39.15084681446651,
          "radius": 5,
          "georeferences": {
            "lat": 21.74909253850776,
            "lng": 39.15084681446651
          },
          "cartesians": {
            "x": 255.93360921042319,
            "y": 176.71837461570638
          }
        }
      },
      {
        "id": 132399,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جمعية الناشرين الإماراتيين _H14",
        "created_at": "2022-12-05T21:18:20.160+01:00",
        "updated_at": "2022-12-06T18:22:11.461+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 168.13160518463505,
          "y": 100.15941490217426,
          "lat": 21.749784811967693,
          "lng": 39.15169481972932,
          "radius": 5,
          "georeferences": {
            "lat": 21.749784811967693,
            "lng": 39.15169481972932
          },
          "cartesians": {
            "x": 168.13160518463505,
            "y": 100.15941490217426
          }
        }
      },
      {
        "id": 132449,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Turn The Page S.A.R.L _Z8",
        "created_at": "2022-12-05T21:18:20.437+01:00",
        "updated_at": "2022-12-06T17:57:36.646+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 248.81861932649147,
          "y": 171.7949739697952,
          "lat": 21.749137071910578,
          "lng": 39.150915545322036,
          "radius": 5,
          "georeferences": {
            "lat": 21.749137071910578,
            "lng": 39.150915545322036
          },
          "cartesians": {
            "x": 248.81861932649147,
            "y": 171.7949739697952
          }
        }
      },
      {
        "id": 132445,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة افاق المعرفة للنشر والتوزيع _H4",
        "created_at": "2022-12-05T21:18:20.425+01:00",
        "updated_at": "2022-12-06T18:21:48.588+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 163.4425463721668,
          "y": 93.68198559242578,
          "lat": 21.749843356603304,
          "lng": 39.151740082732786,
          "radius": 5,
          "georeferences": {
            "lat": 21.749843356603304,
            "lng": 39.151740082732786
          },
          "cartesians": {
            "x": 163.4425463721668,
            "y": 93.68198559242578
          }
        }
      },
      {
        "id": 132657,
        "project_id": 12170,
        "building_id": 12170,
        "name": "استقبال المسرح",
        "created_at": "2022-12-05T21:18:21.204+01:00",
        "updated_at": "2022-12-08T05:44:27.058+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Facilities",
        "category_id": 2210,
        "position": {
          "floor_id": 37595,
          "x": 203.33921504195786,
          "y": 163.01878944642135,
          "lat": 21.749216773078423,
          "lng": 39.15135511230673,
          "radius": 5,
          "georeferences": {
            "lat": 21.749216773078423,
            "lng": 39.15135511230673
          },
          "cartesians": {
            "x": 203.33921504195786,
            "y": 163.01878944642135
          }
        }
      },
      {
        "id": 132425,
        "project_id": 12170,
        "building_id": 12170,
        "name": "السري ارت للخط العربي والنشر والتوزيع _T4",
        "created_at": "2022-12-05T21:18:20.359+01:00",
        "updated_at": "2022-12-05T21:18:20.359+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 182.88698735012298,
          "y": 235.31074910184617,
          "lat": 21.748564086693115,
          "lng": 39.15155357766022,
          "radius": 5,
          "georeferences": {
            "lat": 21.748564086693115,
            "lng": 39.15155357766022
          },
          "cartesians": {
            "x": 182.88698735012298,
            "y": 235.31074910184617
          }
        }
      },
      {
        "id": 132492,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Bookeria _R21",
        "created_at": "2022-12-05T21:18:20.584+01:00",
        "updated_at": "2022-12-06T15:11:15.734+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Arts",
        "category_id": 2204,
        "position": {
          "floor_id": 37595,
          "x": 155.2763233510656,
          "y": 231.40957140125118,
          "lat": 21.748599587125454,
          "lng": 39.15182045450023,
          "radius": 5,
          "georeferences": {
            "lat": 21.748599587125454,
            "lng": 39.15182045450023
          },
          "cartesians": {
            "x": 155.2763233510656,
            "y": 231.40957140125118
          }
        }
      },
      {
        "id": 132554,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار خطوة للنشر والتوزيع _H12",
        "created_at": "2022-12-05T21:18:20.870+01:00",
        "updated_at": "2022-12-06T18:22:09.909+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 166.84023688630725,
          "y": 92.5749251098051,
          "lat": 21.74985332173327,
          "lng": 39.15170722510837,
          "radius": 5,
          "georeferences": {
            "lat": 21.74985332173327,
            "lng": 39.15170722510837
          },
          "cartesians": {
            "x": 166.84023688630725,
            "y": 92.5749251098051
          }
        }
      },
      {
        "id": 132606,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الكتاب التربوي للنشر _Q8",
        "created_at": "2022-12-05T21:18:21.040+01:00",
        "updated_at": "2022-12-05T21:18:21.040+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 136.95684316856617,
          "y": 224.32625712437908,
          "lat": 21.74866373595675,
          "lng": 39.15199747879201,
          "radius": 5,
          "georeferences": {
            "lat": 21.74866373595675,
            "lng": 39.15199747879201
          },
          "cartesians": {
            "x": 136.95684316856617,
            "y": 224.32625712437908
          }
        }
      },
      {
        "id": 132464,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Encyclomedia _T6",
        "created_at": "2022-12-05T21:18:20.486+01:00",
        "updated_at": "2022-12-06T15:11:52.178+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Engineering Sciences",
        "category_id": 2209,
        "position": {
          "floor_id": 37595,
          "x": 183.656335058524,
          "y": 241.2407557445187,
          "lat": 21.748510524003642,
          "lng": 39.15154620176977,
          "radius": 5,
          "georeferences": {
            "lat": 21.748510524003642,
            "lng": 39.15154620176977
          },
          "cartesians": {
            "x": 183.656335058524,
            "y": 241.2407557445187
          }
        }
      },
      {
        "id": 132542,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المعرفة للنشر والتوزيع _H11",
        "created_at": "2022-12-05T21:18:20.815+01:00",
        "updated_at": "2022-12-06T18:22:08.117+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 166.34872845428728,
          "y": 87.0239729542754,
          "lat": 21.74990345839956,
          "lng": 39.15171191914504,
          "radius": 5,
          "georeferences": {
            "lat": 21.74990345839956,
            "lng": 39.15171191914504
          },
          "cartesians": {
            "x": 166.34872845428728,
            "y": 87.0239729542754
          }
        }
      },
      {
        "id": 132661,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بوابة A",
        "created_at": "2022-12-05T21:18:21.220+01:00",
        "updated_at": "2022-12-05T21:18:21.220+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Gates",
        "category_id": 2211,
        "position": {
          "floor_id": 37595,
          "x": 274.2414246320742,
          "y": 74.94085702662079,
          "lat": 21.750011536402507,
          "lng": 39.150668773121055,
          "radius": 5,
          "georeferences": {
            "lat": 21.750011536402507,
            "lng": 39.150668773121055
          },
          "cartesians": {
            "x": 274.2414246320742,
            "y": 74.94085702662079
          }
        }
      },
      {
        "id": 132662,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بوابة C",
        "created_at": "2022-12-05T21:18:21.223+01:00",
        "updated_at": "2022-12-05T21:18:21.223+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Gates",
        "category_id": 2211,
        "position": {
          "floor_id": 37595,
          "x": 76.06045405649206,
          "y": 247.6492992071287,
          "lat": 21.748453691457343,
          "lng": 39.1525864171071,
          "radius": 5,
          "georeferences": {
            "lat": 21.748453691457343,
            "lng": 39.1525864171071
          },
          "cartesians": {
            "x": 76.06045405649206,
            "y": 247.6492992071287
          }
        }
      },
      {
        "id": 132649,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بوابة B",
        "created_at": "2022-12-05T21:18:21.180+01:00",
        "updated_at": "2022-12-05T21:18:21.180+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Gates",
        "category_id": 2211,
        "position": {
          "floor_id": 37595,
          "x": 276.24083389922487,
          "y": 246.05499572740956,
          "lat": 21.74846614679066,
          "lng": 39.1506512206305,
          "radius": 5,
          "georeferences": {
            "lat": 21.74846614679066,
            "lng": 39.1506512206305
          },
          "cartesians": {
            "x": 276.24083389922487,
            "y": 246.05499572740956
          }
        }
      },
      {
        "id": 132463,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Books and beyond _T8",
        "created_at": "2022-12-05T21:18:20.483+01:00",
        "updated_at": "2022-12-06T15:11:29.512+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Engineering Sciences",
        "category_id": 2209,
        "position": {
          "floor_id": 37595,
          "x": 184.49378812599556,
          "y": 245.99831776494852,
          "lat": 21.748467549263697,
          "lng": 39.15153815533011,
          "radius": 5,
          "georeferences": {
            "lat": 21.748467549263697,
            "lng": 39.15153815533011
          },
          "cartesians": {
            "x": 184.49378812599556,
            "y": 245.99831776494852
          }
        }
      },
      {
        "id": 132421,
        "project_id": 12170,
        "building_id": 12170,
        "name": "أطفال و معلمون للنشر و التوزيع _Q19",
        "created_at": "2022-12-05T21:18:20.345+01:00",
        "updated_at": "2022-12-06T15:35:00.967+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 141.43158132611885,
          "y": 225.07998754868734,
          "lat": 21.748656885411183,
          "lng": 39.15195422849691,
          "radius": 5,
          "georeferences": {
            "lat": 21.748656885411183,
            "lng": 39.15195422849691
          },
          "cartesians": {
            "x": 141.43158132611885,
            "y": 225.07998754868734
          }
        }
      },
      {
        "id": 132640,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة رواد المناهج السعودية للنشر والتوزيع _Q10",
        "created_at": "2022-12-05T21:18:21.153+01:00",
        "updated_at": "2022-12-05T21:18:21.153+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 133.56364035673704,
          "y": 229.50218290856847,
          "lat": 21.748617023947297,
          "lng": 39.15203033518052,
          "radius": 5,
          "georeferences": {
            "lat": 21.748617023947297,
            "lng": 39.15203033518052
          },
          "cartesians": {
            "x": 133.56364035673704,
            "y": 229.50218290856847
          }
        }
      },
      {
        "id": 132535,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المركز المصري لتبسيط العلوم _Q16",
        "created_at": "2022-12-05T21:18:20.731+01:00",
        "updated_at": "2022-12-06T15:35:05.272+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 136.1009414704979,
          "y": 234.63717991914382,
          "lat": 21.748570624006497,
          "lng": 39.15200585988579,
          "radius": 5,
          "georeferences": {
            "lat": 21.748570624006497,
            "lng": 39.15200585988579
          },
          "cartesians": {
            "x": 136.1009414704979,
            "y": 234.63717991914382
          }
        }
      },
      {
        "id": 132422,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز التفكر للتدريب والتطوير التربوي _Q12",
        "created_at": "2022-12-05T21:18:20.348+01:00",
        "updated_at": "2022-12-05T21:18:20.348+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 130.5180089896005,
          "y": 235.36737395995695,
          "lat": 21.748564083654472,
          "lng": 39.152059838682675,
          "radius": 5,
          "georeferences": {
            "lat": 21.748564083654472,
            "lng": 39.152059838682675
          },
          "cartesians": {
            "x": 130.5180089896005,
            "y": 235.36737395995695
          }
        }
      },
      {
        "id": 132537,
        "project_id": 12170,
        "building_id": 12170,
        "name": "اديوتك للنشر والتوزيع _Q14",
        "created_at": "2022-12-05T21:18:20.738+01:00",
        "updated_at": "2022-12-06T15:35:07.884+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 132.98564854759834,
          "y": 240.22658760552122,
          "lat": 21.748520175051365,
          "lng": 39.15203603396319,
          "radius": 5,
          "georeferences": {
            "lat": 21.748520175051365,
            "lng": 39.15203603396319
          },
          "cartesians": {
            "x": 132.98564854759834,
            "y": 240.22658760552122
          }
        }
      },
      {
        "id": 132496,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز ديبونو لتعليم التفكير _Q23",
        "created_at": "2022-12-05T21:18:20.598+01:00",
        "updated_at": "2022-12-06T15:34:56.679+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 147.45456655784065,
          "y": 214.31520505759872,
          "lat": 21.748754046112612,
          "lng": 39.15189589156063,
          "radius": 5,
          "georeferences": {
            "lat": 21.748754046112612,
            "lng": 39.15189589156063
          },
          "cartesians": {
            "x": 147.45456655784065,
            "y": 214.31520505759872
          }
        }
      },
      {
        "id": 132460,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة الشرق الأوسط للبرمجيات _Q21",
        "created_at": "2022-12-05T21:18:20.474+01:00",
        "updated_at": "2022-12-06T15:34:59.055+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 144.40835069840864,
          "y": 219.66314901571042,
          "lat": 21.748705777200165,
          "lng": 39.15192539534676,
          "radius": 5,
          "georeferences": {
            "lat": 21.748705777200165,
            "lng": 39.15192539534676
          },
          "cartesians": {
            "x": 144.40835069840864,
            "y": 219.66314901571042
          }
        }
      },
      {
        "id": 132570,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سمارت امازون للنشر والتوزيع _Q1",
        "created_at": "2022-12-05T21:18:20.924+01:00",
        "updated_at": "2022-12-05T21:18:20.924+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Education",
        "category_id": 2208,
        "position": {
          "floor_id": 37595,
          "x": 146.16398888252286,
          "y": 207.4892640996796,
          "lat": 21.748815705245747,
          "lng": 39.15190829703355,
          "radius": 5,
          "georeferences": {
            "lat": 21.748815705245747,
            "lng": 39.15190829703355
          },
          "cartesians": {
            "x": 146.16398888252286,
            "y": 207.4892640996796
          }
        }
      },
      {
        "id": 132426,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جبل عمان ناشرون _T1",
        "created_at": "2022-12-05T21:18:20.362+01:00",
        "updated_at": "2022-12-05T21:18:20.362+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Administrative and Financial Sciences",
        "category_id": 2203,
        "position": {
          "floor_id": 37595,
          "x": 181.83789723271568,
          "y": 227.24318790270155,
          "lat": 21.748636956833348,
          "lng": 39.1515636356995,
          "radius": 5,
          "georeferences": {
            "lat": 21.748636956833348,
            "lng": 39.1515636356995
          },
          "cartesians": {
            "x": 181.83789723271568,
            "y": 227.24318790270155
          }
        }
      },
      {
        "id": 132348,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دروب المعرفة للوسائل التعليمية _N23",
        "created_at": "2022-12-05T21:18:19.962+01:00",
        "updated_at": "2022-12-06T15:57:41.035+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 114.50265486649985,
          "y": 187.00733806155282,
          "lat": 21.749000989459212,
          "lng": 39.152214160735724,
          "radius": 5,
          "georeferences": {
            "lat": 21.749000989459212,
            "lng": 39.152214160735724
          },
          "cartesians": {
            "x": 114.50265486649985,
            "y": 187.00733806155282
          }
        }
      },
      {
        "id": 132358,
        "project_id": 12170,
        "building_id": 12170,
        "name": "واحة الحكايات للنشر والتوزيع _N39",
        "created_at": "2022-12-05T21:18:20.005+01:00",
        "updated_at": "2022-12-06T15:58:42.678+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 85.72863506561055,
          "y": 197.93522058635173,
          "lat": 21.74890257661237,
          "lng": 39.1524924377659,
          "radius": 5,
          "georeferences": {
            "lat": 21.74890257661237,
            "lng": 39.1524924377659
          },
          "cartesians": {
            "x": 85.72863506561055,
            "y": 197.93522058635173
          }
        }
      },
      {
        "id": 132384,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة دار مكتبة المعارف ناشرون ش.م.م _N4",
        "created_at": "2022-12-05T21:18:20.106+01:00",
        "updated_at": "2022-12-06T15:58:04.321+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 119.73314468244624,
          "y": 181.03629546920348,
          "lat": 21.74905486451248,
          "lng": 39.152163534744126,
          "radius": 5,
          "georeferences": {
            "lat": 21.74905486451248,
            "lng": 39.152163534744126
          },
          "cartesians": {
            "x": 119.73314468244624,
            "y": 181.03629546920348
          }
        }
      },
      {
        "id": 132385,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الهدى للنشر والتوزيع _M16",
        "created_at": "2022-12-05T21:18:20.109+01:00",
        "updated_at": "2022-12-06T15:56:48.477+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 89.19649977596498,
          "y": 133.83034162466961,
          "lat": 21.749481488503267,
          "lng": 39.152458249544644,
          "radius": 5,
          "georeferences": {
            "lat": 21.749481488503267,
            "lng": 39.152458249544644
          },
          "cartesians": {
            "x": 89.19649977596498,
            "y": 133.83034162466961
          }
        }
      },
      {
        "id": 132381,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مجموعة كلمات _M34",
        "created_at": "2022-12-05T21:18:20.097+01:00",
        "updated_at": "2022-12-06T16:00:43.574+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 114.64554366027971,
          "y": 158.8358248879871,
          "lat": 21.749255411332516,
          "lng": 39.152212487581984,
          "radius": 5,
          "georeferences": {
            "lat": 21.749255411332516,
            "lng": 39.152212487581984
          },
          "cartesians": {
            "x": 114.64554366027971,
            "y": 158.8358248879871
          }
        }
      },
      {
        "id": 132383,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ربيع للنشر والمستلزمات التعليمية _M1",
        "created_at": "2022-12-05T21:18:20.103+01:00",
        "updated_at": "2022-12-06T15:55:16.794+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 124.13861472314488,
          "y": 149.7568055476566,
          "lat": 21.7493373138438,
          "lng": 39.152120622222505,
          "radius": 5,
          "georeferences": {
            "lat": 21.7493373138438,
            "lng": 39.152120622222505
          },
          "cartesians": {
            "x": 124.13861472314488,
            "y": 149.7568055476566
          }
        }
      },
      {
        "id": 132390,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة سما للنشر والإنتاج والتوزيع - الإمارات _O17",
        "created_at": "2022-12-05T21:18:20.126+01:00",
        "updated_at": "2022-12-06T15:59:22.160+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 115.77735149989613,
          "y": 202.41916771909055,
          "lat": 21.748861789391825,
          "lng": 39.15220199771577,
          "radius": 5,
          "georeferences": {
            "lat": 21.748861789391825,
            "lng": 39.15220199771577
          },
          "cartesians": {
            "x": 115.77735149989613,
            "y": 202.41916771909055
          }
        }
      },
      {
        "id": 132382,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كليلة ودمنة دار نشر وتوزيع _M18",
        "created_at": "2022-12-05T21:18:20.099+01:00",
        "updated_at": "2022-12-06T15:56:45.498+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 95.09481854930203,
          "y": 135.9961487266786,
          "lat": 21.749461871357294,
          "lng": 39.152401251760594,
          "radius": 5,
          "georeferences": {
            "lat": 21.749461871357294,
            "lng": 39.152401251760594
          },
          "cartesians": {
            "x": 95.09481854930203,
            "y": 135.9961487266786
          }
        }
      },
      {
        "id": 132392,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة اسطفان _O1",
        "created_at": "2022-12-05T21:18:20.132+01:00",
        "updated_at": "2022-12-06T15:59:40.736+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 126.13511951345814,
          "y": 191.2703158974117,
          "lat": 21.7489623766313,
          "lng": 39.15210175170248,
          "radius": 5,
          "georeferences": {
            "lat": 21.7489623766313,
            "lng": 39.15210175170248
          },
          "cartesians": {
            "x": 126.13511951345814,
            "y": 191.2703158974117
          }
        }
      },
      {
        "id": 132405,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة النيل للنشر _L14",
        "created_at": "2022-12-05T21:18:20.179+01:00",
        "updated_at": "2022-12-06T15:56:31.997+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 104.23404307516057,
          "y": 120.43498240837303,
          "lat": 21.749602319028284,
          "lng": 39.152312740060985,
          "radius": 5,
          "georeferences": {
            "lat": 21.749602319028284,
            "lng": 39.152312740060985
          },
          "cartesians": {
            "x": 104.23404307516057,
            "y": 120.43498240837303
          }
        }
      },
      {
        "id": 132406,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة مناهج الطفولة التجارية _M17",
        "created_at": "2022-12-05T21:18:20.182+01:00",
        "updated_at": "2022-12-06T15:56:47.020+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 91.9374033380571,
          "y": 134.75829714734377,
          "lat": 21.749473081322215,
          "lng": 39.15243176229829,
          "radius": 5,
          "georeferences": {
            "lat": 21.749473081322215,
            "lng": 39.15243176229829
          },
          "cartesians": {
            "x": 91.9374033380571,
            "y": 134.75829714734377
          }
        }
      },
      {
        "id": 132407,
        "project_id": 12170,
        "building_id": 12170,
        "name": "روائع مجدلاوي للنشر _L7",
        "created_at": "2022-12-05T21:18:20.186+01:00",
        "updated_at": "2022-12-06T15:53:42.944+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 110.346942909658,
          "y": 128.60034210420793,
          "lat": 21.749528516492845,
          "lng": 39.15225372995706,
          "radius": 5,
          "georeferences": {
            "lat": 21.749528516492845,
            "lng": 39.15225372995706
          },
          "cartesians": {
            "x": 110.346942909658,
            "y": 128.60034210420793
          }
        }
      },
      {
        "id": 132404,
        "project_id": 12170,
        "building_id": 12170,
        "name": "BOOK LAND BOOKSHOP L.L.C _N19",
        "created_at": "2022-12-05T21:18:20.176+01:00",
        "updated_at": "2022-12-06T15:58:39.193+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 102.26514718195,
          "y": 191.7792354691495,
          "lat": 21.74895801217361,
          "lng": 39.15233251244903,
          "radius": 5,
          "georeferences": {
            "lat": 21.74895801217361,
            "lng": 39.15233251244903
          },
          "cartesians": {
            "x": 102.26514718195,
            "y": 191.7792354691495
          }
        }
      },
      {
        "id": 132456,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار العربية للعلوم ناشرون _R1",
        "created_at": "2022-12-05T21:18:20.461+01:00",
        "updated_at": "2022-12-05T21:18:20.461+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 158.16523833022723,
          "y": 208.7865636359525,
          "lat": 21.74880387254464,
          "lng": 39.15179229219282,
          "radius": 5,
          "georeferences": {
            "lat": 21.74880387254464,
            "lng": 39.15179229219282
          },
          "cartesians": {
            "x": 158.16523833022723,
            "y": 208.7865636359525
          }
        }
      },
      {
        "id": 132482,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الضياء للنشر و التوزيع _Z10",
        "created_at": "2022-12-05T21:18:20.551+01:00",
        "updated_at": "2022-12-06T17:57:20.747+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 253.32788414856822,
          "y": 172.37644200005013,
          "lat": 21.7491317767702,
          "lng": 39.15087195944113,
          "radius": 5,
          "georeferences": {
            "lat": 21.7491317767702,
            "lng": 39.15087195944113
          },
          "cartesians": {
            "x": 253.32788414856822,
            "y": 172.37644200005013
          }
        }
      },
      {
        "id": 132555,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار المصرية اللبنانية _H1",
        "created_at": "2022-12-05T21:18:20.874+01:00",
        "updated_at": "2022-12-06T18:21:50.121+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 164.49107365540726,
          "y": 101.19777345991787,
          "lat": 21.749775469657546,
          "lng": 39.151730024231675,
          "radius": 5,
          "georeferences": {
            "lat": 21.749775469657546,
            "lng": 39.151730024231675
          },
          "cartesians": {
            "x": 164.49107365540726,
            "y": 101.19777345991787
          }
        }
      },
      {
        "id": 132480,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ويلوز  هاوس للطباعة و النشر _H10",
        "created_at": "2022-12-05T21:18:20.544+01:00",
        "updated_at": "2022-12-06T18:21:58.868+01:00",
        "info": "<p> جنوب السودان - South Sudan </p>",
        "info_unsafe": "<p> جنوب السودان - South Sudan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 165.65116953218055,
          "y": 83.36971165649203,
          "lat": 21.749936467625044,
          "lng": 39.15171862475571,
          "radius": 5,
          "georeferences": {
            "lat": 21.749936467625044,
            "lng": 39.15171862475571
          },
          "cartesians": {
            "x": 165.65116953218055,
            "y": 83.36971165649203
          }
        }
      },
      {
        "id": 132664,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة الطفل القارئ للنشر والتوزيع _M14",
        "created_at": "2022-12-05T21:18:21.229+01:00",
        "updated_at": "2022-12-06T15:55:42.568+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 90.76217704877251,
          "y": 138.41462006942393,
          "lat": 21.749440071657183,
          "lng": 39.15244316127561,
          "radius": 5,
          "georeferences": {
            "lat": 21.749440071657183,
            "lng": 39.15244316127561
          },
          "cartesians": {
            "x": 90.76217704877251,
            "y": 138.41462006942393
          }
        }
      },
      {
        "id": 132665,
        "project_id": 12170,
        "building_id": 12170,
        "name": "تربية قيادية _M12",
        "created_at": "2022-12-05T21:18:21.233+01:00",
        "updated_at": "2022-12-06T15:55:34.262+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 96.66048516167882,
          "y": 140.58043742513465,
          "lat": 21.7494204544186,
          "lng": 39.15238616360179,
          "radius": 5,
          "georeferences": {
            "lat": 21.7494204544186,
            "lng": 39.15238616360179
          },
          "cartesians": {
            "x": 96.66048516167882,
            "y": 140.58043742513465
          }
        }
      },
      {
        "id": 132439,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار البراق لثقافة الأطفال _O19",
        "created_at": "2022-12-05T21:18:20.404+01:00",
        "updated_at": "2022-12-06T15:59:20.101+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 121.04590084280466,
          "y": 199.5859113506439,
          "lat": 21.748887326023606,
          "lng": 39.15215103630812,
          "radius": 5,
          "georeferences": {
            "lat": 21.748887326023606,
            "lng": 39.15215103630812
          },
          "cartesians": {
            "x": 121.04590084280466,
            "y": 199.5859113506439
          }
        }
      },
      {
        "id": 132437,
        "project_id": 12170,
        "building_id": 12170,
        "name": "books factory for publishing and distribution fze _M5",
        "created_at": "2022-12-05T21:18:20.397+01:00",
        "updated_at": "2022-12-06T15:55:19.380+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 116.0896313913965,
          "y": 147.21398018081666,
          "lat": 21.749360356803237,
          "lng": 39.152198406962356,
          "radius": 5,
          "georeferences": {
            "lat": 21.749360356803237,
            "lng": 39.152198406962356
          },
          "cartesians": {
            "x": 116.0896313913965,
            "y": 147.21398018081666
          }
        }
      },
      {
        "id": 132438,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار كتاب سامر للطباعة والنشر ش.م.م. _M9",
        "created_at": "2022-12-05T21:18:20.400+01:00",
        "updated_at": "2022-12-06T15:55:28.212+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 105.02214025820567,
          "y": 143.6055856316109,
          "lat": 21.7493930524583,
          "lng": 39.15230536117688,
          "radius": 5,
          "georeferences": {
            "lat": 21.7493930524583,
            "lng": 39.15230536117688
          },
          "cartesians": {
            "x": 105.02214025820567,
            "y": 143.6055856316109
          }
        }
      },
      {
        "id": 132441,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كتب نون : مؤسسة ناهد الشوا الثقافية _O21",
        "created_at": "2022-12-05T21:18:20.412+01:00",
        "updated_at": "2022-12-06T15:59:11.297+01:00",
        "info": "<p> كندا - Canada </p>",
        "info_unsafe": "<p> كندا - Canada </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 126.0017667408813,
          "y": 196.23577844651533,
          "lat": 21.748917533718778,
          "lng": 39.15210309231035,
          "radius": 5,
          "georeferences": {
            "lat": 21.748917533718778,
            "lng": 39.15210309231035
          },
          "cartesians": {
            "x": 126.0017667408813,
            "y": 196.23577844651533
          }
        }
      },
      {
        "id": 132440,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار عالم الثقافة للنشر والتوزيع _N31",
        "created_at": "2022-12-05T21:18:20.407+01:00",
        "updated_at": "2022-12-06T16:00:32.864+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 113.90054443417756,
          "y": 175.45664986647495,
          "lat": 21.7491053121623,
          "lng": 39.15221986178295,
          "radius": 5,
          "georeferences": {
            "lat": 21.7491053121623,
            "lng": 39.15221986178295
          },
          "cartesians": {
            "x": 113.90054443417756,
            "y": 175.45664986647495
          }
        }
      },
      {
        "id": 132582,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الاصيل للطباعة والنشر والتوزيع _N13",
        "created_at": "2022-12-05T21:18:20.964+01:00",
        "updated_at": "2022-12-06T15:58:15.491+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 96.08904746134232,
          "y": 189.23441638074502,
          "lat": 21.7489810549586,
          "lng": 39.15239219159282,
          "radius": 5,
          "georeferences": {
            "lat": 21.7489810549586,
            "lng": 39.15239219159282
          },
          "cartesians": {
            "x": 96.08904746134232,
            "y": 189.23441638074502
          }
        }
      },
      {
        "id": 132620,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مناهج للطفولة للنشر والتوزيع _O7",
        "created_at": "2022-12-05T21:18:21.084+01:00",
        "updated_at": "2022-12-06T15:59:35.953+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 113.24233589172917,
          "y": 199.42207612699298,
          "lat": 21.74888888140666,
          "lng": 39.152226473119995,
          "radius": 5,
          "georeferences": {
            "lat": 21.74888888140666,
            "lng": 39.152226473119995
          },
          "cartesians": {
            "x": 113.24233589172917,
            "y": 199.42207612699298
          }
        }
      },
      {
        "id": 132621,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار حكاية قمر للنشر والتوزيع _N14",
        "created_at": "2022-12-05T21:18:21.087+01:00",
        "updated_at": "2022-12-06T15:58:16.821+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 92.96891624477402,
          "y": 190.37573684573866,
          "lat": 21.74897077772462,
          "lng": 39.152422366307185,
          "radius": 5,
          "georeferences": {
            "lat": 21.74897077772462,
            "lng": 39.152422366307185
          },
          "cartesians": {
            "x": 92.96891624477402,
            "y": 190.37573684573866
          }
        }
      },
      {
        "id": 132623,
        "project_id": 12170,
        "building_id": 12170,
        "name": "وزارة البيئة والمياه والزراعة _O9",
        "created_at": "2022-12-05T21:18:21.093+01:00",
        "updated_at": "2022-12-06T15:59:33.728+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 107.76603528938927,
          "y": 202.56591127332575,
          "lat": 21.748860541884998,
          "lng": 39.15227944611359,
          "radius": 5,
          "georeferences": {
            "lat": 21.748860541884998,
            "lng": 39.15227944611359
          },
          "cartesians": {
            "x": 107.76603528938927,
            "y": 202.56591127332575
          }
        }
      },
      {
        "id": 132545,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة سفير - مصر _N15",
        "created_at": "2022-12-05T21:18:20.826+01:00",
        "updated_at": "2022-12-06T15:58:18.173+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 90.02224642623355,
          "y": 191.55137461568495,
          "lat": 21.748960188879703,
          "lng": 39.15245086449248,
          "radius": 5,
          "georeferences": {
            "lat": 21.748960188879703,
            "lng": 39.15245086449248
          },
          "cartesians": {
            "x": 90.02224642623355,
            "y": 191.55137461568495
          }
        }
      },
      {
        "id": 132509,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نسمات لتكنولوجيا المعلومات والنشر _M7",
        "created_at": "2022-12-05T21:18:20.642+01:00",
        "updated_at": "2022-12-06T15:55:23.421+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 111.4405943754067,
          "y": 145.70190676004503,
          "lat": 21.749374057806683,
          "lng": 39.152243334443774,
          "radius": 5,
          "georeferences": {
            "lat": 21.749374057806683,
            "lng": 39.152243334443774
          },
          "cartesians": {
            "x": 111.4405943754067,
            "y": 145.70190676004503
          }
        }
      },
      {
        "id": 132508,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المؤسسة الدولية للنشر والتوزيع _M10",
        "created_at": "2022-12-05T21:18:20.639+01:00",
        "updated_at": "2022-12-06T15:55:29.882+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 102.52394804351039,
          "y": 142.60837814505905,
          "lat": 21.749402082713296,
          "lng": 39.152329501361294,
          "radius": 5,
          "georeferences": {
            "lat": 21.749402082713296,
            "lng": 39.152329501361294
          },
          "cartesians": {
            "x": 102.52394804351039,
            "y": 142.60837814505905
          }
        }
      },
      {
        "id": 132515,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Gulf Horizons FZE _L11",
        "created_at": "2022-12-05T21:18:20.661+01:00",
        "updated_at": "2022-12-06T15:53:47.736+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 99.51894341651948,
          "y": 121.9918396680871,
          "lat": 21.74958830447083,
          "lng": 39.15235833796728,
          "radius": 5,
          "georeferences": {
            "lat": 21.74958830447083,
            "lng": 39.15235833796728
          },
          "cartesians": {
            "x": 99.51894341651948,
            "y": 121.9918396680871
          }
        }
      },
      {
        "id": 132514,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار أصالة _M23",
        "created_at": "2022-12-05T21:18:20.658+01:00",
        "updated_at": "2022-12-06T15:56:39.821+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 111.22806092224599,
          "y": 141.5988542261644,
          "lat": 21.749411115458315,
          "lng": 39.15224534656756,
          "radius": 5,
          "georeferences": {
            "lat": 21.749411115458315,
            "lng": 39.15224534656756
          },
          "cartesians": {
            "x": 111.22806092224599,
            "y": 141.5988542261644
          }
        }
      },
      {
        "id": 132547,
        "project_id": 12170,
        "building_id": 12170,
        "name": "كنوز للنشر و التوزيع _N37",
        "created_at": "2022-12-05T21:18:20.834+01:00",
        "updated_at": "2022-12-06T15:58:21.837+01:00",
        "info": "<p> تونس - Tunisia </p>",
        "info_unsafe": "<p> تونس - Tunisia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 87.14460248020316,
          "y": 192.41660894687226,
          "lat": 21.748952402687927,
          "lng": 39.152478692175954,
          "radius": 5,
          "georeferences": {
            "lat": 21.748952402687927,
            "lng": 39.152478692175954
          },
          "cartesians": {
            "x": 87.14460248020316,
            "y": 192.41660894687226
          }
        }
      },
      {
        "id": 132548,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الحافظ _O23",
        "created_at": "2022-12-05T21:18:20.837+01:00",
        "updated_at": "2022-12-06T15:59:09.206+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 130.78471338807876,
          "y": 193.33412227413768,
          "lat": 21.74894369279833,
          "lng": 39.1520568246012,
          "radius": 5,
          "georeferences": {
            "lat": 21.74894369279833,
            "lng": 39.1520568246012
          },
          "cartesians": {
            "x": 130.78471338807876,
            "y": 193.33412227413768
          }
        }
      },
      {
        "id": 132543,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ماستر دور للتجارة _N12",
        "created_at": "2022-12-05T21:18:20.819+01:00",
        "updated_at": "2022-12-06T15:58:13.637+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 98.65451535406177,
          "y": 188.33504934004353,
          "lat": 21.748989152440785,
          "lng": 39.15236738141862,
          "radius": 5,
          "georeferences": {
            "lat": 21.748989152440785,
            "lng": 39.15236738141862
          },
          "cartesians": {
            "x": 98.65451535406177,
            "y": 188.33504934004353
          }
        }
      },
      {
        "id": 132475,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ثري دي كمبيوتر سنتر _N40",
        "created_at": "2022-12-05T21:18:20.527+01:00",
        "updated_at": "2022-12-06T15:58:34.464+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 88.6408130539524,
          "y": 196.93202540328377,
          "lat": 21.748911608424258,
          "lng": 39.15246427480729,
          "radius": 5,
          "georeferences": {
            "lat": 21.748911608424258,
            "lng": 39.15246427480729
          },
          "cartesians": {
            "x": 88.6408130539524,
            "y": 196.93202540328377
          }
        }
      },
      {
        "id": 132607,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ورقة _S8",
        "created_at": "2022-12-05T21:18:21.043+01:00",
        "updated_at": "2022-12-06T15:30:52.506+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 163.1997058082412,
          "y": 246.29705269029574,
          "lat": 21.748465058026188,
          "lng": 39.15174401217592,
          "radius": 5,
          "georeferences": {
            "lat": 21.748465058026188,
            "lng": 39.15174401217592
          },
          "cartesians": {
            "x": 163.1997058082412,
            "y": 246.29705269029574
          }
        }
      },
      {
        "id": 132471,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار أشجار للنشر والتوزيع _M19",
        "created_at": "2022-12-05T21:18:20.510+01:00",
        "updated_at": "2022-12-06T15:56:43.748+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 99.43168064333241,
          "y": 137.47406155567248,
          "lat": 21.749448481896174,
          "lng": 39.152359341766996,
          "radius": 5,
          "georeferences": {
            "lat": 21.749448481896174,
            "lng": 39.152359341766996
          },
          "cartesians": {
            "x": 99.43168064333241,
            "y": 137.47406155567248
          }
        }
      },
      {
        "id": 132474,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الرواية الخليجية للنشر والتوزيع _M25",
        "created_at": "2022-12-05T21:18:20.522+01:00",
        "updated_at": "2022-12-06T15:56:38.175+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 117.09163459153027,
          "y": 143.7302771066298,
          "lat": 21.749391809181205,
          "lng": 39.152188684333616,
          "radius": 5,
          "georeferences": {
            "lat": 21.749391809181205,
            "lng": 39.152188684333616
          },
          "cartesians": {
            "x": 117.09163459153027,
            "y": 143.7302771066298
          }
        }
      },
      {
        "id": 132454,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الرقي للطباعة والنشر والتوزيع _V13",
        "created_at": "2022-12-05T21:18:20.452+01:00",
        "updated_at": "2022-12-06T15:28:10.933+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 216.8060268116478,
          "y": 235.82634821738787,
          "lat": 21.748559100944988,
          "lng": 39.15122568149083,
          "radius": 5,
          "georeferences": {
            "lat": 21.748559100944988,
            "lng": 39.15122568149083
          },
          "cartesians": {
            "x": 216.8060268116478,
            "y": 235.82634821738787
          }
        }
      },
      {
        "id": 132478,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار كادي ورمادي للنشر والتوزيع _M21",
        "created_at": "2022-12-05T21:18:20.537+01:00",
        "updated_at": "2022-12-06T15:56:41.951+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 105.36459451168142,
          "y": 139.57088672518216,
          "lat": 21.74942948740563,
          "lng": 39.152302008840195,
          "radius": 5,
          "georeferences": {
            "lat": 21.74942948740563,
            "lng": 39.152302008840195
          },
          "cartesians": {
            "x": 105.36459451168142,
            "y": 139.57088672518216
          }
        }
      },
      {
        "id": 132476,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة السعادة الفريدة للتجارة _N21",
        "created_at": "2022-12-05T21:18:20.531+01:00",
        "updated_at": "2022-12-06T15:57:43.781+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 108.05483412947498,
          "y": 189.7729432665779,
          "lat": 21.748976075247683,
          "lng": 39.152276521678374,
          "radius": 5,
          "georeferences": {
            "lat": 21.748976075247683,
            "lng": 39.152276521678374
          },
          "cartesians": {
            "x": 108.05483412947498,
            "y": 189.7729432665779
          }
        }
      },
      {
        "id": 132477,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الفُلك للترجمة والنشر _N17",
        "created_at": "2022-12-05T21:18:20.534+01:00",
        "updated_at": "2022-12-06T15:58:37.503+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 96.51048956791283,
          "y": 194.0958593949941,
          "lat": 21.74893714608415,
          "lng": 39.15238816779764,
          "radius": 5,
          "georeferences": {
            "lat": 21.74893714608415,
            "lng": 39.15238816779764
          },
          "cartesians": {
            "x": 96.51048956791283,
            "y": 194.0958593949941
          }
        }
      },
      {
        "id": 132614,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار العزيز للنشر والتوزيع _N16",
        "created_at": "2022-12-05T21:18:21.063+01:00",
        "updated_at": "2022-12-06T15:58:36.040+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 91.89976681364075,
          "y": 195.8939797078291,
          "lat": 21.748920951612995,
          "lng": 39.1524327591413,
          "radius": 5,
          "georeferences": {
            "lat": 21.748920951612995,
            "lng": 39.1524327591413
          },
          "cartesians": {
            "x": 91.89976681364075,
            "y": 195.8939797078291
          }
        }
      },
      {
        "id": 132546,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دعوة البشير للنشر _N11",
        "created_at": "2022-12-05T21:18:20.830+01:00",
        "updated_at": "2022-12-06T15:58:11.616+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 101.9133967881879,
          "y": 187.22803906539218,
          "lat": 21.748999118464678,
          "lng": 39.1523358657344,
          "radius": 5,
          "georeferences": {
            "lat": 21.748999118464678,
            "lng": 39.1523358657344
          },
          "cartesians": {
            "x": 101.9133967881879,
            "y": 187.22803906539218
          }
        }
      },
      {
        "id": 132573,
        "project_id": 12170,
        "building_id": 12170,
        "name": "البسمة للنشر والتوزيع _S7",
        "created_at": "2022-12-05T21:18:20.934+01:00",
        "updated_at": "2022-12-06T15:30:53.929+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 163.75097916160726,
          "y": 242.91726333208592,
          "lat": 21.74849557631143,
          "lng": 39.15173864784854,
          "radius": 5,
          "georeferences": {
            "lat": 21.74849557631143,
            "lng": 39.15173864784854
          },
          "cartesians": {
            "x": 163.75097916160726,
            "y": 242.91726333208592
          }
        }
      },
      {
        "id": 132567,
        "project_id": 12170,
        "building_id": 12170,
        "name": "PANWORLD EDUCATION _P26",
        "created_at": "2022-12-05T21:18:20.914+01:00",
        "updated_at": "2022-12-06T15:34:43.423+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 144.386269190042,
          "y": 199.18115980618342,
          "lat": 21.748890754857577,
          "lng": 39.151925396442046,
          "radius": 5,
          "georeferences": {
            "lat": 21.748890754857577,
            "lng": 39.151925396442046
          },
          "cartesians": {
            "x": 144.386269190042,
            "y": 199.18115980618342
          }
        }
      },
      {
        "id": 132462,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار مصر للنشر والتوزيع _S9",
        "created_at": "2022-12-05T21:18:20.480+01:00",
        "updated_at": "2022-12-06T15:30:50.922+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Law",
        "category_id": 2215,
        "position": {
          "floor_id": 37595,
          "x": 162.57907325108025,
          "y": 249.67691820425114,
          "lat": 21.7484345397264,
          "lng": 39.15175004701335,
          "radius": 5,
          "georeferences": {
            "lat": 21.7484345397264,
            "lng": 39.15175004701335
          },
          "cartesians": {
            "x": 162.57907325108025,
            "y": 249.67691820425114
          }
        }
      },
      {
        "id": 132501,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات الحلبي الحقوقية _S10",
        "created_at": "2022-12-05T21:18:20.615+01:00",
        "updated_at": "2022-12-05T21:18:20.615+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Law",
        "category_id": 2215,
        "position": {
          "floor_id": 37595,
          "x": 167.91838390146543,
          "y": 248.15397589095207,
          "lat": 21.748448241930955,
          "lng": 39.151698415129616,
          "radius": 5,
          "georeferences": {
            "lat": 21.748448241930955,
            "lng": 39.151698415129616
          },
          "cartesians": {
            "x": 167.91838390146543,
            "y": 248.15397589095207
          }
        }
      },
      {
        "id": 132412,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة ضحى _W15",
        "created_at": "2022-12-05T21:18:20.201+01:00",
        "updated_at": "2022-12-06T15:25:33.827+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 227.89507549141683,
          "y": 227.3321772011355,
          "lat": 21.74863570606732,
          "lng": 39.15111839347375,
          "radius": 5,
          "georeferences": {
            "lat": 21.74863570606732,
            "lng": 39.15111839347375
          },
          "cartesians": {
            "x": 227.89507549141683,
            "y": 227.3321772011355
          }
        }
      },
      {
        "id": 132416,
        "project_id": 12170,
        "building_id": 12170,
        "name": "وزارة الشؤون الاسلامية والدعوة والارشاد _R27",
        "created_at": "2022-12-05T21:18:20.219+01:00",
        "updated_at": "2022-12-05T21:18:20.219+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 161.08026893343617,
          "y": 210.43852491413,
          "lat": 21.748788925014715,
          "lng": 39.15176412918618,
          "radius": 5,
          "georeferences": {
            "lat": 21.748788925014715,
            "lng": 39.15176412918618
          },
          "cartesians": {
            "x": 161.08026893343617,
            "y": 210.43852491413
          }
        }
      },
      {
        "id": 132415,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار النور المبين للنشر والتوزيع _U13",
        "created_at": "2022-12-05T21:18:20.216+01:00",
        "updated_at": "2022-12-06T15:28:47.453+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 199.1940360426439,
          "y": 241.63794752369574,
          "lat": 21.748506786048853,
          "lng": 39.15139600011967,
          "radius": 5,
          "georeferences": {
            "lat": 21.748506786048853,
            "lng": 39.15139600011967
          },
          "cartesians": {
            "x": 199.1940360426439,
            "y": 241.63794752369574
          }
        }
      },
      {
        "id": 132507,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة مدينة الاذكياء للتجارة _L13",
        "created_at": "2022-12-05T21:18:20.636+01:00",
        "updated_at": "2022-12-06T18:25:55.897+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Children and Youth Literature",
        "category_id": 2207,
        "position": {
          "floor_id": 37595,
          "x": 101.49166706956879,
          "y": 118.16227282781284,
          "lat": 21.749622870999293,
          "lng": 39.15233922763588,
          "radius": 5,
          "georeferences": {
            "lat": 21.749622870999293,
            "lng": 39.15233922763588
          },
          "cartesians": {
            "x": 101.49166706956879,
            "y": 118.16227282781284
          }
        }
      },
      {
        "id": 132569,
        "project_id": 12170,
        "building_id": 12170,
        "name": "حنان صلواتي للنشر والتوزيع _Q11",
        "created_at": "2022-12-05T21:18:20.920+01:00",
        "updated_at": "2022-12-10T21:14:24.036+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Medical Sciences",
        "category_id": 2218,
        "position": {
          "floor_id": 37595,
          "x": 131.7628869917944,
          "y": 231.98681488123225,
          "lat": 21.748594602158292,
          "lng": 39.15204776915949,
          "radius": 5,
          "georeferences": {
            "lat": 21.748594602158292,
            "lng": 39.15204776915949
          },
          "cartesians": {
            "x": 131.7628869917944,
            "y": 231.98681488123225
          }
        }
      },
      {
        "id": 132420,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار التربويون للنشر والتوزيع _P11",
        "created_at": "2022-12-05T21:18:20.342+01:00",
        "updated_at": "2022-12-06T15:40:53.590+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 121.27467287130239,
          "y": 218.68841316494985,
          "lat": 21.74871480482047,
          "lng": 39.15214902274464,
          "radius": 5,
          "georeferences": {
            "lat": 21.74871480482047,
            "lng": 39.15214902274464
          },
          "cartesians": {
            "x": 121.27467287130239,
            "y": 218.68841316494985
          }
        }
      },
      {
        "id": 132670,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز بحوث ودراسات المدينة المنورة _X10",
        "created_at": "2022-12-05T21:18:21.248+01:00",
        "updated_at": "2022-12-06T15:24:24.414+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 242.12406282248122,
          "y": 203.62839890668562,
          "lat": 21.748849642088,
          "lng": 39.15098059321603,
          "radius": 5,
          "georeferences": {
            "lat": 21.748849642088,
            "lng": 39.15098059321603
          },
          "cartesians": {
            "x": 242.12406282248122,
            "y": 203.62839890668562
          }
        }
      },
      {
        "id": 132672,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة درسات تكوين للنشر والتوزيع _R25",
        "created_at": "2022-12-05T21:18:21.254+01:00",
        "updated_at": "2022-12-05T21:18:21.254+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 159.1475192592922,
          "y": 219.19891966375872,
          "lat": 21.74870982668447,
          "lng": 39.15178290428794,
          "radius": 5,
          "georeferences": {
            "lat": 21.74870982668447,
            "lng": 39.15178290428794
          },
          "cartesians": {
            "x": 159.1475192592922,
            "y": 219.19891966375872
          }
        }
      },
      {
        "id": 132637,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز البحوث والتواصل المعرفي _V18",
        "created_at": "2022-12-05T21:18:21.138+01:00",
        "updated_at": "2022-12-06T15:27:51.126+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 209.5879382749721,
          "y": 231.7651783559175,
          "lat": 21.74859584834869,
          "lng": 39.1512954179161,
          "radius": 5,
          "georeferences": {
            "lat": 21.74859584834869,
            "lng": 39.1512954179161
          },
          "cartesians": {
            "x": 209.5879382749721,
            "y": 231.7651783559175
          }
        }
      },
      {
        "id": 132451,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دارة الملك عبدالعزيز _X1",
        "created_at": "2022-12-05T21:18:20.443+01:00",
        "updated_at": "2022-12-06T15:24:34.948+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 221.75260838952437,
          "y": 191.13306794885696,
          "lat": 21.748962687975897,
          "lng": 39.15117739818431,
          "radius": 5,
          "georeferences": {
            "lat": 21.748962687975897,
            "lng": 39.15117739818431
          },
          "cartesians": {
            "x": 221.75260838952437,
            "y": 191.13306794885696
          }
        }
      },
      {
        "id": 132424,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة الإبداع الفكري للنشر والتوزيع _T12",
        "created_at": "2022-12-05T21:18:20.355+01:00",
        "updated_at": "2022-12-06T15:30:12.331+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 179.35864199124154,
          "y": 243.86596418572074,
          "lat": 21.748486856875175,
          "lng": 39.15158777558917,
          "radius": 5,
          "georeferences": {
            "lat": 21.748486856875175,
            "lng": 39.15158777558917
          },
          "cartesians": {
            "x": 179.35864199124154,
            "y": 243.86596418572074
          }
        }
      },
      {
        "id": 132536,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الفاروق للنشر والتوزيع _Q15",
        "created_at": "2022-12-05T21:18:20.734+01:00",
        "updated_at": "2022-12-06T15:35:06.657+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 134.4047914238156,
          "y": 237.63892232305062,
          "lat": 21.748543531061692,
          "lng": 39.15202228801093,
          "radius": 5,
          "georeferences": {
            "lat": 21.748543531061692,
            "lng": 39.15202228801093
          },
          "cartesians": {
            "x": 134.4047914238156,
            "y": 237.63892232305062
          }
        }
      },
      {
        "id": 132518,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الوفاق الحديثة للنشر والتوزيع _Y16",
        "created_at": "2022-12-05T21:18:20.670+01:00",
        "updated_at": "2022-12-06T15:17:04.055+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 250.5419858193107,
          "y": 194.41302788073142,
          "lat": 21.748932786458866,
          "lng": 39.15089911994714,
          "radius": 5,
          "georeferences": {
            "lat": 21.748932786458866,
            "lng": 39.15089911994714
          },
          "cartesians": {
            "x": 250.5419858193107,
            "y": 194.41302788073142
          }
        }
      },
      {
        "id": 132519,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المركز القومي للترجمة - وزارة الثقافة المصرية _Y15",
        "created_at": "2022-12-05T21:18:20.673+01:00",
        "updated_at": "2022-12-06T15:17:02.794+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 253.52570789847982,
          "y": 195.44435271121205,
          "lat": 21.748923443369296,
          "lng": 39.15087028645519,
          "radius": 5,
          "georeferences": {
            "lat": 21.748923443369296,
            "lng": 39.15087028645519
          },
          "cartesians": {
            "x": 253.52570789847982,
            "y": 195.44435271121205
          }
        }
      },
      {
        "id": 132520,
        "project_id": 12170,
        "building_id": 12170,
        "name": "البحاث العلمي _X9",
        "created_at": "2022-12-05T21:18:20.676+01:00",
        "updated_at": "2022-12-06T15:24:26.338+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 239.86848131077335,
          "y": 202.42390644404122,
          "lat": 21.74886054202516,
          "lng": 39.151002385838936,
          "radius": 5,
          "georeferences": {
            "lat": 21.74886054202516,
            "lng": 39.151002385838936
          },
          "cartesians": {
            "x": 239.86848131077335,
            "y": 202.42390644404122
          }
        }
      },
      {
        "id": 132522,
        "project_id": 12170,
        "building_id": 12170,
        "name": "اكاديميا انترناشيونال ودار الكتاب العربي - بيروت - لبنان _V5",
        "created_at": "2022-12-05T21:18:20.683+01:00",
        "updated_at": "2022-12-06T15:28:00.075+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 205.82600562003427,
          "y": 216.45935859239867,
          "lat": 21.748734115160815,
          "lng": 39.15133162637625,
          "radius": 5,
          "georeferences": {
            "lat": 21.748734115160815,
            "lng": 39.15133162637625
          },
          "cartesians": {
            "x": 205.82600562003427,
            "y": 216.45935859239867
          }
        }
      },
      {
        "id": 132521,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة الحكمي _W10",
        "created_at": "2022-12-05T21:18:20.679+01:00",
        "updated_at": "2022-12-06T15:25:49.686+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 224.41576914271803,
          "y": 216.85346126618867,
          "lat": 21.74873037547762,
          "lng": 39.15115191980906,
          "radius": 5,
          "georeferences": {
            "lat": 21.74873037547762,
            "lng": 39.15115191980906
          },
          "cartesians": {
            "x": 224.41576914271803,
            "y": 216.85346126618867
          }
        }
      },
      {
        "id": 132512,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز عباقر للنشر والتوزيع _O5",
        "created_at": "2022-12-05T21:18:20.652+01:00",
        "updated_at": "2022-12-06T15:59:43.744+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 118.47557329433579,
          "y": 196.00267251833148,
          "lat": 21.74891971200856,
          "lng": 39.15217584700545,
          "radius": 5,
          "georeferences": {
            "lat": 21.74891971200856,
            "lng": 39.15217584700545
          },
          "cartesians": {
            "x": 118.47557329433579,
            "y": 196.00267251833148
          }
        }
      },
      {
        "id": 132557,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة دار الامان للطباعة والنشر والتوزيع _Y12",
        "created_at": "2022-12-05T21:18:20.881+01:00",
        "updated_at": "2022-12-06T15:21:48.383+01:00",
        "info": "<p> المغرب - Morocco </p>",
        "info_unsafe": "<p> المغرب - Morocco </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 249.6338138089843,
          "y": 188.34521612519836,
          "lat": 21.74898759504472,
          "lng": 39.15090783643887,
          "radius": 5,
          "georeferences": {
            "lat": 21.74898759504472,
            "lng": 39.15090783643887
          },
          "cartesians": {
            "x": 249.6338138089843,
            "y": 188.34521612519836
          }
        }
      },
      {
        "id": 132558,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة المعارف الحديثة _Y14",
        "created_at": "2022-12-05T21:18:20.884+01:00",
        "updated_at": "2022-12-06T15:21:45.509+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 255.04613549945896,
          "y": 190.20158074192713,
          "lat": 21.748970777260997,
          "lng": 39.15085553378158,
          "radius": 5,
          "georeferences": {
            "lat": 21.748970777260997,
            "lng": 39.15085553378158
          },
          "cartesians": {
            "x": 255.04613549945896,
            "y": 190.20158074192713
          }
        }
      },
      {
        "id": 132559,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز الملك فيصل للبحوث والدراسات الإسلامية _X5",
        "created_at": "2022-12-05T21:18:20.887+01:00",
        "updated_at": "2022-12-06T15:24:32.515+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 230.63711180945666,
          "y": 196.74416681048908,
          "lat": 21.748911926639217,
          "lng": 39.15109156825746,
          "radius": 5,
          "georeferences": {
            "lat": 21.748911926639217,
            "lng": 39.15109156825746
          },
          "cartesians": {
            "x": 230.63711180945666,
            "y": 196.74416681048908
          }
        }
      },
      {
        "id": 132551,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة آفاق للنشر والتوزيع _L1",
        "created_at": "2022-12-05T21:18:20.859+01:00",
        "updated_at": "2022-12-06T15:53:31.804+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 126.20720628299219,
          "y": 138.3411999446894,
          "lat": 21.74944039066086,
          "lng": 39.15210050653811,
          "radius": 5,
          "georeferences": {
            "lat": 21.74944039066086,
            "lng": 39.15210050653811
          },
          "cartesians": {
            "x": 126.20720628299219,
            "y": 138.3411999446894
          }
        }
      },
      {
        "id": 132495,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مدبولي للنشر والتوزيع _R20",
        "created_at": "2022-12-05T21:18:20.594+01:00",
        "updated_at": "2022-12-05T21:18:20.594+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 153.96280915565896,
          "y": 235.47981742716033,
          "lat": 21.748562840570244,
          "lng": 39.15183319470345,
          "radius": 5,
          "georeferences": {
            "lat": 21.748562840570244,
            "lng": 39.15183319470345
          },
          "cartesians": {
            "x": 153.96280915565896,
            "y": 235.47981742716033
          }
        }
      },
      {
        "id": 132499,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار اللباب _Q9",
        "created_at": "2022-12-05T21:18:20.608+01:00",
        "updated_at": "2022-12-05T21:18:20.608+01:00",
        "info": "<p> تركيا - Turkey </p>",
        "info_unsafe": "<p> تركيا - Turkey </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 135.71091616877573,
          "y": 226.7413239370114,
          "lat": 21.748641937040563,
          "lng": 39.152009548441384,
          "radius": 5,
          "georeferences": {
            "lat": 21.748641937040563,
            "lng": 39.152009548441384
          },
          "cartesians": {
            "x": 135.71091616877573,
            "y": 226.7413239370114
          }
        }
      },
      {
        "id": 132500,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز دراسات الوحدة العربية _S4",
        "created_at": "2022-12-05T21:18:20.611+01:00",
        "updated_at": "2022-12-06T15:30:57.241+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 164.85107006358976,
          "y": 233.88190314886563,
          "lat": 21.748577165998956,
          "lng": 39.1517279193281,
          "radius": 5,
          "georeferences": {
            "lat": 21.748577165998956,
            "lng": 39.1517279193281
          },
          "cartesians": {
            "x": 164.85107006358976,
            "y": 233.88190314886563
          }
        }
      },
      {
        "id": 132534,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ليتيل بافن ببليشينغ هاوس م م ح _P9",
        "created_at": "2022-12-05T21:18:20.725+01:00",
        "updated_at": "2022-12-06T15:40:41.691+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 125.5009045188535,
          "y": 214.13222958349087,
          "lat": 21.748755911712546,
          "lng": 39.15210811975057,
          "radius": 5,
          "georeferences": {
            "lat": 21.748755911712546,
            "lng": 39.15210811975057
          },
          "cartesians": {
            "x": 125.5009045188535,
            "y": 214.13222958349087
          }
        }
      },
      {
        "id": 132594,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مجمع الملك سلمان العالمي للغة العربية _W25",
        "created_at": "2022-12-05T21:18:21.001+01:00",
        "updated_at": "2022-12-06T15:25:42.318+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 204.9774641448851,
          "y": 201.28839717472246,
          "lat": 21.74887113575639,
          "lng": 39.151339671980914,
          "radius": 5,
          "georeferences": {
            "lat": 21.74887113575639,
            "lng": 39.151339671980914
          },
          "cartesians": {
            "x": 204.9774641448851,
            "y": 201.28839717472246
          }
        }
      },
      {
        "id": 132530,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مفكرون الدولية للنشر و التوزيع _R23",
        "created_at": "2022-12-05T21:18:20.709+01:00",
        "updated_at": "2022-12-05T21:18:20.709+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 157.21158455986472,
          "y": 224.99390986005784,
          "lat": 21.748657509619374,
          "lng": 39.15180167942893,
          "radius": 5,
          "georeferences": {
            "lat": 21.748657509619374,
            "lng": 39.15180167942893
          },
          "cartesians": {
            "x": 157.21158455986472,
            "y": 224.99390986005784
          }
        }
      },
      {
        "id": 132523,
        "project_id": 12170,
        "building_id": 12170,
        "name": "متجر ضفة _U5",
        "created_at": "2022-12-05T21:18:20.686+01:00",
        "updated_at": "2022-12-06T15:28:54.680+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 192.16323801192453,
          "y": 218.12902230286448,
          "lat": 21.748719168679425,
          "lng": 39.15146372418167,
          "radius": 5,
          "georeferences": {
            "lat": 21.748719168679425,
            "lng": 39.15146372418167
          },
          "cartesians": {
            "x": 192.16323801192453,
            "y": 218.12902230286448
          }
        }
      },
      {
        "id": 132526,
        "project_id": 12170,
        "building_id": 12170,
        "name": "رف للنشر  Raff publishing _V25",
        "created_at": "2022-12-05T21:18:20.695+01:00",
        "updated_at": "2022-12-06T15:27:45.035+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 196.80046745838683,
          "y": 208.67614328795358,
          "lat": 21.748804494737836,
          "lng": 39.15141879716196,
          "radius": 5,
          "georeferences": {
            "lat": 21.748804494737836,
            "lng": 39.15141879716196
          },
          "cartesians": {
            "x": 196.80046745838683,
            "y": 208.67614328795358
          }
        }
      },
      {
        "id": 132595,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة لبنان ناشرون _V22",
        "created_at": "2022-12-05T21:18:21.004+01:00",
        "updated_at": "2022-12-06T15:27:55.435+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 202.63802863427992,
          "y": 219.01438018674995,
          "lat": 21.748711071133386,
          "lng": 39.151362471645825,
          "radius": 5,
          "georeferences": {
            "lat": 21.748711071133386,
            "lng": 39.151362471645825
          },
          "cartesians": {
            "x": 202.63802863427992,
            "y": 219.01438018674995
          }
        }
      },
      {
        "id": 132610,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة النيل العربية _T13",
        "created_at": "2022-12-05T21:18:21.051+01:00",
        "updated_at": "2022-12-06T15:30:14.101+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 178.9387699851036,
          "y": 240.41825554884565,
          "lat": 21.748517997981686,
          "lng": 39.15159179879182,
          "radius": 5,
          "georeferences": {
            "lat": 21.748517997981686,
            "lng": 39.15159179879182
          },
          "cartesians": {
            "x": 178.9387699851036,
            "y": 240.41825554884565
          }
        }
      },
      {
        "id": 132453,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار حبر للنشر _V17",
        "created_at": "2022-12-05T21:18:20.449+01:00",
        "updated_at": "2022-12-06T15:27:49.170+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 211.32487996112258,
          "y": 234.45289691889494,
          "lat": 21.74857155809816,
          "lng": 39.1512786544789,
          "radius": 5,
          "georeferences": {
            "lat": 21.74857155809816,
            "lng": 39.1512786544789
          },
          "cartesians": {
            "x": 211.32487996112258,
            "y": 234.45289691889494
          }
        }
      },
      {
        "id": 132487,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة العبيكان للتعليم _U27",
        "created_at": "2022-12-05T21:18:20.567+01:00",
        "updated_at": "2022-12-06T15:28:27.665+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 185.01167421557247,
          "y": 211.44725165350593,
          "lat": 21.748779582671666,
          "lng": 39.15153279034577,
          "radius": 5,
          "georeferences": {
            "lat": 21.748779582671666,
            "lng": 39.15153279034577
          },
          "cartesians": {
            "x": 185.01167421557247,
            "y": 211.44725165350593
          }
        }
      },
      {
        "id": 132491,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار البشائر الإسلامية _R7",
        "created_at": "2022-12-05T21:18:20.580+01:00",
        "updated_at": "2022-12-05T21:18:20.580+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 153.94952399949744,
          "y": 223.13543850907757,
          "lat": 21.74867432555666,
          "lng": 39.15183319508868,
          "radius": 5,
          "georeferences": {
            "lat": 21.74867432555666,
            "lng": 39.15183319508868
          },
          "cartesians": {
            "x": 153.94952399949744,
            "y": 223.13543850907757
          }
        }
      },
      {
        "id": 132608,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة الانجلو المصرية _S13",
        "created_at": "2022-12-05T21:18:21.045+01:00",
        "updated_at": "2022-12-05T21:18:21.045+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 169.15669177618702,
          "y": 238.6357246792557,
          "lat": 21.748534191374116,
          "lng": 39.151686345421254,
          "radius": 5,
          "georeferences": {
            "lat": 21.748534191374116,
            "lng": 39.151686345421254
          },
          "cartesians": {
            "x": 169.15669177618702,
            "y": 238.6357246792557
          }
        }
      },
      {
        "id": 132489,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ابن كثير _R5",
        "created_at": "2022-12-05T21:18:20.574+01:00",
        "updated_at": "2022-12-05T21:18:20.574+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 155.60785398596462,
          "y": 217.20282040303664,
          "lat": 21.748727888265037,
          "lng": 39.15181710217901,
          "radius": 5,
          "georeferences": {
            "lat": 21.748727888265037,
            "lng": 39.15181710217901
          },
          "cartesians": {
            "x": 155.60785398596462,
            "y": 217.20282040303664
          }
        }
      },
      {
        "id": 132465,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة جرير _G18",
        "created_at": "2022-12-05T21:18:20.489+01:00",
        "updated_at": "2022-12-08T05:42:02.667+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 173.6419849873468,
          "y": 128.70401290114256,
          "lat": 21.74952696580463,
          "lng": 39.1516418454701,
          "radius": 5,
          "georeferences": {
            "lat": 21.74952696580463,
            "lng": 39.1516418454701
          },
          "cartesians": {
            "x": 173.6419849873468,
            "y": 128.70401290114256
          }
        }
      },
      {
        "id": 132619,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ثقافة للنشر والتوزيع _K13",
        "created_at": "2022-12-05T21:18:21.080+01:00",
        "updated_at": "2022-12-06T18:26:07.509+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 114.86559401958993,
          "y": 105.7342471276842,
          "lat": 21.749734981466975,
          "lng": 39.15220981064092,
          "radius": 5,
          "georeferences": {
            "lat": 21.749734981466975,
            "lng": 39.15220981064092
          },
          "cartesians": {
            "x": 114.86559401958993,
            "y": 105.7342471276842
          }
        }
      },
      {
        "id": 132587,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة محمد أحمد  محمد الحازمي للنشر _J16",
        "created_at": "2022-12-05T21:18:20.979+01:00",
        "updated_at": "2022-12-06T18:26:53.872+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 133.2330529848678,
          "y": 92.6113206510885,
          "lat": 21.749853319266723,
          "lng": 39.15203211293597,
          "radius": 5,
          "georeferences": {
            "lat": 21.749853319266723,
            "lng": 39.15203211293597
          },
          "cartesians": {
            "x": 133.2330529848678,
            "y": 92.6113206510885
          }
        }
      },
      {
        "id": 132505,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز ادب بوك للنشر والتوزيع _I23",
        "created_at": "2022-12-05T21:18:20.629+01:00",
        "updated_at": "2022-12-06T18:26:59.658+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 159.3376394151008,
          "y": 114.44414722598941,
          "lat": 21.749655888707235,
          "lng": 39.15177998068465,
          "radius": 5,
          "georeferences": {
            "lat": 21.749655888707235,
            "lng": 39.15177998068465
          },
          "cartesians": {
            "x": 159.3376394151008,
            "y": 114.44414722598941
          }
        }
      },
      {
        "id": 132435,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جامعة الملك سعود _K12",
        "created_at": "2022-12-05T21:18:20.391+01:00",
        "updated_at": "2022-12-06T18:26:08.930+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 117.43431166792507,
          "y": 107.8003212767234,
          "lat": 21.74971629735264,
          "lng": 39.15218499971193,
          "radius": 5,
          "georeferences": {
            "lat": 21.74971629735264,
            "lng": 39.15218499971193
          },
          "cartesians": {
            "x": 117.43431166792507,
            "y": 107.8003212767234
          }
        }
      },
      {
        "id": 132590,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المركز الوطني للوثاىق والمحفوظات _Z13",
        "created_at": "2022-12-05T21:18:20.988+01:00",
        "updated_at": "2022-12-06T15:15:46.087+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 252.46503203097836,
          "y": 176.34271517711977,
          "lat": 21.74909596484244,
          "lng": 39.150880341956736,
          "radius": 5,
          "georeferences": {
            "lat": 21.74909596484244,
            "lng": 39.150880341956736
          },
          "cartesians": {
            "x": 252.46503203097836,
            "y": 176.34271517711977
          }
        }
      },
      {
        "id": 132560,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة الفكر الجامعي التجارية _X11",
        "created_at": "2022-12-05T21:18:20.890+01:00",
        "updated_at": "2022-12-06T15:24:22.726+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 244.79645007599848,
          "y": 205.41863968086383,
          "lat": 21.748833448080475,
          "lng": 39.15095477733524,
          "radius": 5,
          "georeferences": {
            "lat": 21.748833448080475,
            "lng": 39.15095477733524
          },
          "cartesians": {
            "x": 244.79645007599848,
            "y": 205.41863968086383
          }
        }
      },
      {
        "id": 132561,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز الملك عبدالعزيز للحوار الوطني _W22",
        "created_at": "2022-12-05T21:18:20.894+01:00",
        "updated_at": "2022-12-06T15:25:45.988+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 211.85325473494458,
          "y": 209.55666239684274,
          "lat": 21.748796396452505,
          "lng": 39.1512732882273,
          "radius": 5,
          "georeferences": {
            "lat": 21.748796396452505,
            "lng": 39.1512732882273
          },
          "cartesians": {
            "x": 211.85325473494458,
            "y": 209.55666239684274
          }
        }
      },
      {
        "id": 132486,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار التنوع الثقافي _W7",
        "created_at": "2022-12-05T21:18:20.564+01:00",
        "updated_at": "2022-12-06T15:25:53.046+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 219.1378531875142,
          "y": 210.92821706872957,
          "lat": 21.748783938921797,
          "lng": 39.151202880903725,
          "radius": 5,
          "georeferences": {
            "lat": 21.748783938921797,
            "lng": 39.151202880903725
          },
          "cartesians": {
            "x": 219.1378531875142,
            "y": 210.92821706872957
          }
        }
      },
      {
        "id": 132574,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة مكتبة المتنبي المحدودة _S15",
        "created_at": "2022-12-05T21:18:20.937+01:00",
        "updated_at": "2022-12-05T21:18:20.937+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 169.70500168699567,
          "y": 232.49741481174675,
          "lat": 21.748589622506227,
          "lng": 39.151680981124436,
          "radius": 5,
          "georeferences": {
            "lat": 21.748589622506227,
            "lng": 39.151680981124436
          },
          "cartesians": {
            "x": 169.70500168699567,
            "y": 232.49741481174675
          }
        }
      },
      {
        "id": 132490,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز طروس للنشر والتوزيع _U16",
        "created_at": "2022-12-05T21:18:20.577+01:00",
        "updated_at": "2022-12-06T15:28:20.991+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 196.70360738676783,
          "y": 247.84729059624337,
          "lat": 21.748450732252476,
          "lng": 39.15142013998869,
          "radius": 5,
          "georeferences": {
            "lat": 21.748450732252476,
            "lng": 39.15142013998869
          },
          "cartesians": {
            "x": 196.70360738676783,
            "y": 247.84729059624337
          }
        }
      },
      {
        "id": 132364,
        "project_id": 12170,
        "building_id": 12170,
        "name": "رصانة المعهد الدولي للدراسات الإيرانية _W1",
        "created_at": "2022-12-05T21:18:20.030+01:00",
        "updated_at": "2022-12-06T15:25:56.519+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Politics ",
        "category_id": 2220,
        "position": {
          "floor_id": 37595,
          "x": 207.8181251142897,
          "y": 198.25101195962802,
          "lat": 21.748898539489414,
          "lng": 39.1513121792736,
          "radius": 5,
          "georeferences": {
            "lat": 21.748898539489414,
            "lng": 39.1513121792736
          },
          "cartesians": {
            "x": 207.8181251142897,
            "y": 198.25101195962802
          }
        }
      },
      {
        "id": 132423,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منتدى المعارف _T17",
        "created_at": "2022-12-05T21:18:20.352+01:00",
        "updated_at": "2022-12-06T15:30:19.911+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Sociology",
        "category_id": 2227,
        "position": {
          "floor_id": 37595,
          "x": 176.9821852510866,
          "y": 226.97254463478225,
          "lat": 21.748639448208497,
          "lng": 39.151610573945895,
          "radius": 5,
          "georeferences": {
            "lat": 21.748639448208497,
            "lng": 39.151610573945895
          },
          "cartesians": {
            "x": 176.9821852510866,
            "y": 226.97254463478225
          }
        }
      },
      {
        "id": 132538,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار النهار للنشر والتوزيع _T14",
        "created_at": "2022-12-05T21:18:20.741+01:00",
        "updated_at": "2022-12-06T15:30:15.815+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Sociology",
        "category_id": 2227,
        "position": {
          "floor_id": 37595,
          "x": 178.4498264690136,
          "y": 237.24647546233933,
          "lat": 21.74854664778597,
          "lng": 39.15159649258617,
          "radius": 5,
          "georeferences": {
            "lat": 21.74854664778597,
            "lng": 39.15159649258617
          },
          "cartesians": {
            "x": 178.4498264690136,
            "y": 237.24647546233933
          }
        }
      },
      {
        "id": 132549,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سوتيميديا للنشر والتوزيع تونس _S12",
        "created_at": "2022-12-05T21:18:20.841+01:00",
        "updated_at": "2022-12-05T21:18:20.841+01:00",
        "info": "<p> تونس - Tunisia </p>",
        "info_unsafe": "<p> تونس - Tunisia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Sociology",
        "category_id": 2227,
        "position": {
          "floor_id": 37595,
          "x": 168.60727292992544,
          "y": 243.73959065428744,
          "lat": 21.74848810254791,
          "lng": 39.15169170970945,
          "radius": 5,
          "georeferences": {
            "lat": 21.74848810254791,
            "lng": 39.15169170970945
          },
          "cartesians": {
            "x": 168.60727292992544,
            "y": 243.73959065428744
          }
        }
      },
      {
        "id": 132609,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المركز الأكاديمي للأبحاث _S17",
        "created_at": "2022-12-05T21:18:21.048+01:00",
        "updated_at": "2022-12-05T21:18:21.048+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Sociology",
        "category_id": 2227,
        "position": {
          "floor_id": 37595,
          "x": 170.6007969187783,
          "y": 226.9794017494822,
          "lat": 21.748639448225372,
          "lng": 39.15167226406303,
          "radius": 5,
          "georeferences": {
            "lat": 21.748639448225372,
            "lng": 39.15167226406303
          },
          "cartesians": {
            "x": 170.6007969187783,
            "y": 226.9794017494822
          }
        }
      },
      {
        "id": 132589,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة الملك فهد الوطنية _Z14",
        "created_at": "2022-12-05T21:18:20.985+01:00",
        "updated_at": "2022-12-06T15:15:44.449+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Sociology",
        "category_id": 2227,
        "position": {
          "floor_id": 37595,
          "x": 247.67820459610874,
          "y": 175.6580949486947,
          "lat": 21.74910219426796,
          "lng": 39.15092661001464,
          "radius": 5,
          "georeferences": {
            "lat": 21.74910219426796,
            "lng": 39.15092661001464
          },
          "cartesians": {
            "x": 247.67820459610874,
            "y": 175.6580949486947
          }
        }
      },
      {
        "id": 132360,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة روائع الفاروق _P17",
        "created_at": "2022-12-05T21:18:20.016+01:00",
        "updated_at": "2022-12-06T15:35:40.483+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 122.83982257404892,
          "y": 222.82447836081855,
          "lat": 21.74867743589387,
          "lng": 39.15213393503944,
          "radius": 5,
          "georeferences": {
            "lat": 21.74867743589387,
            "lng": 39.15213393503944
          },
          "cartesians": {
            "x": 122.83982257404892,
            "y": 222.82447836081855
          }
        }
      },
      {
        "id": 132389,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة مهارات الأطفال للتجارة _P3",
        "created_at": "2022-12-05T21:18:20.122+01:00",
        "updated_at": "2022-12-06T15:40:48.270+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 138.04074009309554,
          "y": 200.32593659041905,
          "lat": 21.7488804777182,
          "lng": 39.151986751716265,
          "radius": 5,
          "georeferences": {
            "lat": 21.7488804777182,
            "lng": 39.151986751716265
          },
          "cartesians": {
            "x": 138.04074009309554,
            "y": 200.32593659041905
          }
        }
      },
      {
        "id": 132393,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار موهبتي للطبع والنشر والتوزيع _P24",
        "created_at": "2022-12-05T21:18:20.135+01:00",
        "updated_at": "2022-12-06T15:34:45.351+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 138.87846915402386,
          "y": 205.29037016175923,
          "lat": 21.748835634672137,
          "lng": 39.151978704700376,
          "radius": 5,
          "georeferences": {
            "lat": 21.748835634672137,
            "lng": 39.151978704700376
          },
          "cartesians": {
            "x": 138.87846915402386,
            "y": 205.29037016175923
          }
        }
      },
      {
        "id": 132419,
        "project_id": 12170,
        "building_id": 12170,
        "name": "زهور للنشر والتوزيع _P19",
        "created_at": "2022-12-05T21:18:20.339+01:00",
        "updated_at": "2022-12-06T15:35:37.914+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 127.13518717852537,
          "y": 218.06133038804506,
          "lat": 21.748720411256826,
          "lng": 39.15209236157629,
          "radius": 5,
          "georeferences": {
            "lat": 21.748720411256826,
            "lng": 39.15209236157629
          },
          "cartesians": {
            "x": 127.13518717852537,
            "y": 218.06133038804506
          }
        }
      },
      {
        "id": 132638,
        "project_id": 12170,
        "building_id": 12170,
        "name": "أحب أن أتعلم للوسائل والمستلزمات التعليمية _P13",
        "created_at": "2022-12-05T21:18:21.141+01:00",
        "updated_at": "2022-12-06T15:40:55.324+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 117.11804631590363,
          "y": 223.45141862660165,
          "lat": 21.7486718293976,
          "lng": 39.15218925499781,
          "radius": 5,
          "georeferences": {
            "lat": 21.7486718293976,
            "lng": 39.15218925499781
          },
          "cartesians": {
            "x": 117.11804631590363,
            "y": 223.45141862660165
          }
        }
      },
      {
        "id": 132427,
        "project_id": 12170,
        "building_id": 12170,
        "name": "واحة البرامج _P22",
        "created_at": "2022-12-05T21:18:20.365+01:00",
        "updated_at": "2022-12-06T15:35:32.556+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 134.79083588181877,
          "y": 209.74292771851674,
          "lat": 21.74879546230443,
          "lng": 39.1520182667676,
          "radius": 5,
          "georeferences": {
            "lat": 21.74879546230443,
            "lng": 39.1520182667676
          },
          "cartesians": {
            "x": 134.79083588181877,
            "y": 209.74292771851674
          }
        }
      },
      {
        "id": 132433,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار أملاك التراث _M31",
        "created_at": "2022-12-05T21:18:20.385+01:00",
        "updated_at": "2022-12-06T16:00:42.336+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 114.64059103790635,
          "y": 154.24980116763908,
          "lat": 21.74929682879032,
          "lng": 39.1522124879682,
          "radius": 5,
          "georeferences": {
            "lat": 21.74929682879032,
            "lng": 39.1522124879682
          },
          "cartesians": {
            "x": 114.64059103790635,
            "y": 154.24980116763908
          }
        }
      },
      {
        "id": 132513,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سهل للنشر وتكنولوجيا المعلومات _P16",
        "created_at": "2022-12-05T21:18:20.655+01:00",
        "updated_at": "2022-12-06T15:35:41.923+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 120.93461234344275,
          "y": 224.9299327680493,
          "lat": 21.748658439556507,
          "lng": 39.152152374886285,
          "radius": 5,
          "georeferences": {
            "lat": 21.748658439556507,
            "lng": 39.152152374886285
          },
          "cartesians": {
            "x": 120.93461234344275,
            "y": 224.9299327680493
          }
        }
      },
      {
        "id": 132497,
        "project_id": 12170,
        "building_id": 12170,
        "name": "محيط القمة - قسم الأطفال _Q4",
        "created_at": "2022-12-05T21:18:20.601+01:00",
        "updated_at": "2022-12-05T21:18:20.601+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 142.9098664072604,
          "y": 213.00983972887832,
          "lat": 21.748765879276032,
          "lng": 39.15193981247692,
          "radius": 5,
          "georeferences": {
            "lat": 21.748765879276032,
            "lng": 39.15193981247692
          },
          "cartesians": {
            "x": 142.9098664072604,
            "y": 213.00983972887832
          }
        }
      },
      {
        "id": 132498,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Harper`s Books and Creativity Products - United Kingdom _Q5",
        "created_at": "2022-12-05T21:18:20.605+01:00",
        "updated_at": "2022-12-05T21:18:20.605+01:00",
        "info": "<p> المملكة المتحدة - United Kingdom </p>",
        "info_unsafe": "<p> المملكة المتحدة - United Kingdom </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 140.97111872974395,
          "y": 216.18424564347563,
          "lat": 21.74873722932326,
          "lng": 39.15195858762328,
          "radius": 5,
          "georeferences": {
            "lat": 21.74873722932326,
            "lng": 39.15195858762328
          },
          "cartesians": {
            "x": 140.97111872974395,
            "y": 216.18424564347563
          }
        }
      },
      {
        "id": 132605,
        "project_id": 12170,
        "building_id": 12170,
        "name": "فرست للنشر والتوزيع _Q7",
        "created_at": "2022-12-05T21:18:21.036+01:00",
        "updated_at": "2022-12-05T21:18:21.036+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 138.202696541742,
          "y": 221.84222776025183,
          "lat": 21.74868615768995,
          "lng": 39.15198540913972,
          "radius": 5,
          "georeferences": {
            "lat": 21.74868615768995,
            "lng": 39.15198540913972
          },
          "cartesians": {
            "x": 138.202696541742,
            "y": 221.84222776025183
          }
        }
      },
      {
        "id": 132604,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة حمدان الشمراني _P18",
        "created_at": "2022-12-05T21:18:21.033+01:00",
        "updated_at": "2022-12-06T15:35:39.161+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 124.98739176572442,
          "y": 220.33945837981162,
          "lat": 21.74869985782045,
          "lng": 39.152113148328766,
          "radius": 5,
          "georeferences": {
            "lat": 21.74869985782045,
            "lng": 39.152113148328766
          },
          "cartesians": {
            "x": 124.98739176572442,
            "y": 220.33945837981162
          }
        }
      },
      {
        "id": 132583,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المستقبل للنشر و الوسائل التعليمية _P15",
        "created_at": "2022-12-05T21:18:20.967+01:00",
        "updated_at": "2022-12-06T15:35:43.268+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 118.47532586729068,
          "y": 227.7945924513285,
          "lat": 21.74863259204446,
          "lng": 39.1521761789614,
          "radius": 5,
          "georeferences": {
            "lat": 21.74863259204446,
            "lng": 39.1521761789614
          },
          "cartesians": {
            "x": 118.47532586729068,
            "y": 227.7945924513285
          }
        }
      },
      {
        "id": 132532,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المستقبل للإنتاج التعليمي _P20",
        "created_at": "2022-12-05T21:18:20.717+01:00",
        "updated_at": "2022-12-06T15:35:36.461+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 129.35212351425466,
          "y": 215.5762405763398,
          "lat": 21.748742833140774,
          "lng": 39.15207090427987,
          "radius": 5,
          "georeferences": {
            "lat": 21.748742833140774,
            "lng": 39.15207090427987
          },
          "cartesians": {
            "x": 129.35212351425466,
            "y": 215.5762405763398
          }
        }
      },
      {
        "id": 132459,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سندس للانتاج والتوزيع _P1",
        "created_at": "2022-12-05T21:18:20.470+01:00",
        "updated_at": "2022-12-06T15:40:50.564+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 142.26670856178328,
          "y": 195.49394504486006,
          "lat": 21.748924075496625,
          "lng": 39.15194584840441,
          "radius": 5,
          "georeferences": {
            "lat": 21.748924075496625,
            "lng": 39.15194584840441
          },
          "cartesians": {
            "x": 142.26670856178328,
            "y": 195.49394504486006
          }
        }
      },
      {
        "id": 132566,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نسما للأطفال _P5",
        "created_at": "2022-12-05T21:18:20.910+01:00",
        "updated_at": "2022-12-06T15:40:46.304+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 133.67592182659016,
          "y": 205.0201828873687,
          "lat": 21.74883812529591,
          "lng": 39.15202899588599,
          "radius": 5,
          "georeferences": {
            "lat": 21.74883812529591,
            "lng": 39.15202899588599
          },
          "cartesians": {
            "x": 133.67592182659016,
            "y": 205.0201828873687
          }
        }
      },
      {
        "id": 132568,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ابتكار و ابداع للنشر و التوزيع _Q6",
        "created_at": "2022-12-05T21:18:20.917+01:00",
        "updated_at": "2022-12-05T21:18:20.917+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Teaching Aids",
        "category_id": 2228,
        "position": {
          "floor_id": 37595,
          "x": 139.7254851340294,
          "y": 218.87516075876883,
          "lat": 21.74871293915626,
          "lng": 39.151970657295244,
          "radius": 5,
          "georeferences": {
            "lat": 21.74871293915626,
            "lng": 39.151970657295244
          },
          "cartesians": {
            "x": 139.7254851340294,
            "y": 218.87516075876883
          }
        }
      },
      {
        "id": 132418,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الغوثاني _R19",
        "created_at": "2022-12-05T21:18:20.335+01:00",
        "updated_at": "2022-12-05T21:18:20.335+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 152.99469870072357,
          "y": 238.23939095558785,
          "lat": 21.748537927639894,
          "lng": 39.15184258223036,
          "radius": 5,
          "georeferences": {
            "lat": 21.748537927639894,
            "lng": 39.15184258223036
          },
          "cartesians": {
            "x": 152.99469870072357,
            "y": 238.23939095558785
          }
        }
      },
      {
        "id": 132417,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار آرت بوك للنشر والتوزيع _R9",
        "created_at": "2022-12-05T21:18:20.330+01:00",
        "updated_at": "2022-12-05T21:18:20.330+01:00",
        "info": "<p> البحرين - Bahrain </p>",
        "info_unsafe": "<p> البحرين - Bahrain </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 152.29097706462187,
          "y": 228.86117245537997,
          "lat": 21.748622631267693,
          "lng": 39.15184928795293,
          "radius": 5,
          "georeferences": {
            "lat": 21.748622631267693,
            "lng": 39.15184928795293
          },
          "cartesians": {
            "x": 152.29097706462187,
            "y": 228.86117245537997
          }
        }
      },
      {
        "id": 132531,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار وقف أضواء الشاطبية للنشر _R14",
        "created_at": "2022-12-05T21:18:20.713+01:00",
        "updated_at": "2022-12-05T21:18:20.713+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 148.28308965851855,
          "y": 242.93399734183447,
          "lat": 21.74849557533184,
          "lng": 39.15188817891205,
          "radius": 5,
          "georeferences": {
            "lat": 21.74849557533184,
            "lng": 39.15188817891205
          },
          "cartesians": {
            "x": 148.28308965851855,
            "y": 242.93399734183447
          }
        }
      },
      {
        "id": 132602,
        "project_id": 12170,
        "building_id": 12170,
        "name": "لوامع الانوار للنشر و التوزيع _R15",
        "created_at": "2022-12-05T21:18:21.027+01:00",
        "updated_at": "2022-12-05T21:18:21.027+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 147.38494147175174,
          "y": 246.24520399167443,
          "lat": 21.748465679799583,
          "lng": 39.15189689582546,
          "radius": 5,
          "georeferences": {
            "lat": 21.748465679799583,
            "lng": 39.15189689582546
          },
          "cartesians": {
            "x": 147.38494147175174,
            "y": 246.24520399167443
          }
        }
      },
      {
        "id": 132533,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار التقوى _R16",
        "created_at": "2022-12-05T21:18:20.721+01:00",
        "updated_at": "2022-12-05T21:18:20.721+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 150.6461704408104,
          "y": 247.34507271679308,
          "lat": 21.748455714980924,
          "lng": 39.15186538034638,
          "radius": 5,
          "georeferences": {
            "lat": 21.748455714980924,
            "lng": 39.15186538034638
          },
          "cartesians": {
            "x": 150.6461704408104,
            "y": 247.34507271679308
          }
        }
      },
      {
        "id": 132603,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الإمام مسلم لنشر والتوزيع _R17",
        "created_at": "2022-12-05T21:18:21.030+01:00",
        "updated_at": "2022-12-05T21:18:21.030+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 151.4755524768142,
          "y": 244.5856477237555,
          "lat": 21.748480627916795,
          "lng": 39.15185733393111,
          "radius": 5,
          "georeferences": {
            "lat": 21.748480627916795,
            "lng": 39.15185733393111
          },
          "cartesians": {
            "x": 151.4755524768142,
            "y": 244.5856477237555
          }
        }
      },
      {
        "id": 132457,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار سلف للنشر والتوزيع _R12",
        "created_at": "2022-12-05T21:18:20.464+01:00",
        "updated_at": "2022-12-05T21:18:20.464+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 150.1500866536617,
          "y": 237.55284593164504,
          "lat": 21.748544155595088,
          "lng": 39.15187007448943,
          "radius": 5,
          "georeferences": {
            "lat": 21.748544155595088,
            "lng": 39.15187007448943
          },
          "cartesians": {
            "x": 150.1500866536617,
            "y": 237.55284593164504
          }
        }
      },
      {
        "id": 132494,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة بيت السنة للنشر والتوزيع _R18",
        "created_at": "2022-12-05T21:18:20.591+01:00",
        "updated_at": "2022-12-05T21:18:20.591+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 152.16561311808215,
          "y": 241.27466721549123,
          "lat": 21.748510523426425,
          "lng": 39.151850628639714,
          "radius": 5,
          "georeferences": {
            "lat": 21.748510523426425,
            "lng": 39.151850628639714
          },
          "cartesians": {
            "x": 152.16561311808215,
            "y": 241.27466721549123
          }
        }
      },
      {
        "id": 132430,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ممدوح عدوان للنشر والتوزيع _E9",
        "created_at": "2022-12-05T21:18:20.376+01:00",
        "updated_at": "2022-12-06T16:43:56.369+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 212.5932725344572,
          "y": 99.14645389046973,
          "lat": 21.74979352865029,
          "lng": 39.151264989357905,
          "radius": 5,
          "georeferences": {
            "lat": 21.74979352865029,
            "lng": 39.151264989357905
          },
          "cartesians": {
            "x": 212.5932725344572,
            "y": 99.14645389046973
          }
        }
      },
      {
        "id": 132641,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة مكتبة المكتبة _T15",
        "created_at": "2022-12-05T21:18:21.156+01:00",
        "updated_at": "2022-12-06T15:30:18.062+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 178.02950912972136,
          "y": 233.3849904019935,
          "lat": 21.748581525804525,
          "lng": 39.15160051580441,
          "radius": 5,
          "georeferences": {
            "lat": 21.748581525804525,
            "lng": 39.15160051580441
          },
          "cartesians": {
            "x": 178.02950912972136,
            "y": 233.3849904019935
          }
        }
      },
      {
        "id": 132639,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات مسكيلياني _Q17",
        "created_at": "2022-12-05T21:18:21.144+01:00",
        "updated_at": "2022-12-06T15:35:03.175+01:00",
        "info": "<p> تونس - Tunisia </p>",
        "info_unsafe": "<p> تونس - Tunisia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 138.35084549552747,
          "y": 230.5659053871908,
          "lat": 21.748607370760652,
          "lng": 39.1519840674278,
          "radius": 5,
          "georeferences": {
            "lat": 21.748607370760652,
            "lng": 39.1519840674278
          },
          "cartesians": {
            "x": 138.35084549552747,
            "y": 230.5659053871908
          }
        }
      },
      {
        "id": 132628,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نوفا بلس  للنشر والتوزيع _D25",
        "created_at": "2022-12-05T21:18:21.109+01:00",
        "updated_at": "2022-12-06T16:38:46.872+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 204.50745643741385,
          "y": 127.15385318604888,
          "lat": 21.749540666029457,
          "lng": 39.15134344684817,
          "radius": 5,
          "georeferences": {
            "lat": 21.749540666029457,
            "lng": 39.15134344684817
          },
          "cartesians": {
            "x": 204.50745643741385,
            "y": 127.15385318604888
          }
        }
      },
      {
        "id": 132666,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز المحروسة  للنشر والخدمات الصحفية والمعلومات _L16",
        "created_at": "2022-12-05T21:18:21.236+01:00",
        "updated_at": "2022-12-06T15:56:30.630+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 110.89756568012763,
          "y": 124.63438522473325,
          "lat": 21.749564328594786,
          "lng": 39.152248365932955,
          "radius": 5,
          "georeferences": {
            "lat": 21.749564328594786,
            "lng": 39.152248365932955
          },
          "cartesians": {
            "x": 110.89756568012763,
            "y": 124.63438522473325
          }
        }
      },
      {
        "id": 132667,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ريادة للنشر  والتوزيع _L19",
        "created_at": "2022-12-05T21:18:21.239+01:00",
        "updated_at": "2022-12-06T15:56:28.183+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 117.83874084826644,
          "y": 129.00588696140366,
          "lat": 21.74952478120216,
          "lng": 39.15218130947789,
          "radius": 5,
          "georeferences": {
            "lat": 21.74952478120216,
            "lng": 39.15218130947789
          },
          "cartesians": {
            "x": 117.83874084826644,
            "y": 129.00588696140366
          }
        }
      },
      {
        "id": 132562,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المدى _V20",
        "created_at": "2022-12-05T21:18:20.897+01:00",
        "updated_at": "2022-12-06T15:27:53.966+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 206.11235769617844,
          "y": 224.8035917980707,
          "lat": 21.748658753737768,
          "lng": 39.15132894474309,
          "radius": 5,
          "georeferences": {
            "lat": 21.748658753737768,
            "lng": 39.15132894474309
          },
          "cartesians": {
            "x": 206.11235769617844,
            "y": 224.8035917980707
          }
        }
      },
      {
        "id": 132616,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة وراقو للنشر والتوزيع _F17",
        "created_at": "2022-12-05T21:18:21.069+01:00",
        "updated_at": "2022-12-06T16:46:28.610+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 190.87810848417254,
          "y": 95.37661928116012,
          "lat": 21.749827785665182,
          "lng": 39.15147487516136,
          "radius": 5,
          "georeferences": {
            "lat": 21.749827785665182,
            "lng": 39.15147487516136
          },
          "cartesians": {
            "x": 190.87810848417254,
            "y": 95.37661928116012
          }
        }
      },
      {
        "id": 132539,
        "project_id": 12170,
        "building_id": 12170,
        "name": "العصماء _T10",
        "created_at": "2022-12-05T21:18:20.801+01:00",
        "updated_at": "2022-12-06T15:30:09.196+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 179.98830050395333,
          "y": 248.8996024556808,
          "lat": 21.74844139084566,
          "lng": 39.1515817408023,
          "radius": 5,
          "georeferences": {
            "lat": 21.74844139084566,
            "lng": 39.1515817408023
          },
          "cartesians": {
            "x": 179.98830050395333,
            "y": 248.8996024556808
          }
        }
      },
      {
        "id": 132591,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار كلمات _Y25",
        "created_at": "2022-12-05T21:18:20.992+01:00",
        "updated_at": "2022-12-06T15:14:49.349+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 221.15459137819303,
          "y": 183.20295186228054,
          "lat": 21.74903431243961,
          "lng": 39.15118309705305,
          "radius": 5,
          "georeferences": {
            "lat": 21.74903431243961,
            "lng": 39.15118309705305
          },
          "cartesians": {
            "x": 221.15459137819303,
            "y": 183.20295186228054
          }
        }
      },
      {
        "id": 132473,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الساقي _M27",
        "created_at": "2022-12-05T21:18:20.518+01:00",
        "updated_at": "2022-12-06T15:56:36.548+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 125.41840930874739,
          "y": 146.5831280338638,
          "lat": 21.74936596361488,
          "lng": 39.15210821733591,
          "radius": 5,
          "georeferences": {
            "lat": 21.74936596361488,
            "lng": 39.15210821733591
          },
          "cartesians": {
            "x": 125.41840930874739,
            "y": 146.5831280338638
          }
        }
      },
      {
        "id": 132479,
        "project_id": 12170,
        "building_id": 12170,
        "name": "عصير الكتب للترجمة والنشر والتوزيع _L22",
        "created_at": "2022-12-05T21:18:20.541+01:00",
        "updated_at": "2022-12-06T15:56:26.223+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 126.34156927784238,
          "y": 134.30674780118662,
          "lat": 21.749476825399697,
          "lng": 39.152099165852384,
          "radius": 5,
          "georeferences": {
            "lat": 21.749476825399697,
            "lng": 39.152099165852384
          },
          "cartesians": {
            "x": 126.34156927784238,
            "y": 134.30674780118662
          }
        }
      },
      {
        "id": 132540,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ياسمينا _I13",
        "created_at": "2022-12-05T21:18:20.806+01:00",
        "updated_at": "2022-12-06T18:14:56.572+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 146.7513811533738,
          "y": 85.35556647714361,
          "lat": 21.749918716387302,
          "lng": 39.151901353424066,
          "radius": 5,
          "georeferences": {
            "lat": 21.749918716387302,
            "lng": 39.151901353424066
          },
          "cartesians": {
            "x": 146.7513811533738,
            "y": 85.35556647714361
          }
        }
      },
      {
        "id": 132553,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة إبداع للترجمة والنشر والتوزيع _I7",
        "created_at": "2022-12-05T21:18:20.867+01:00",
        "updated_at": "2022-12-06T18:14:54.850+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 150.72179747720767,
          "y": 100.79886010349594,
          "lat": 21.749779205993903,
          "lng": 39.15186313045891,
          "radius": 5,
          "georeferences": {
            "lat": 21.749779205993903,
            "lng": 39.15186313045891
          },
          "cartesians": {
            "x": 150.72179747720767,
            "y": 100.79886010349594
          }
        }
      },
      {
        "id": 132668,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة الملك عبدالعزيز العامة _I9&11",
        "created_at": "2022-12-05T21:18:21.242+01:00",
        "updated_at": "2022-12-06T18:14:52.055+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 148.42363081395104,
          "y": 92.31896205758508,
          "lat": 21.749855812165766,
          "lng": 39.1518852595183,
          "radius": 5,
          "georeferences": {
            "lat": 21.749855812165766,
            "lng": 39.1518852595183
          },
          "cartesians": {
            "x": 148.42363081395104,
            "y": 92.31896205758508
          }
        }
      },
      {
        "id": 132387,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار سما للنشر و التوزيع _K25",
        "created_at": "2022-12-05T21:18:20.115+01:00",
        "updated_at": "2022-12-06T18:26:15.468+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 143.33071977118396,
          "y": 129.63331251551537,
          "lat": 21.749518867326827,
          "lng": 39.15193488002692,
          "radius": 5,
          "georeferences": {
            "lat": 21.749518867326827,
            "lng": 39.15193488002692
          },
          "cartesians": {
            "x": 143.33071977118396,
            "y": 129.63331251551537
          }
        }
      },
      {
        "id": 132525,
        "project_id": 12170,
        "building_id": 12170,
        "name": "ذات السلاسل _U1",
        "created_at": "2022-12-05T21:18:20.692+01:00",
        "updated_at": "2022-12-08T03:18:25.840+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 186.393672467646,
          "y": 206.54941335850566,
          "lat": 21.748823802733515,
          "lng": 39.15151937950793,
          "radius": 5,
          "georeferences": {
            "lat": 21.748823802733515,
            "lng": 39.15151937950793
          },
          "cartesians": {
            "x": 186.393672467646,
            "y": 206.54941335850566
          }
        }
      },
      {
        "id": 132396,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منشورات الجمل _I14",
        "created_at": "2022-12-05T21:18:20.146+01:00",
        "updated_at": "2022-12-06T18:27:07.414+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 151.1926386738164,
          "y": 87.14378437228225,
          "lat": 21.749902523478056,
          "lng": 39.151858437390665,
          "radius": 5,
          "georeferences": {
            "lat": 21.749902523478056,
            "lng": 39.151858437390665
          },
          "cartesians": {
            "x": 151.1926386738164,
            "y": 87.14378437228225
          }
        }
      },
      {
        "id": 132466,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الخان _I17",
        "created_at": "2022-12-05T21:18:20.493+01:00",
        "updated_at": "2022-12-06T18:27:05.342+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 153.14206012623586,
          "y": 93.83103153638078,
          "lat": 21.74984211052436,
          "lng": 39.151839661159414,
          "radius": 5,
          "georeferences": {
            "lat": 21.74984211052436,
            "lng": 39.151839661159414
          },
          "cartesians": {
            "x": 153.14206012623586,
            "y": 93.83103153638078
          }
        }
      },
      {
        "id": 132400,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الرائدية للنشر والتوزيع _I19",
        "created_at": "2022-12-05T21:18:20.163+01:00",
        "updated_at": "2022-12-06T18:27:03.407+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 155.16007645869067,
          "y": 99.82859183091813,
          "lat": 21.749787925621565,
          "lng": 39.151820214679184,
          "radius": 5,
          "georeferences": {
            "lat": 21.749787925621565,
            "lng": 39.151820214679184
          },
          "cartesians": {
            "x": 155.16007645869067,
            "y": 99.82859183091813
          }
        }
      },
      {
        "id": 132409,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار إرفاء للنشر والتوزيع _I1",
        "created_at": "2022-12-05T21:18:20.192+01:00",
        "updated_at": "2022-12-06T18:14:40.790+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 155.38520966024242,
          "y": 115.68973998972439,
          "lat": 21.74964467784616,
          "lng": 39.15181820250552,
          "radius": 5,
          "georeferences": {
            "lat": 21.74964467784616,
            "lng": 39.15181820250552
          },
          "cartesians": {
            "x": 155.38520966024242,
            "y": 115.68973998972439
          }
        }
      },
      {
        "id": 132408,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار دون للنشر والتوزيع _K1",
        "created_at": "2022-12-05T21:18:20.189+01:00",
        "updated_at": "2022-12-06T18:24:35.265+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 140.2812299393366,
          "y": 131.9813456652209,
          "lat": 21.749497691314186,
          "lng": 39.15196438435638,
          "radius": 5,
          "georeferences": {
            "lat": 21.749497691314186,
            "lng": 39.15196438435638
          },
          "cartesians": {
            "x": 140.2812299393366,
            "y": 131.9813456652209
          }
        }
      },
      {
        "id": 132469,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الدار المصريه للعلوم _K17",
        "created_at": "2022-12-05T21:18:20.503+01:00",
        "updated_at": "2022-12-06T18:26:24.364+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 121.38538785019907,
          "y": 105.31337688354651,
          "lat": 21.749738719152194,
          "lng": 39.152146778135545,
          "radius": 5,
          "georeferences": {
            "lat": 21.749738719152194,
            "lng": 39.152146778135545
          },
          "cartesians": {
            "x": 121.38538785019907,
            "y": 105.31337688354651
          }
        }
      },
      {
        "id": 132617,
        "project_id": 12170,
        "building_id": 12170,
        "name": "وكالة كلمات _K14",
        "created_at": "2022-12-05T21:18:21.072+01:00",
        "updated_at": "2022-12-06T18:26:06.216+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 112.85112465552848,
          "y": 103.04691459215621,
          "lat": 21.74975927092519,
          "lng": 39.15222925711627,
          "radius": 5,
          "georeferences": {
            "lat": 21.74975927092519,
            "lng": 39.15222925711627
          },
          "cartesians": {
            "x": 112.85112465552848,
            "y": 103.04691459215621
          }
        }
      },
      {
        "id": 132516,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الادب للنشر والتوزيع _K16",
        "created_at": "2022-12-05T21:18:20.664+01:00",
        "updated_at": "2022-12-06T18:26:25.973+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 119.23248035998273,
          "y": 102.90204108975931,
          "lat": 21.749760517365516,
          "lng": 39.15216756577276,
          "radius": 5,
          "georeferences": {
            "lat": 21.749760517365516,
            "lng": 39.15216756577276
          },
          "cartesians": {
            "x": 119.23248035998273,
            "y": 102.90204108975931
          }
        }
      },
      {
        "id": 132378,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة الانتشار العربي _K8",
        "created_at": "2022-12-05T21:18:20.087+01:00",
        "updated_at": "2022-12-06T18:26:11.907+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 126.18451821107172,
          "y": 117.37661088312302,
          "lat": 21.74962972679162,
          "lng": 39.152100508832554,
          "radius": 5,
          "georeferences": {
            "lat": 21.74962972679162,
            "lng": 39.152100508832554
          },
          "cartesians": {
            "x": 126.18451821107172,
            "y": 117.37661088312302
          }
        }
      },
      {
        "id": 132379,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة طبق الأصل للترجمة _K15",
        "created_at": "2022-12-05T21:18:20.090+01:00",
        "updated_at": "2022-12-06T18:26:27.058+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 117.28819254581188,
          "y": 100.97321609997499,
          "lat": 21.749777955890547,
          "lng": 39.1521863416365,
          "radius": 5,
          "georeferences": {
            "lat": 21.749777955890547,
            "lng": 39.1521863416365
          },
          "cartesians": {
            "x": 117.28819254581188,
            "y": 100.97321609997499
          }
        }
      },
      {
        "id": 132377,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المؤسسة العربية للدراسات والنشر _K5",
        "created_at": "2022-12-05T21:18:20.077+01:00",
        "updated_at": "2022-12-06T18:26:13.251+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 131.94868589939264,
          "y": 123.78384446982213,
          "lat": 21.74957180567305,
          "lng": 39.15204485181296,
          "radius": 5,
          "georeferences": {
            "lat": 21.74957180567305,
            "lng": 39.15204485181296
          },
          "cartesians": {
            "x": 131.94868589939264,
            "y": 123.78384446982213
          }
        }
      },
      {
        "id": 132352,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار بصمة للنشر والتوزيع _J5",
        "created_at": "2022-12-05T21:18:19.977+01:00",
        "updated_at": "2022-12-06T18:23:26.761+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 140.3295853719574,
          "y": 112.46484446338079,
          "lat": 21.74967394874879,
          "lng": 39.151963714820674,
          "radius": 5,
          "georeferences": {
            "lat": 21.74967394874879,
            "lng": 39.151963714820674
          },
          "cartesians": {
            "x": 140.3295853719574,
            "y": 112.46484446338079
          }
        }
      },
      {
        "id": 132436,
        "project_id": 12170,
        "building_id": 12170,
        "name": "جداول للنشر والترجمة والتوزيع _K19",
        "created_at": "2022-12-05T21:18:20.394+01:00",
        "updated_at": "2022-12-06T18:26:20.334+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 128.7468250760557,
          "y": 113.51192334934882,
          "lat": 21.749664604779728,
          "lng": 39.152075698502806,
          "radius": 5,
          "georeferences": {
            "lat": 21.749664604779728,
            "lng": 39.152075698502806
          },
          "cartesians": {
            "x": 128.7468250760557,
            "y": 113.51192334934882
          }
        }
      },
      {
        "id": 132506,
        "project_id": 12170,
        "building_id": 12170,
        "name": "آفاق للنشر والتوزيع _J21",
        "created_at": "2022-12-05T21:18:20.632+01:00",
        "updated_at": "2022-12-06T18:26:39.051+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 143.65686447492996,
          "y": 110.39234590563532,
          "lat": 21.749692633650202,
          "lng": 39.15193152789639,
          "radius": 5,
          "georeferences": {
            "lat": 21.749692633650202,
            "lng": 39.15193152789639
          },
          "cartesians": {
            "x": 143.65686447492996,
            "y": 110.39234590563532
          }
        }
      },
      {
        "id": 132434,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز القارئ العربي للنشر والتوزيع الإمارات _J9",
        "created_at": "2022-12-05T21:18:20.388+01:00",
        "updated_at": "2022-12-06T18:26:44.539+01:00",
        "info": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "info_unsafe": "<p> الإمارات العربية المتحدة - United Arab Emirates </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 132.2696633500789,
          "y": 99.78447543115666,
          "lat": 21.74978854624712,
          "lng": 39.152041500447616,
          "radius": 5,
          "georeferences": {
            "lat": 21.74978854624712,
            "lng": 39.152041500447616
          },
          "cartesians": {
            "x": 132.2696633500789,
            "y": 99.78447543115666
          }
        }
      },
      {
        "id": 132401,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار ماي بوك للنشر والتوزيع _J17",
        "created_at": "2022-12-05T21:18:20.166+01:00",
        "updated_at": "2022-12-06T18:26:43.155+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 136.2218012423127,
          "y": 98.26300623845711,
          "lat": 21.74980224861301,
          "lng": 39.15200327858974,
          "radius": 5,
          "georeferences": {
            "lat": 21.74980224861301,
            "lng": 39.15200327858974
          },
          "cartesians": {
            "x": 136.2218012423127,
            "y": 98.26300623845711
          }
        }
      },
      {
        "id": 132388,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مكتبة زهران للنشر والتوزيع _J13",
        "created_at": "2022-12-05T21:18:20.119+01:00",
        "updated_at": "2022-12-06T18:23:45.422+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 126.36142623471571,
          "y": 88.4120909559341,
          "lat": 21.74989131015667,
          "lng": 39.15209849889757,
          "radius": 5,
          "georeferences": {
            "lat": 21.74989131015667,
            "lng": 39.15209849889757
          },
          "cartesians": {
            "x": 126.36142623471571,
            "y": 88.4120909559341
          }
        }
      },
      {
        "id": 132580,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار صوفيا _K18",
        "created_at": "2022-12-05T21:18:20.957+01:00",
        "updated_at": "2022-12-06T18:26:22.738+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 123.4692875839722,
          "y": 108.06960634760216,
          "lat": 21.74971380679621,
          "lng": 39.1521266611824,
          "radius": 5,
          "georeferences": {
            "lat": 21.74971380679621,
            "lng": 39.1521266611824
          },
          "cartesians": {
            "x": 123.4692875839722,
            "y": 108.06960634760216
          }
        }
      },
      {
        "id": 132468,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مؤسسة ابجد للترجمة والنشر والتوزيع _I21",
        "created_at": "2022-12-05T21:18:20.500+01:00",
        "updated_at": "2022-12-06T18:27:02.181+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 156.5529183578732,
          "y": 104.99927926505751,
          "lat": 21.749741214461956,
          "lng": 39.151806803333315,
          "radius": 5,
          "georeferences": {
            "lat": 21.749741214461956,
            "lng": 39.151806803333315
          },
          "cartesians": {
            "x": 156.5529183578732,
            "y": 104.99927926505751
          }
        }
      },
      {
        "id": 132585,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة دار تشكيل للنشر والتوزيع _E26",
        "created_at": "2022-12-05T21:18:20.973+01:00",
        "updated_at": "2022-12-08T05:41:51.600+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 189.60026394198823,
          "y": 132.89364991895243,
          "lat": 21.749488973342753,
          "lng": 39.151487617085,
          "radius": 5,
          "georeferences": {
            "lat": 21.749488973342753,
            "lng": 39.151487617085
          },
          "cartesians": {
            "x": 189.60026394198823,
            "y": 132.89364991895243
          }
        }
      },
      {
        "id": 132596,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الوان للطباعة والنشر والتوزيع _V16",
        "created_at": "2022-12-05T21:18:21.007+01:00",
        "updated_at": "2022-12-06T15:27:47.380+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 213.1317007167759,
          "y": 237.62328361501258,
          "lat": 21.748542908085007,
          "lng": 39.151261220519345,
          "radius": 5,
          "georeferences": {
            "lat": 21.748542908085007,
            "lng": 39.151261220519345
          },
          "cartesians": {
            "x": 213.1317007167759,
            "y": 237.62328361501258
          }
        }
      },
      {
        "id": 132524,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار العلم للملايين _V19",
        "created_at": "2022-12-05T21:18:20.689+01:00",
        "updated_at": "2022-12-06T15:27:52.652+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 208.33602544145407,
          "y": 228.5941979562594,
          "lat": 21.74862449833707,
          "lng": 39.15130748747741,
          "radius": 5,
          "georeferences": {
            "lat": 21.74862449833707,
            "lng": 39.15130748747741
          },
          "cartesians": {
            "x": 208.33602544145407,
            "y": 228.5941979562594
          }
        }
      },
      {
        "id": 132527,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار أفكار مبتكرة للنشر _U21",
        "created_at": "2022-12-05T21:18:20.698+01:00",
        "updated_at": "2022-12-06T15:28:25.140+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 191.41307917717438,
          "y": 230.12940107594386,
          "lat": 21.7486107978464,
          "lng": 39.15147110061553,
          "radius": 5,
          "georeferences": {
            "lat": 21.7486107978464,
            "lng": 39.15147110061553
          },
          "cartesians": {
            "x": 191.41307917717438,
            "y": 230.12940107594386
          }
        }
      },
      {
        "id": 132528,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار نادي الكتاب _U18",
        "created_at": "2022-12-05T21:18:20.701+01:00",
        "updated_at": "2022-12-06T15:28:23.739+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 193.57121677967038,
          "y": 237.50615584293493,
          "lat": 21.748544155768787,
          "lng": 39.151450314054216,
          "radius": 5,
          "georeferences": {
            "lat": 21.748544155768787,
            "lng": 39.151450314054216
          },
          "cartesians": {
            "x": 193.57121677967038,
            "y": 237.50615584293493
          }
        }
      },
      {
        "id": 132529,
        "project_id": 12170,
        "building_id": 12170,
        "name": "سندباد للنشر والتوزيع _U24",
        "created_at": "2022-12-05T21:18:20.705+01:00",
        "updated_at": "2022-12-06T15:28:26.319+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 188.5595313449788,
          "y": 221.09828336641561,
          "lat": 21.748692387597927,
          "lng": 39.151498592680475,
          "radius": 5,
          "georeferences": {
            "lat": 21.748692387597927,
            "lng": 39.151498592680475
          },
          "cartesians": {
            "x": 188.5595313449788,
            "y": 221.09828336641561
          }
        }
      },
      {
        "id": 132593,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الشاهد للنشر والتوزيع _Y13",
        "created_at": "2022-12-05T21:18:20.998+01:00",
        "updated_at": "2022-12-06T15:21:47.074+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 252.34008547769338,
          "y": 189.37683882785717,
          "lat": 21.748978251958402,
          "lng": 39.150881685112125,
          "radius": 5,
          "georeferences": {
            "lat": 21.748978251958402,
            "lng": 39.150881685112125
          },
          "cartesians": {
            "x": 252.34008547769338,
            "y": 189.37683882785717
          }
        }
      },
      {
        "id": 132600,
        "project_id": 12170,
        "building_id": 12170,
        "name": "زغلول - Zaglool Store _U7",
        "created_at": "2022-12-05T21:18:21.020+01:00",
        "updated_at": "2022-12-06T15:28:53.198+01:00",
        "info": "<p> السويد - Sweden </p>",
        "info_unsafe": "<p> السويد - Sweden </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 193.8340741651453,
          "y": 223.85117328052416,
          "lat": 21.74866747443034,
          "lng": 39.15144763127198,
          "radius": 5,
          "georeferences": {
            "lat": 21.74866747443034,
            "lng": 39.15144763127198
          },
          "cartesians": {
            "x": 193.8340741651453,
            "y": 223.85117328052416
          }
        }
      },
      {
        "id": 132601,
        "project_id": 12170,
        "building_id": 12170,
        "name": "إنكي للنشر والتوزيع _R11",
        "created_at": "2022-12-05T21:18:21.023+01:00",
        "updated_at": "2022-12-05T21:18:21.023+01:00",
        "info": "<p> عراق - Iraq </p>",
        "info_unsafe": "<p> عراق - Iraq </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 150.8397033897623,
          "y": 233.82808919768365,
          "lat": 21.748577788015183,
          "lng": 39.15186336920081,
          "radius": 5,
          "georeferences": {
            "lat": 21.748577788015183,
            "lng": 39.15186336920081
          },
          "cartesians": {
            "x": 150.8397033897623,
            "y": 233.82808919768365
          }
        }
      },
      {
        "id": 132598,
        "project_id": 12170,
        "building_id": 12170,
        "name": "شركة الآن ناشرون وموزعون _V11",
        "created_at": "2022-12-05T21:18:21.014+01:00",
        "updated_at": "2022-12-06T15:28:08.017+01:00",
        "info": "<p> الأردن - Jordan </p>",
        "info_unsafe": "<p> الأردن - Jordan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 213.6089420203788,
          "y": 229.8299346095353,
          "lat": 21.74861328693698,
          "lng": 39.151256526055846,
          "radius": 5,
          "georeferences": {
            "lat": 21.74861328693698,
            "lng": 39.151256526055846
          },
          "cartesians": {
            "x": 213.6089420203788,
            "y": 229.8299346095353
          }
        }
      },
      {
        "id": 132599,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار مكتبتي للنشر والتوزيع _V10",
        "created_at": "2022-12-05T21:18:21.017+01:00",
        "updated_at": "2022-12-06T15:28:05.563+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 211.94136529033133,
          "y": 227.14213832337936,
          "lat": 21.748637577216098,
          "lng": 39.15127261893008,
          "radius": 5,
          "georeferences": {
            "lat": 21.748637577216098,
            "lng": 39.15127261893008
          },
          "cartesians": {
            "x": 211.94136529033133,
            "y": 227.14213832337936
          }
        }
      },
      {
        "id": 132592,
        "project_id": 12170,
        "building_id": 12170,
        "name": "المؤسسة العربية ناشرون وموزعون _Y17",
        "created_at": "2022-12-05T21:18:20.995+01:00",
        "updated_at": "2022-12-06T15:14:43.223+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 247.66215578456433,
          "y": 193.24367051728524,
          "lat": 21.748943375142574,
          "lng": 39.15092694766359,
          "radius": 5,
          "georeferences": {
            "lat": 21.748943375142574,
            "lng": 39.15092694766359
          },
          "cartesians": {
            "x": 247.66215578456433,
            "y": 193.24367051728524
          }
        }
      },
      {
        "id": 132588,
        "project_id": 12170,
        "building_id": 12170,
        "name": "الملكية الفكرية _Z19",
        "created_at": "2022-12-05T21:18:20.982+01:00",
        "updated_at": "2022-12-06T15:19:03.708+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 228.73876220440766,
          "y": 172.71260010284252,
          "lat": 21.749128979541084,
          "lng": 39.15110967061552,
          "radius": 5,
          "georeferences": {
            "lat": 21.749128979541084,
            "lng": 39.15110967061552
          },
          "cartesians": {
            "x": 228.73876220440766,
            "y": 172.71260010284252
          }
        }
      },
      {
        "id": 132483,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الكتاب الجديد المتحدة _Y8",
        "created_at": "2022-12-05T21:18:20.554+01:00",
        "updated_at": "2022-12-06T15:21:51.723+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 239.71117510989785,
          "y": 184.90745950591025,
          "lat": 21.749018738517016,
          "lng": 39.151003724766746,
          "radius": 5,
          "georeferences": {
            "lat": 21.749018738517016,
            "lng": 39.151003724766746
          },
          "cartesians": {
            "x": 239.71117510989785,
            "y": 184.90745950591025
          }
        }
      },
      {
        "id": 132484,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار أدب للنشر والتوزيع _Y20",
        "created_at": "2022-12-05T21:18:20.558+01:00",
        "updated_at": "2022-12-06T15:14:46.427+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 237.77412804327082,
          "y": 189.73691302521107,
          "lat": 21.74897514144125,
          "lng": 39.15102250067821,
          "radius": 5,
          "georeferences": {
            "lat": 21.74897514144125,
            "lng": 39.15102250067821
          },
          "cartesians": {
            "x": 237.77412804327082,
            "y": 189.73691302521107
          }
        }
      },
      {
        "id": 132485,
        "project_id": 12170,
        "building_id": 12170,
        "name": "بيت الحكمة _X8",
        "created_at": "2022-12-05T21:18:20.561+01:00",
        "updated_at": "2022-12-06T15:24:28.047+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 236.88372744326446,
          "y": 200.4271206536269,
          "lat": 21.74887860441938,
          "lng": 39.15103121928449,
          "radius": 5,
          "georeferences": {
            "lat": 21.74887860441938,
            "lng": 39.15103121928449
          },
          "cartesians": {
            "x": 236.88372744326446,
            "y": 200.4271206536269
          }
        }
      },
      {
        "id": 132488,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الرشيد _U15",
        "created_at": "2022-12-05T21:18:20.571+01:00",
        "updated_at": "2022-12-06T15:28:46.005+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 200.79469040165276,
          "y": 246.6015908008567,
          "lat": 21.748461942734234,
          "lng": 39.151380577820426,
          "radius": 5,
          "georeferences": {
            "lat": 21.748461942734234,
            "lng": 39.151380577820426
          },
          "cartesians": {
            "x": 200.79469040165276,
            "y": 246.6015908008567
          }
        }
      },
      {
        "id": 132565,
        "project_id": 12170,
        "building_id": 12170,
        "name": "عويدات للنشر والطباعة _U17",
        "created_at": "2022-12-05T21:18:20.907+01:00",
        "updated_at": "2022-12-06T15:28:22.228+01:00",
        "info": "<p> لبنان - Lebanon </p>",
        "info_unsafe": "<p> لبنان - Lebanon </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 195.72869556400292,
          "y": 244.26224300290502,
          "lat": 21.748483119085755,
          "lng": 39.15142952742979,
          "radius": 5,
          "georeferences": {
            "lat": 21.748483119085755,
            "lng": 39.15142952742979
          },
          "cartesians": {
            "x": 195.72869556400292,
            "y": 244.26224300290502
          }
        }
      },
      {
        "id": 132572,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المصورات للنشر _S6",
        "created_at": "2022-12-05T21:18:20.931+01:00",
        "updated_at": "2022-12-06T15:30:55.552+01:00",
        "info": "<p> السودان - Sudan </p>",
        "info_unsafe": "<p> السودان - Sudan </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 164.09445279178442,
          "y": 239.81354947601827,
          "lat": 21.748523603313803,
          "lng": 39.151735295224476,
          "radius": 5,
          "georeferences": {
            "lat": 21.748523603313803,
            "lng": 39.151735295224476
          },
          "cartesians": {
            "x": 164.09445279178442,
            "y": 239.81354947601827
          }
        }
      },
      {
        "id": 132452,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المستقبل الرقمي _X21",
        "created_at": "2022-12-05T21:18:20.446+01:00",
        "updated_at": "2022-12-06T15:17:37.493+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 219.64039561476298,
          "y": 194.30759553580552,
          "lat": 21.74893403860816,
          "lng": 39.15119785027537,
          "radius": 5,
          "georeferences": {
            "lat": 21.74893403860816,
            "lng": 39.15119785027537
          },
          "cartesians": {
            "x": 219.64039561476298,
            "y": 194.30759553580552
          }
        }
      },
      {
        "id": 132564,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مجموعة بيت الحكمة للتبادل الثقافي الدولي _U9",
        "created_at": "2022-12-05T21:18:20.903+01:00",
        "updated_at": "2022-12-06T15:28:50.439+01:00",
        "info": "<p> الصين - China </p>",
        "info_unsafe": "<p> الصين - China </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "General Science and Knowledge",
        "category_id": 2212,
        "position": {
          "floor_id": 37595,
          "x": 195.7827306595046,
          "y": 229.9178446114596,
          "lat": 21.748612666043638,
          "lng": 39.15142885620304,
          "radius": 5,
          "georeferences": {
            "lat": 21.748612666043638,
            "lng": 39.15142885620304
          },
          "cartesians": {
            "x": 195.7827306595046,
            "y": 229.9178446114596
          }
        }
      },
      {
        "id": 132650,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:21.183+01:00",
        "updated_at": "2022-12-06T15:17:10.249+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 258.74755014244846,
          "y": 205.6672474293508,
          "lat": 21.748831067422792,
          "lng": 39.15081991205381,
          "radius": 5,
          "georeferences": {
            "lat": 21.748831067422792,
            "lng": 39.15081991205381
          },
          "cartesians": {
            "x": 258.74755014244846,
            "y": 205.6672474293508
          }
        }
      },
      {
        "id": 132659,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:21.210+01:00",
        "updated_at": "2022-12-06T16:26:22.064+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 263.4115626165936,
          "y": 132.6704883918303,
          "lat": 21.74949027226781,
          "lng": 39.15077406655386,
          "radius": 5,
          "georeferences": {
            "lat": 21.74949027226781,
            "lng": 39.15077406655386
          },
          "cartesians": {
            "x": 263.4115626165936,
            "y": 132.6704883918303
          }
        }
      },
      {
        "id": 132663,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:21.226+01:00",
        "updated_at": "2022-12-06T16:19:32.147+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 232.45810318620204,
          "y": 75.38995459117831,
          "lat": 21.75000788609835,
          "lng": 39.1510727057555,
          "radius": 5,
          "georeferences": {
            "lat": 21.75000788609835,
            "lng": 39.1510727057555
          },
          "cartesians": {
            "x": 232.45810318620204,
            "y": 75.38995459117831
          }
        }
      },
      {
        "id": 132658,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:21.207+01:00",
        "updated_at": "2022-12-06T15:15:10.531+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 262.81865970190233,
          "y": 185.59471115828467,
          "lat": 21.749012307485476,
          "lng": 39.150780347520204,
          "radius": 5,
          "georeferences": {
            "lat": 21.749012307485476,
            "lng": 39.150780347520204
          },
          "cartesians": {
            "x": 262.81865970190233,
            "y": 185.59471115828467
          }
        }
      },
      {
        "id": 132654,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:21.195+01:00",
        "updated_at": "2022-12-06T15:31:05.272+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 174.10636527245947,
          "y": 253.82458948635275,
          "lat": 21.74839696927886,
          "lng": 39.15163865364258,
          "radius": 5,
          "georeferences": {
            "lat": 21.74839696927886,
            "lng": 39.15163865364258
          },
          "cartesians": {
            "x": 174.10636527245947,
            "y": 253.82458948635275
          }
        }
      },
      {
        "id": 132652,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نقطة معلومات",
        "created_at": "2022-12-05T21:18:21.189+01:00",
        "updated_at": "2022-12-06T15:25:19.152+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Information Point",
        "category_id": 2213,
        "position": {
          "floor_id": 37595,
          "x": 242.1161703538905,
          "y": 220.58081977197028,
          "lat": 21.74869654104589,
          "lng": 39.15098084547164,
          "radius": 5,
          "georeferences": {
            "lat": 21.74869654104589,
            "lng": 39.15098084547164
          },
          "cartesians": {
            "x": 242.1161703538905,
            "y": 220.58081977197028
          }
        }
      },
      {
        "id": 132660,
        "project_id": 12170,
        "building_id": 12170,
        "name": "نقطة معلومات",
        "created_at": "2022-12-05T21:18:21.216+01:00",
        "updated_at": "2022-12-05T21:18:21.216+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Information Point",
        "category_id": 2213,
        "position": {
          "floor_id": 37595,
          "x": 104.70317356106051,
          "y": 218.8130876977169,
          "lat": 21.748713839718555,
          "lng": 39.15230922376013,
          "radius": 5,
          "georeferences": {
            "lat": 21.748713839718555,
            "lng": 39.15230922376013
          },
          "cartesians": {
            "x": 104.70317356106051,
            "y": 218.8130876977169
          }
        }
      },
      {
        "id": 132556,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار المنهاج للنشر والتوزيع _Z4",
        "created_at": "2022-12-05T21:18:20.877+01:00",
        "updated_at": "2022-12-06T17:57:41.389+01:00",
        "info": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "info_unsafe": "<p> المملكة العربية السعودية - Saudi Arabia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Religions",
        "category_id": 2224,
        "position": {
          "floor_id": 37595,
          "x": 235.36003639882296,
          "y": 169.91264136834792,
          "lat": 21.749154202323233,
          "lng": 39.151045632457226,
          "radius": 5,
          "georeferences": {
            "lat": 21.749154202323233,
            "lng": 39.151045632457226
          },
          "cartesians": {
            "x": 235.36003639882296,
            "y": 169.91264136834792
          }
        }
      },
      {
        "id": 132446,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار كتاب للنشر والتوزيع _G11",
        "created_at": "2022-12-05T21:18:20.428+01:00",
        "updated_at": "2022-12-06T18:22:00.606+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 177.54998946589373,
          "y": 85.94301646599826,
          "lat": 21.749913112028,
          "lng": 39.15160362308033,
          "radius": 5,
          "georeferences": {
            "lat": 21.749913112028,
            "lng": 39.15160362308033
          },
          "cartesians": {
            "x": 177.54998946589373,
            "y": 85.94301646599826
          }
        }
      },
      {
        "id": 132402,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار بوك لاند للنشر والتوزيع _J24",
        "created_at": "2022-12-05T21:18:20.169+01:00",
        "updated_at": "2022-12-06T18:22:51.650+01:00",
        "info": "<p> الكويت - Kuwait </p>",
        "info_unsafe": "<p> الكويت - Kuwait </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 149.07796568215937,
          "y": 120.31708466139949,
          "lat": 21.749602948482906,
          "lng": 39.15187922379798,
          "radius": 5,
          "georeferences": {
            "lat": 21.749602948482906,
            "lng": 39.15187922379798
          },
          "cartesians": {
            "x": 149.07796568215937,
            "y": 120.31708466139949
          }
        }
      },
      {
        "id": 132642,
        "project_id": 12170,
        "building_id": 12170,
        "name": "منطقة القراءة",
        "created_at": "2022-12-05T21:18:21.159+01:00",
        "updated_at": "2022-12-06T18:19:32.666+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Reading Area",
        "category_id": 2223,
        "position": {
          "floor_id": 37595,
          "x": 137.62148052427833,
          "y": 82.0617530493538,
          "lat": 21.749948552181102,
          "lng": 39.15198957995375,
          "radius": 5,
          "georeferences": {
            "lat": 21.749948552181102,
            "lng": 39.15198957995375
          },
          "cartesians": {
            "x": 137.62148052427833,
            "y": 82.0617530493538
          }
        }
      },
      {
        "id": 132541,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار روائع الكتب  ناشرون وموزّعون _G12",
        "created_at": "2022-12-05T21:18:20.811+01:00",
        "updated_at": "2022-12-06T18:22:03.709+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 176.72422244436623,
          "y": 92.1160469425707,
          "lat": 21.749857370019797,
          "lng": 39.15161166986657,
          "radius": 5,
          "georeferences": {
            "lat": 21.749857370019797,
            "lng": 39.15161166986657
          },
          "cartesians": {
            "x": 176.72422244436623,
            "y": 92.1160469425707
          }
        }
      },
      {
        "id": 132612,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار نقوش عربية _F11",
        "created_at": "2022-12-05T21:18:21.057+01:00",
        "updated_at": "2022-12-06T18:21:03.784+01:00",
        "info": "<p> تونس - Tunisia </p>",
        "info_unsafe": "<p> تونس - Tunisia </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Literature",
        "category_id": 2216,
        "position": {
          "floor_id": 37595,
          "x": 196.45862975644724,
          "y": 92.26734215238483,
          "lat": 21.749855812074024,
          "lng": 39.15142089492144,
          "radius": 5,
          "georeferences": {
            "lat": 21.749855812074024,
            "lng": 39.15142089492144
          },
          "cartesians": {
            "x": 196.45862975644724,
            "y": 92.26734215238483
          }
        }
      },
      {
        "id": 132613,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار نينوى للدراسات والنشر والتوزيع _G4",
        "created_at": "2022-12-05T21:18:21.060+01:00",
        "updated_at": "2022-12-06T18:20:54.303+01:00",
        "info": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "info_unsafe": "<p> الجمهورية العربية السورية - Syrian Arab Republic </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 181.30208910938612,
          "y": 91.9387299464268,
          "lat": 21.74985892697136,
          "lng": 39.15156741286566,
          "radius": 5,
          "georeferences": {
            "lat": 21.74985892697136,
            "lng": 39.15156741286566
          },
          "cartesians": {
            "x": 181.30208910938612,
            "y": 91.9387299464268
          }
        }
      },
      {
        "id": 132397,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مركز تبصير للنشر والتوزيع _F5",
        "created_at": "2022-12-05T21:18:20.150+01:00",
        "updated_at": "2022-12-06T18:21:07.775+01:00",
        "info": "<p> مصر - Egypt </p>",
        "info_unsafe": "<p> مصر - Egypt </p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 191.37831302804537,
          "y": 109.09963050888598,
          "lat": 21.74970384521423,
          "lng": 39.15147018176206,
          "radius": 5,
          "georeferences": {
            "lat": 21.74970384521423,
            "lng": 39.15147018176206
          },
          "cartesians": {
            "x": 191.37831302804537,
            "y": 109.09963050888598
          }
        }
      },
      {
        "id": 132646,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دورات مياه",
        "created_at": "2022-12-05T21:18:21.172+01:00",
        "updated_at": "2022-12-08T03:16:30.850+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Restrooms",
        "category_id": 2225,
        "position": {
          "floor_id": 37595,
          "x": 242.47662373144507,
          "y": 279.37543206086303,
          "lat": 21.748165550203357,
          "lng": 39.15097797134767,
          "radius": 5,
          "georeferences": {
            "lat": 21.748165550203357,
            "lng": 39.15097797134767
          },
          "cartesians": {
            "x": 242.47662373144507,
            "y": 279.37543206086303
          }
        }
      },
      {
        "id": 132645,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دورات مياه",
        "created_at": "2022-12-05T21:18:21.169+01:00",
        "updated_at": "2022-12-08T03:16:42.950+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Restrooms",
        "category_id": 2225,
        "position": {
          "floor_id": 37595,
          "x": 107.08922921464537,
          "y": 280.44508288682766,
          "lat": 21.74815720417124,
          "lng": 39.15228679658204,
          "radius": 5,
          "georeferences": {
            "lat": 21.74815720417124,
            "lng": 39.15228679658204
          },
          "cartesians": {
            "x": 107.08922921464537,
            "y": 280.44508288682766
          }
        }
      },
      {
        "id": 132651,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:21.186+01:00",
        "updated_at": "2022-12-08T03:20:05.484+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 137.57111111347533,
          "y": 184.84732333057383,
          "lat": 21.749020273108446,
          "lng": 39.15199113126879,
          "radius": 5,
          "georeferences": {
            "lat": 21.749020273108446,
            "lng": 39.15199113126879
          },
          "cartesians": {
            "x": 137.57111111347533,
            "y": 184.84732333057383
          }
        }
      },
      {
        "id": 132655,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:21.198+01:00",
        "updated_at": "2022-12-08T05:43:06.076+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 138.11242023105618,
          "y": 139.98641429881823,
          "lat": 21.749425416795113,
          "lng": 39.15198543356256,
          "radius": 5,
          "georeferences": {
            "lat": 21.749425416795113,
            "lng": 39.15198543356256
          },
          "cartesians": {
            "x": 138.11242023105618,
            "y": 139.98641429881823
          }
        }
      },
      {
        "id": 132644,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مسرح 3",
        "created_at": "2022-12-05T21:18:21.166+01:00",
        "updated_at": "2022-12-08T05:42:50.449+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Theaters",
        "category_id": 2229,
        "position": {
          "floor_id": 37595,
          "x": 197.12537497165312,
          "y": 71.92921011968451,
          "lat": 21.750039483838393,
          "lng": 39.151414238670256,
          "radius": 5,
          "georeferences": {
            "lat": 21.750039483838393,
            "lng": 39.151414238670256
          },
          "cartesians": {
            "x": 197.12537497165312,
            "y": 71.92921011968451
          }
        }
      },
      {
        "id": 132643,
        "project_id": 12170,
        "building_id": 12170,
        "name": "مقهى",
        "created_at": "2022-12-05T21:18:21.163+01:00",
        "updated_at": "2022-12-08T05:43:16.709+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Cafe",
        "category_id": 2206,
        "position": {
          "floor_id": 37595,
          "x": 173.5684769339792,
          "y": 111.46655625462125,
          "lat": 21.749682641859046,
          "lng": 39.15164237751331,
          "radius": 5,
          "georeferences": {
            "lat": 21.749682641859046,
            "lng": 39.15164237751331
          },
          "cartesians": {
            "x": 173.5684769339792,
            "y": 111.46655625462125
          }
        }
      },
      {
        "id": 132653,
        "project_id": 12170,
        "building_id": 12170,
        "name": "توقيع كتاب",
        "created_at": "2022-12-05T21:18:21.192+01:00",
        "updated_at": "2022-12-08T05:44:55.158+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Book Signing",
        "category_id": 2205,
        "position": {
          "floor_id": 37595,
          "x": 144.5188953478769,
          "y": 163.21992473993174,
          "lat": 21.749215527557634,
          "lng": 39.15192374161217,
          "radius": 5,
          "georeferences": {
            "lat": 21.749215527557634,
            "lng": 39.15192374161217
          },
          "cartesians": {
            "x": 144.5188953478769,
            "y": 163.21992473993174
          }
        }
      },
      {
        "id": 132786,
        "project_id": 12170,
        "building_id": 12170,
        "name": "World Cup Zone",
        "created_at": "2022-12-08T05:51:14.882+01:00",
        "updated_at": "2022-12-08T05:51:24.765+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "World Cup",
        "category_id": 2230,
        "position": {
          "floor_id": 37595,
          "x": 206.4986705360958,
          "y": 316.73087094348443,
          "lat": 21.747828534091244,
          "lng": 39.151326164594664,
          "radius": 5,
          "georeferences": {
            "lat": 21.747828534091244,
            "lng": 39.151326164594664
          },
          "cartesians": {
            "x": 206.4986705360958,
            "y": 316.73087094348443
          }
        }
      },
      {
        "id": 132874,
        "project_id": 12170,
        "building_id": 12170,
        "name": "دار الفكر الجديد_Q14a",
        "created_at": "2022-12-10T21:15:30.654+01:00",
        "updated_at": "2022-12-10T21:15:30.654+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 131.45847283115452,
          "y": 241.81583296160912,
          "lat": 21.748505837044284,
          "lng": 39.152050813932476,
          "radius": 5,
          "georeferences": {
            "lat": 21.748505837044284,
            "lng": 39.152050813932476
          },
          "cartesians": {
            "x": 131.45847283115452,
            "y": 241.81583296160912
          }
        }
      },
      {
        "id": 132875,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Little Things_A20",
        "created_at": "2022-12-10T21:19:08.569+01:00",
        "updated_at": "2022-12-10T21:19:08.569+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 256.1734770908656,
          "y": 149.52356593010865,
          "lat": 21.74933813860211,
          "lng": 39.15084421340447,
          "radius": 5,
          "georeferences": {
            "lat": 21.74933813860211,
            "lng": 39.15084421340447
          },
          "cartesians": {
            "x": 256.1734770908656,
            "y": 149.52356593010865
          }
        }
      },
      {
        "id": 132876,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Spacetoon_C6",
        "created_at": "2022-12-10T21:20:39.065+01:00",
        "updated_at": "2022-12-10T21:20:39.065+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 245.87213993619724,
          "y": 116.43254570966772,
          "lat": 21.749637091031406,
          "lng": 39.15094345513802,
          "radius": 5,
          "georeferences": {
            "lat": 21.749637091031406,
            "lng": 39.15094345513802
          },
          "cartesians": {
            "x": 245.87213993619724,
            "y": 116.43254570966772
          }
        }
      },
      {
        "id": 132877,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Pan World_B1",
        "created_at": "2022-12-10T21:22:19.392+01:00",
        "updated_at": "2022-12-10T21:23:28.011+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 241.7363709548644,
          "y": 140.71185212783536,
          "lat": 21.74941785931077,
          "lng": 39.15098368827324,
          "radius": 5,
          "georeferences": {
            "lat": 21.74941785931077,
            "lng": 39.15098368827324
          },
          "cartesians": {
            "x": 241.7363709548644,
            "y": 140.71185212783536
          }
        }
      },
      {
        "id": 132878,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Kinokuniya_B9",
        "created_at": "2022-12-10T21:23:17.141+01:00",
        "updated_at": "2022-12-10T21:25:10.289+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 239.7876780162021,
          "y": 134.6452279061008,
          "lat": 21.74947266727231,
          "lng": 39.15100246373635,
          "radius": 5,
          "georeferences": {
            "lat": 21.74947266727231,
            "lng": 39.15100246373635
          },
          "cartesians": {
            "x": 239.7876780162021,
            "y": 134.6452279061008
          }
        }
      },
      {
        "id": 132879,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Jazert Al Kanz_C1",
        "created_at": "2022-12-10T21:24:20.163+01:00",
        "updated_at": "2022-12-10T21:25:20.108+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 238.67430864294158,
          "y": 131.33621535369002,
          "lat": 21.74950256251524,
          "lng": 39.15101319257241,
          "radius": 5,
          "georeferences": {
            "lat": 21.74950256251524,
            "lng": 39.15101319257241
          },
          "cartesians": {
            "x": 238.67430864294158,
            "y": 131.33621535369002
          }
        }
      },
      {
        "id": 132880,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Dar Al Mahroosa_C3",
        "created_at": "2022-12-10T21:26:11.755+01:00",
        "updated_at": "2022-12-10T21:26:11.755+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 244.70672203067585,
          "y": 129.2608510248132,
          "lat": 21.74952124703891,
          "lng": 39.15095485452633,
          "radius": 5,
          "georeferences": {
            "lat": 21.74952124703891,
            "lng": 39.15095485452633
          },
          "cartesians": {
            "x": 244.70672203067585,
            "y": 129.2608510248132
          }
        }
      },
      {
        "id": 132881,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Insight Edition_C13",
        "created_at": "2022-12-10T21:26:56.064+01:00",
        "updated_at": "2022-12-10T21:26:56.064+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 219.6007975449446,
          "y": 132.73597014511986,
          "lat": 21.749490106164767,
          "lng": 39.15119759444218,
          "radius": 5,
          "georeferences": {
            "lat": 21.749490106164767,
            "lng": 39.15119759444218
          },
          "cartesians": {
            "x": 219.6007975449446,
            "y": 132.73597014511986
          }
        }
      },
      {
        "id": 132882,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Virgin_C8",
        "created_at": "2022-12-10T21:27:24.541+01:00",
        "updated_at": "2022-12-10T21:27:24.541+01:00",
        "info": "",
        "info_unsafe": "",
        "custom_fields": [],
        "type": null,
        "category_name": "Manga Area",
        "category_id": 2217,
        "position": {
          "floor_id": 37595,
          "x": 234.15502088962518,
          "y": 121.41044925869909,
          "lat": 21.74959224820668,
          "lng": 39.1510567784689,
          "radius": 5,
          "georeferences": {
            "lat": 21.74959224820668,
            "lng": 39.1510567784689
          },
          "cartesians": {
            "x": 234.15502088962518,
            "y": 121.41044925869909
          }
        }
      },
      {
        "id": 132873,
        "project_id": 12170,
        "building_id": 12170,
        "name": "Jarir - Kids Area_N7",
        "created_at": "2022-12-10T21:04:38.266+01:00",
        "updated_at": "2022-12-10T21:35:54.509+01:00",
        "info": "<p>Saudi&nbsp;Arabia&nbsp;-&nbsp;المملكة&nbsp;العربية&nbsp;السعودية</p>",
        "info_unsafe": "<p>Saudi&nbsp;Arabia&nbsp;-&nbsp;المملكة&nbsp;العربية&nbsp;السعودية</p>",
        "custom_fields": [],
        "type": null,
        "category_name": "Other",
        "category_id": 2219,
        "position": {
          "floor_id": 37595,
          "x": 108.72738089519802,
          "y": 183.89929929378894,
          "lat": 21.74902911491653,
          "lng": 39.15226995918926,
          "radius": 5,
          "georeferences": {
            "lat": 21.74902911491653,
            "lng": 39.15226995918926
          },
          "cartesians": {
            "x": 108.72738089519802,
            "y": 183.89929929378894
          }
        }
      }
    ]
