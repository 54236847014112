import React, { useContext, useState } from "react";
import Icon from "react-web-vector-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import booksJson from '../consts/poii'

const Settings = (props) => {
  const [show, setShow] = useState(false);
  const [originalBooks, setOriginalBooks] = useState(booksJson);
  const [books, setBooks] = useState(booksJson);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const orgUrl = 'https://map-viewer.situm.com/?email=nassar_pos@imaxem.com&apikey=2c87898505fa25f28feac7a6905cb676c8cd4d981d0d4613fad04152562a1eb0&rtl=true&wl=true&lng=ar&buildingid=12170&floorid=37595&hide=lc,pcl'
  const [mapUrl, setMapUrl] = useState(orgUrl)

  const openSettingsModal = () => {
    setShow(true)
  }

  const onSearchBook = (e) => {
    let filterBooks = originalBooks.filter((obj)=>{
      return obj.name.includes(e.target.value)
    })
    setBooks(filterBooks)
  }

  const onBookClick = (item) => {
    if(item.id)
    {
      props.searchBookRef.current.setMapToPoii(item.id)
      setShow(false)
    }
  }

  const resetDefPLace = () => {
    props.searchBookRef.current.resetPoii()
    setShow(false)
  }

  setTimeout( () => {
    //props.searchBookRef.current.setMapToPoii(132571)
  }, 5000 )

  return (
      <div>
        <Modal show={show} onHide={handleClose} style={{zIndex: 9999999999999}}>
          <Modal.Header closeButton>
            <Form.Control type="text" placeholder="ابحث بـ اسم المكان"  onChange={onSearchBook} />
          </Modal.Header>
          <Modal.Body>
            <ul>
              {books.map((item,index)=>{
                if(index < 1000)
                {
                  return (
                      <li style={{marginTop: 10, cursor: 'pointer'}} onClick={()=>{onBookClick(item)}}>{item.name}</li>
                  )
                }
              })}
              <li style={{marginTop: 10, cursor: 'pointer'}} onClick={()=>{resetDefPLace()}}>{'Reset'}</li>
            </ul>

          </Modal.Body>

        </Modal>
        <div  style={{
          //backgroundColor: '#0A1435',
          borderColor: '#0A1435',
          position: 'absolute',
          right: 15,
          bottom: 0,
          height: 40,
          width: 10,
          zIndex: 99999999,

        }} onClick={openSettingsModal}>
          <Icon
              name='setting'
              font='AntDesign'
              color='white'
              size={20}
               style={{

                 //marginLeft: 10
               }}
          />
        </div>
      </div>
  )
}

export default Settings;
