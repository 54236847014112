import React, { useContext, useRef } from "react";
import Iframe from "./Iframe";
import Button from 'react-bootstrap/Button';
import SearchBooks from "./SearchBooks";
import Icon from "react-web-vector-icons";
import Settings from "./Settings";


const HomeScreen = () => {
  let searchBooksRef = useRef(null);

  return (
      <div>
      <div style={{
        textAlign: 'center',
        alignContent: 'center',
        alignSelf: 'center',
      }}>

        <SearchBooks ref={searchBooksRef}/>

      </div>

        <Settings searchBookRef={searchBooksRef} />
      </div>
  )
}

export default HomeScreen;
