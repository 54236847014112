import { createContext } from "react";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";


import HomeScreen from '../screens/Home'
import Settings from '../screens/Settings'

const RootNavigator = () => {
  return (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </BrowserRouter>
  )
};


export default RootNavigator;
