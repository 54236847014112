import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import '../App.css';
import _ from 'lodash'
import Icon, { FontAwesome, Feather } from 'react-web-vector-icons';
import moment from "moment";
import booksJson from '../consts/books'

window.moment = moment

const SearchBooks = React.forwardRef((props, ref) => {
  //let ref = useRef();

  const [show, setShow] = useState(false);
  const [originalBooks, setOriginalBooks] = useState(booksJson);
  const [books, setBooks] = useState(booksJson);

  const orgUrl = 'https://map-viewer.situm.com/?email=nassar_pos@imaxem.com&apikey=2c87898505fa25f28feac7a6905cb676c8cd4d981d0d4613fad04152562a1eb0&rtl=true&wl=true&lng=ar&buildingid=12170&floorid=37595&hide=lc,pcl'
  const [mapUrl, setMapUrl] = useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSearchBook = (e) => {
    let filterBooks = originalBooks.filter((obj)=>{
      return obj.name.includes(e.target.value)
    })
    setBooks(filterBooks)
  }

  const onBookClick = (item) => {
    let uri = orgUrl;
    if(item.map_id)
    {
      uri += '&poiid=' + item.map_id
      const poiiStrg =  localStorage.getItem("poiid");
      if(poiiStrg) {
        uri += '&navigation_from=' + poiiStrg
        uri += '&navigation_to=' + item.map_id
      }
      uri += '&' + (Math.random() + 1).toString(36).substring(7)
      console.log ('-->uri', uri );
      setMapUrl(uri)
      setShow(false)
    }
  }

  const openSearchModal = () => {
    setShow(true)
  };

  const iframeOnMouseUp = (e) => {
   // console.log ('-->event d', e.target );
  }

  const iframeLoadDone = (e) => {
    //console.log ('-->event d', e );
    /*let iframe = document.getElementById('mapIframe');

    console.log ('-->iframe', iframe );

    iframe.contentWindow.body.addEventListener('mouseup', iframeOnMouseUp);*/
  }

  useEffect(() => {

    const poiiStrg =  localStorage.getItem("poiid");
    if(poiiStrg)
    {
      let uriX = orgUrl + '&navigation_from=' + poiiStrg
      setMapUrl(uriX)
    } else {
      setMapUrl(orgUrl)
    }

    ref.current = {
      setMapToPoii: (poii) => {
        let uri = orgUrl;
        uri += '&navigation_from=' + poii
        localStorage.setItem("poiid", JSON.stringify(poii));
        setMapUrl(uri)
      },
      resetPoii: () => {
        localStorage.removeItem('poiid')
        setMapUrl(orgUrl)
      }
    }

    let checkint = setInterval(() => {
      console.log ('--> interval run' );
      const lastRefreshTime = localStorage.getItem('last-refresh-in')
      const now = moment(new Date())
      const end = moment.unix(lastRefreshTime)
      const duration = moment.duration(now.diff(end))
      console.log ('-->duration', duration.asMinutes() );
      if( duration.asMinutes() > 5)
      {
        //clearInterval(checkint)
        localStorage.setItem('last-refresh-in', JSON.stringify( moment().unix() ))
        setTimeout(()=>{
          const poiiStrg =  localStorage.getItem("poiid");
          let uri = orgUrl
          if(poiiStrg) {
            uri += '&navigation_from=' + poiiStrg
          }
          console.log ('-->Refresh Now', uri );
          //setMapUrl(uri);
        }, 100)
      }
    }, 10000)

    return () => {
      //clearInterval(checkint)
    }
  }, []);

  useEffect(() => {
    //window.moment(new Date()).add(4, 'minutes').unix()
    //moment().unix()
    localStorage.setItem('last-refresh-in', JSON.stringify( moment().unix() ))
    console.log ('-->update last refresh time' );
    console.log ('-->mapUrl', mapUrl );
  },[mapUrl])

  return (
      <>
        <iframe src={mapUrl}
                id="mapIframe"
                width={1000}
                height={500}
                onLoad={iframeLoadDone}
            //sandbox='allow-scripts allow-modal'
                loading='lazy'
            //style={}
            //title='Custom title'
        >
        </iframe>

        <Modal show={show} onHide={handleClose} style={{zIndex: 9999999999999}}>
          <Modal.Header closeButton>
            <Form.Control type="text" placeholder="ابحث بـ اسم الكتاب" onChange={onSearchBook} />
          </Modal.Header>
          <Modal.Body>
            <ul>
              {books.map((item,index)=>{
                if(index < 1000)
                {
                  return (
                      <li style={{marginTop: 10, cursor: 'pointer'}} onClick={()=>{onBookClick(item)}}>{item.name}</li>
                  )
                }
              })}
            </ul>

          </Modal.Body>

        </Modal>
        <div style={{
          position: 'absolute',
          bottom: 30,
          zIndex: 99999999,
          width: '100%',
          //backgroundColor: 'red'
          //left: window.innerWidth / 2 - 60,
        }}>
          <Button variant="danger" style={{

          }} onClick={openSearchModal}>ابحث عن كتاب</Button>
        </div>
        </>

  );
});

export default SearchBooks;
